export const ToolsInitialState = {
  anonymity: {
    data: {},
    loading: false,
    isLoaded: false,
    errors: null
  },
  settings: {
    data: {},
    isLoaded: false,
    errors: null
  }
};
