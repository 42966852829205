import { useLangUrlDefault } from "../../../../../hooks";
import { Promocode } from "../../../Promocodes/Promocode";

export const PromocodesTable = ({ data }) => {
  const { name, imageFile, promocodes, favicon, faviconContentType } = data;
  const [queryLang] = useLangUrlDefault();

  const promocodesLang = promocodes.filter(
    (it) => it.showIn[queryLang] && it.promocode
  );

  return promocodesLang?.length
    ? promocodesLang?.map((item, index) => (
        <Promocode
          key={`site_promo_${index}`}
          variant={"table"}
          promocode={item?.promocode}
          description={item?.description?.[queryLang]}
          title={name}
          image={imageFile}
          favicon={favicon}
          faviconContentType={faviconContentType}
          time={item?.endDate}
          eternal={item?.eternal}
          siteId={data?.id}
        />
      ))
    : null;
};
