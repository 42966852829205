import { useEffect, useRef, useState } from "react";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RenderReviews } from "../../../../components/common/RenderReviews/RenderReviews";
import { SkeletonReviews } from "../../../../components/ui/Skeleton/SkeletonReviews/SkeletonReviews";
import { ApiService } from "../../../../services";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { StyledCaptchaBox } from "../../../ServicePage/components/About/About.styled";
import { NoData } from "../../components/NoData/NoData";
import { SortBlock } from "../../components/SortBlock/SortBlock";

import { StyledListReview } from "./ListReview.styled";

export const ListReview = () => {
  // **Ref
  const captchaRef = useRef(null);
  const { t } = useTranslation();
  // **Props
  const options = t("dashboard.reviews.sortList", {
    returnObjects: true
  }).map((sortType) => ({
    label: sortType.label,
    value: sortType.value
  }));

  // **Redux state
  const { captchaConfig } = useSelector(getAllContent);
  const { enable, clientKey } = captchaConfig.data;
  // **Local state
  const [, setTokenCaptcha] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState({
    content: [],
    totalElements: 0,
    totalPages: 0
  });
  const [pageParams, setPageParams] = useState({
    sort: "CREATION_DATE",
    page: 1,
    size: 10
  });

  const changeSortType = (type) => {
    setPageParams({ ...pageParams, sort: type });
  };

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      page
    });
  };

  const fetchProxyRevies = () => {
    ApiService.getProxyReviewsCabinet({
      ...pageParams,
      page: pageParams.page - 1
    })
      .then((response) => {
        if (response?.status === 200 && response?.data) {
          setReviews(response.data);
        } else {
          toast.error(t("notifications.apiError"));
        }
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchProxyRevies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams]);

  return (
    <StyledListReview noData={!loading && reviews?.totalElements === 0}>
      {!loading &&
        reviews?.content?.length !== 0 &&
        reviews?.totalElements !== 0 && (
          <>
            <SortBlock
              value={pageParams.sort}
              label={t("proxySitePage.content.reviews.top.title")}
              options={options}
              changeSortType={changeSortType}
            />
            <RenderReviews
              reviews={reviews?.content}
              totalElements={reviews?.totalElements}
              pageSize={pageParams.size}
              currentPage={pageParams.page}
              changeHandler={fetchProxyRevies}
              onChangePage={pageChangeHandler}
              paginationDisabled={2}
              isDashboard
              captchaRef={captchaRef}
              setTokenCaptcha={setTokenCaptcha}
            />
            {enable && clientKey && (
              <StyledCaptchaBox>
                <HCaptcha
                  ref={captchaRef}
                  sitekey={clientKey}
                  onVerify={setTokenCaptcha}
                  onExpire={() => setTokenCaptcha(null)}
                  onError={(err) => toast.error(err?.message)}
                  size="invisible"
                />
              </StyledCaptchaBox>
            )}
          </>
        )}
      {loading && <SkeletonReviews cells={10} isDashboard />}
      {!loading && reviews?.totalElements === 0 && (
        <NoData
          title={t("dashboard.reviews.noData.title")}
          subtitle={t("dashboard.reviews.noData.subtitle")}
          srcX1={"/img/dashboard/no-reviews-x1.png"}
          srcX2={"/img/dashboard/no-reviews-x2.png"}
          imgMaxWidth={343}
        />
      )}
    </StyledListReview>
  );
};
