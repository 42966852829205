import * as yup from "yup";

import {
  EMAIL_REGEX,
  EMAIL_STAGE_REGEX,
  URL_START_WITH_HTTPS,
  URL_WITHOUT_HTTPS
} from "../constants";

export const ServiceSchema = (trns, languages, proxyForLang, social) => {
  const languagesValidation = {};

  languages.forEach((lang) => {
    languagesValidation[lang.value] = yup
      .string()
      .required(trns.allLanguages.rules.allMustBeFilled)
      .test({
        name: "min",
        message: trns.description.rules.minLength,
        test: (value) => value?.length >= 50
      })
      .test({
        name: "max",
        message: trns.description.rules.maxLength500,
        test: (value) => value?.length <= 500
      });
  });

  const proxyTypesValidation = {};
  proxyForLang.forEach((data) => {
    proxyTypesValidation[data.type] = yup.boolean();
  });

  const proxyValidation = {};
  proxyForLang.forEach((data) => {
    if (data.checked) {
      if (data.type === "ResidentialProxy") {
        proxyValidation[data.type] = yup.object().shape({
          link: yup
            .string()
            .required(trns.link.rules.required)
            .matches(URL_WITHOUT_HTTPS, trns.link.rules.url),
          minRent: yup.string().required(trns.minRent.rules.required),
          minPrice: yup
            .string()
            .required(trns.minPrice.rules.required)
            .test(
              "positive",
              trns.minPrice.rules.positive,
              (value) => Number(value) > 0
            ),
          pullIp: yup
            .string()
            .required(trns.pullIP.rules.required)
            .test(
              "positive",
              trns.pullIP.rules.positive,
              (value) => Number(value) > 0
            ),
          minGb: yup
            .string()
            .required(trns.minGB.rules.required)
            .test(
              "positive",
              trns.minGB.rules.positive,
              (value) => Number(value) > 0
            )
            .required(trns.minGB.rules.required)
        });
      } else {
        const shape = {
          link: yup
            .string()
            .required(trns.link.rules.required)
            .matches(URL_WITHOUT_HTTPS, trns.link.rules.url),
          minRent: yup.string().required(trns.minRent.rules.required),
          minPrice: yup
            .string()
            .test(
              "positive",
              trns.minPrice.rules.positive,
              (value) => Number(value) > 0
            )
            .required(trns.minPrice.rules.required),
          locations: yup
            .array()
            .min(1, trns.countries.rules.required)
            .required(trns.countries.rules.required)
        };
        if (data.type !== "FreeProxy") {
          shape.minIpAmount = yup
            .string()
            .test({
              name: "positive",
              message: trns.minIP.rules.positive,
              test: (value) => Number(value) > 0
            })
            .test({
              name: "integer",
              message: trns.minIP.rules.integer,
              test: (value) => Number.isInteger(Number(value))
            })
            .required(trns.minIP.rules.required);
        }
        proxyValidation[data.type] = yup.object().shape(shape);
      }
    }
  });

  const socialNetworksValidation = {};
  social?.forEach((data) => {
    if (data.code === "email") {
      socialNetworksValidation[data.code] = yup
        .string()
        .required(trns.email.rules.required)
        .matches(
          process.env?.REACT_APP_MODE === "prod"
            ? EMAIL_REGEX
            : EMAIL_STAGE_REGEX,
          trns.email.rules.email
        );
    } else {
      socialNetworksValidation[data.code] = yup.string().test({
        name: "url",
        message: trns.socialLink.rules.https,
        test: (value) =>
          !value || value?.length === 0 || URL_START_WITH_HTTPS.test(value)
      });
    }
  });
  return yup.object().shape({
    link: yup
      .string()
      .required(trns.link.rules.required)
      .matches(URL_WITHOUT_HTTPS, trns.link.rules.url),
    description: yup.object().shape(languagesValidation),
    proxyTypes: yup
      .object()
      .shape(proxyTypesValidation)
      .test("atLeastOne", trns.proxyType.rules.minOne, (value) => {
        const values = Object.values(value);
        return values.some(Boolean);
      }),
    proxy: yup.object().shape(proxyValidation),
    refunds: yup.string(),
    refundPeriod: yup.string().when("refunds", {
      is: (refunds) => refunds === "true",
      then: () =>
        yup
          .string()
          .test(
            "positive",
            trns.refundDays.rules.positive,
            (value) => Number(value) > 0
          )
          .test("integer", trns.refundDays.rules.integer, (value) =>
            Number.isInteger(Number(value))
          ),
      otherwise: () => yup.string()
    }),
    socialNetworks: yup.object().shape(socialNetworksValidation),
    speedFrom: yup
      .string()
      .test("number", trns.speed.rules.number, (value) =>
        value ? !isNaN(Number(value)) : true
      )
      .test("positive", trns.speed.rules.positive, (value) =>
        value ? Number(value) >= 0 : true
      ),

    speedTo: yup
      .string()
      .test("number", trns.speed.rules.number, (value) =>
        value ? !isNaN(Number(value)) : true
      )
      .test("positive", trns.speed.rules.positive, (value) =>
        value ? Number(value) >= 0 : true
      )
      .test("speedFromGreater", trns.speed.rules.greater, (value, context) =>
        value ? Number(value) >= context.parent.speedFrom : true
      ),
    pingFrom: yup
      .string()
      .test("number", trns.speed.rules.number, (value) =>
        value ? !isNaN(Number(value)) : true
      )
      .test("positive", trns.ping.rules.positive, (value) =>
        value ? Number(value) >= 0 : true
      ),
    pingTo: yup
      .string()
      .test("number", trns.speed.rules.number, (value) =>
        value ? !isNaN(Number(value)) : true
      )
      .test("positive", trns.ping.rules.positive, (value) =>
        value ? Number(value) >= 0 : true
      )
      .test("speedFromGreater", trns.speed.rules.greater, (value, context) =>
        value ? Number(value) >= context.parent.pingFrom : true
      )
  });
};
