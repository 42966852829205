/* eslint-disable react/no-unknown-property */
import PT from "prop-types";
import { useSelector } from "react-redux";

import { useMatchedRoute } from "../../../hooks";
import { getAllContent } from "../../../store/reducers/ContentReducer/Content.selectors";
import { getHighlightedWord } from "../../../utils/helpers/text.helper";

import {
  ContentWrapper,
  Description,
  ImageWrapper,
  MainContainer,
  MainSection,
  StyledButton
} from "./Main.styled";
import { HighlightedTitle } from "./components/HighlightedTitle";

export const Main = ({
  buttonLabel = "Select service",
  images,
  animation,
  button = true,
  className,
  star = false,
  handleButtonClick
}) => {
  const { pageContent } = useSelector(getAllContent);
  // current page
  const page = useMatchedRoute();

  const currentHighlighWord = () => {
    if (!pageContent.current?.data?.h1) {
      return "";
    }
    if (page?.tag !== "proxy") {
      return pageContent?.current?.data?.highlightedWord;
    }
    return getHighlightedWord(pageContent?.current?.data?.highlightedWord);
  };

  // current highlightedWord
  const highlightedWord = currentHighlighWord();

  return (
    <MainSection>
      <MainContainer className={className}>
        <ContentWrapper className="main-content-wrapper">
          {pageContent.isDataLoaded && pageContent.current?.data?.h1 ? (
            <HighlightedTitle
              heading={pageContent.current?.data?.h1}
              highlightWord={highlightedWord}
              star={star}
            />
          ) : null}

          {pageContent.current?.data?.mainDescription && (
            <Description className="main-description">
              {pageContent.current?.data?.mainDescription}
            </Description>
          )}

          {button && (
            <StyledButton size="lg" onClick={handleButtonClick}>
              {buttonLabel}
            </StyledButton>
          )}
        </ContentWrapper>
        <ImageWrapper className={"main-image-wrapper"}>
          {animation ? animation : null}
          <picture>
            {images.mobile1x && (
              <source
                srcSet={`${images.mobile1x} 1x ${
                  images.mobile2x ? `, ${images.mobile2x} 2x` : ""
                }`}
              />
            )}

            <img
              src={images.mobile2x}
              alt={pageContent.current?.data?.h1}
              fetchpriority="high"
            />
          </picture>
        </ImageWrapper>
      </MainContainer>
    </MainSection>
  );
};

Main.propTypes = {
  highlightWord: PT.string,
  title: PT.string,
  subtitle: PT.string,
  subtitleHtml: PT.string,
  buttonLabel: PT.string,
  images: PT.shape({}),
  button: PT.bool,
  star: PT.bool,
  className: PT.string,
  animation: PT.node,
  handleButtonClick: PT.func
};
