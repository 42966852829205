import { useEffect, useState } from "react";

import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { FormInput } from "../../../../../components/forms/FormInput/FormInput";
import { LabelCountry } from "../../../../../components/ui/LabelContry/LabelCountry";
import { useLangUrlDefault } from "../../../../../hooks";
import {
  StyledBoxHidden,
  StyledSelect
} from "../../../AddEditPromocode/Form/BlockDescriptions/BlockDescriptions.styled";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

export const BlockDescriptions = () => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { errors } = useFormState();
  const [lang, setLang] = useState(queryLang);

  const options = [
    {
      value: "en",
      label: <LabelCountry code="en">{t("languages.en")}</LabelCountry>
    },
    {
      value: "ru",
      label: <LabelCountry code="ru">{t("languages.ru")}</LabelCountry>
    },
    {
      value: "ua",
      label: <LabelCountry code="ua">{t("languages.ua")}</LabelCountry>
    }
  ];
  useEffect(() => {
    if (errors?.description && !errors?.description?.[lang]) {
      setLang(Object.keys(errors.description)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.description.title")}
      subtitle={t("dashboard.services.addEdit.blocks.description.subtitle")}
    >
      <StyledSelect
        defaultValue={queryLang}
        value={lang}
        onChange={setLang}
        options={options}
        size={"sm"}
      />
      {options.map((option) => (
        <StyledBoxHidden hidden={lang !== option.value} key={option.value}>
          <FormInput
            name={`description.${option.value}`}
            label={t("forms.description.label")}
            placeholder={t("forms.description.placeholder")}
            type={"textarea"}
            showMaxLenght={500}
            showError
          />
        </StyledBoxHidden>
      ))}
    </FormBlock>
  );
};
