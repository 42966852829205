import {
  animationWebRTCLeak,
  animationWebRTCLeakIp
} from "../../../../assets/animations";
import { Rive } from "../../../../components/ui/Rive/Rive";
import { useSeoBlock } from "../../../../hooks";
import { parseSeoData } from "../../../../utils/helpers";

import {
  AnimmationWrapper,
  Description,
  RestyledContainer,
  RestyledTitle,
  StyledContent,
  // StyledImage,
  // StyledPicture,
  StyledSection
} from "./WebRTCLeakTestInfo.styled";

export const WebRTCLeakTestInfo = () => {
  const { data } = useSeoBlock([
    "toolsWebRTCWhatIsIt",
    "toolsWebRTCWhatDoesIt"
  ]);

  return (
    <>
      {data?.toolsWebRTCWhatIsIt?.showOnFront && (
        <StyledSection>
          <RestyledContainer>
            <StyledContent>
              <RestyledTitle tag="h2">
                {parseSeoData(data?.toolsWebRTCWhatIsIt?.title)}
              </RestyledTitle>
              <Description>
                {parseSeoData(data?.toolsWebRTCWhatIsIt?.description)}
              </Description>
            </StyledContent>
            <AnimmationWrapper>
              <Rive
                src={animationWebRTCLeak}
                autoPlay
                top={-74}
                right={-80}
                left={-80}
                bottom={-74}
              />
            </AnimmationWrapper>
            {/* <StyledPicture>
              <source
                srcSet="img/webRTC-leak-test/webRTC-leak-info-image-mobile.svg"
                media="(max-width: 992px)"
              />
              <StyledImage
                src="img/webRTC-leak-test/webRTC-leak-info-image-mobile.svg"
                alt={parseSeoData(data?.toolsWebRTCWhatIsIt?.title, "TEXT")}
              />
            </StyledPicture> */}
          </RestyledContainer>
        </StyledSection>
      )}
      {data?.toolsWebRTCWhatDoesIt?.showOnFront && (
        <StyledSection variant="light">
          <RestyledContainer variant="light">
            <AnimmationWrapper variant="light">
              <Rive
                src={animationWebRTCLeakIp}
                autoPlay
                top={-85}
                right={-71}
                bottom={-58}
                left={-70}
              />
            </AnimmationWrapper>
            {/* <StyledPicture>
              <source srcSet="img/webRTC-leak-test/webRTC-leak-info-image-mobile1.svg" />
              <StyledImage
                src="img/webRTC-leak-test/webRTC-leak-info-image-mobile1.svg"
                alt={parseSeoData(data?.toolsWebRTCWhatDoesIt?.title, "TEXT")}
              />
            </StyledPicture> */}
            <StyledContent variant="light">
              <RestyledTitle tag="h2">
                {parseSeoData(data?.toolsWebRTCWhatDoesIt?.title)}
              </RestyledTitle>
              <Description>
                {parseSeoData(data?.toolsWebRTCWhatDoesIt?.description)}
              </Description>
            </StyledContent>
          </RestyledContainer>
        </StyledSection>
      )}
    </>
  );
};
