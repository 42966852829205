/* eslint-disable import/namespace */
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useLangUrlDefault, useMatchedRoute } from "../../hooks";
import { getAllBlogInner } from "../../store/reducers/BlogInnerReducer/BlogInner.selectors";
import { getAllContent } from "../../store/reducers/ContentReducer/Content.selectors";
import { getAllProxySite } from "../../store/reducers/ProxySiteReducer/ProxySite.selectors";
import { getSiteDescription } from "../../utils/helpers";
import { generateMetaTags } from "../../utils/helpers/generateMetaTags.helper";

const BLOG_INNER_TAG = "bloginner";
const PROXY_SITE_TAG = "proxy-site";

export const MetaPage = () => {
  // **Props
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();

  const route = useMatchedRoute();

  // **Redux state
  const {
    pageContent: { current }
  } = useSelector(getAllContent);
  const { routeContent } = useSelector(getAllContent);
  const { site } = useSelector(getAllProxySite);
  const { content } = useSelector(getAllBlogInner);

  const homeURL = process.env.REACT_APP_URL;

  const ENG_LOCATION =
    queryLang === "en"
      ? routeContent?.location
      : routeContent?.location?.slice(3);

  const getCurrentTitle = () => {
    if (current?.data?.title?.length > 0) {
      return current?.data?.title;
    }
    if (route?.tag === BLOG_INNER_TAG && content?.data?.title?.length > 0) {
      return content?.data?.title;
    }
    return t("metaPage.title");
  };

  const getCurrentDescription = () => {
    if (
      route?.tag === PROXY_SITE_TAG &&
      site.data?.description &&
      getSiteDescription(site.data?.description, queryLang, false)
    ) {
      return getSiteDescription(site.data?.description, queryLang, false);
    }
    if (current?.data?.description?.length > 0) {
      return current?.data?.description;
    }
    if (
      route?.tag === BLOG_INNER_TAG &&
      content?.data?.description?.length > 0
    ) {
      return content?.data?.description;
    }
    return t("metaPage.description");
  };
  const currentTitle = getCurrentTitle();
  const currentDescription = getCurrentDescription();

  return (
    <Helmet>
      {generateMetaTags(
        currentTitle,
        routeContent?.tag === PROXY_SITE_TAG && currentDescription.length > 160
          ? `${currentDescription.slice(0, 160)}...`
          : currentDescription,
        queryLang,
        false
      )}
      {routeContent?.tag === BLOG_INNER_TAG ? (
        content?.data?.langLinks?.hasOwnProperty("en") && (
          <link
            href={`${homeURL}/blog/${content?.data?.langLinks?.en}/`}
            rel="alternate"
            hrefLang="en"
          />
        )
      ) : (
        <link
          href={`${homeURL}${ENG_LOCATION}`}
          rel="alternate"
          hrefLang="en"
        />
      )}
      {routeContent?.tag === BLOG_INNER_TAG ? (
        content?.data?.langLinks?.hasOwnProperty("ru") && (
          <link
            href={`${homeURL}/ru/blog/${content?.data?.langLinks?.ru}/`}
            rel="alternate"
            hrefLang="ru"
          />
        )
      ) : (
        <link
          href={`${homeURL}/ru${ENG_LOCATION}`}
          rel="alternate"
          hrefLang="ru"
        />
      )}
      {routeContent?.tag === BLOG_INNER_TAG ? (
        content?.data?.langLinks?.hasOwnProperty("ua") && (
          <link
            href={`${homeURL}/ua/blog/${content?.data?.langLinks?.ua}/`}
            rel="alternate"
            hrefLang="uk-ua"
          />
        )
      ) : (
        <link
          href={`${homeURL}/ua${ENG_LOCATION}`}
          rel="alternate"
          hrefLang="uk-ua"
        />
      )}
      <link
        key="canonical"
        rel="canonical"
        href={`${homeURL}${routeContent?.location}`}
      />
    </Helmet>
  );
};
