import styled from "@emotion/styled";

export const ResultList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;

export const ResultItem = styled.li`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(216, 218, 224, 0.55);
  }
`;

export const ResultItemLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  margin-bottom: 8px;
  max-width: 50%;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const ResultItemValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
  max-width: 50%;
  word-break: break-all;
`;
