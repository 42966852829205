import styled from "@emotion/styled";

import { TextButton } from "../../../../../components/ui/TextButton/TextButton";

export const StyledPromocodesBlock = styled.div`
  display: ${(p) => (p.active ? "flex" : "none")};
  flex-direction: column;
  gap: 16px;

  margin-top: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    gap: ${(p) => (p.noData ? "48px" : "24px")};
  }
`;

export const StyledPromocodesBlockHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const StyledPromocodesList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    gap: 12px;
  }
  & > li {
    width: 100%;
  }
`;

export const StyledPaginationBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  @media (min-width: ${(p) => p.theme.screenSizes.xlMin}) {
    margin-top: 0px;
    gap: 20px;
  }
`;

export const StyledShowMore = styled(TextButton)`
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const StyledNoDataTitle = styled.p`
  color: ${({ theme }) => theme.colors["neutral-800"]};
  font-family: ${({ theme }) => theme.fontFamily.title};
  text-align: ${(p) => (p.alternative ? "left" : "center")};
  word-wrap: break-all;

  font-size: 20px;
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 125%;
  margin-bottom: ${(p) => (p.alternative ? "16px" : "0")};

  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    max-width: ${(p) => (p.alternative ? "auto" : "490px")};
    font-size: 24px;
    line-height: 120%;
    margin-bottom: ${(p) => (p.alternative ? "24px" : "0")};
  }
`;

export const StyledNoDataSubtitle = styled.p`
  color: ${({ theme }) => theme.colors["neutral-500"]};
  font-family: ${({ theme }) => theme.fontFamily.lato};
  text-align: center;
  word-wrap: break-all;

  font-size: 16px;
  font-style: ${({ theme }) => theme.fontStyle.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 150%;
  margin-top: 8px;
  margin-bottom: 24px;
  @media (min-width: ${({ theme }) => theme.screenSizes.lgMin}) {
    margin-bottom: 36px;
    max-width: 348px;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: -15px;
  font-size: 0;
  max-width: 286px;
`;

export const StyledNoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
