import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "swiper/modules";

import { Container } from "../../../components/common/Container/Container";
import {
  Swiper,
  SwiperSlide
} from "../../../components/ui/Swiper/Swiper.styled";
import {
  useDispatchedActions,
  useLangUrlDefault,
  useSeoBlock
} from "../../../hooks";
import { getAllPromocodes } from "../../../store/reducers/PromocodesReducer/Promocodes.selectors";
import { parseSeoData } from "../../../utils/helpers";

import { OfferItem } from "./OfferItem/OfferItem";
import {
  StyledSpesialOfferCard,
  StyledSpesialOffersDescription,
  StyledSpesialOffersHeader,
  StyledSpesialOffersListBox,
  StyledSpesialOffersTitle
} from "./SpesialOffer.styled";

export const SpecialOffer = () => {
  const [queryLang] = useLangUrlDefault();
  const { getSpecialPromocodes } = useDispatchedActions();
  const [searchParams] = useSearchParams();
  const { data } = useSeoBlock(["specialOffer"]);
  const { special } = useSelector(getAllPromocodes);

  const proxyType = searchParams.get("fpt") || "IPv4";

  const currentSpecial = special?.data?.length
    ? special?.data?.filter(
        (item) =>
          Object.keys(item.showIn)?.length === 0 ||
          (Object.keys(item.showIn)?.length > 0 && item.showIn[queryLang])
      )
    : [];

  useEffect(() => {
    if (!special?.isDataLoaded || special?.type !== proxyType) {
      getSpecialPromocodes(proxyType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyType]);

  return (
    <section>
      <Container>
        <StyledSpesialOfferCard shadow size={"large"}>
          <StyledSpesialOffersHeader>
            <StyledSpesialOffersTitle>
              {parseSeoData(data?.specialOffer?.title)}
            </StyledSpesialOffersTitle>
            <StyledSpesialOffersDescription>
              {parseSeoData(data?.specialOffer?.description)}
            </StyledSpesialOffersDescription>
          </StyledSpesialOffersHeader>
          <StyledSpesialOffersListBox>
            <Swiper
              direction="horizontal"
              modules={[Pagination]}
              pagination={{ clickable: true }}
              wrapperTag="ul"
              style={{ overflow: "visible", position: "static" }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10
                },
                400: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                480: {
                  slidesPerView: 1.2,
                  spaceBetween: 20
                },
                550: {
                  slidesPerView: 1.5,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20
                },
                992: {
                  slidesPerView: 2,
                  spaceBetween: 36
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 24
                }
              }}
            >
              {special?.isDataLoaded ? (
                <>
                  {currentSpecial?.length
                    ? currentSpecial.map((item) => (
                        <SwiperSlide
                          key={`special-offer-${item?.id}`}
                          tag="li"
                          style={{
                            height: "auto"
                          }}
                        >
                          <OfferItem item={item} />
                        </SwiperSlide>
                      ))
                    : null}
                </>
              ) : (
                Array.from({ length: 3 }).map((_, index) => (
                  <SwiperSlide
                    key={`special-offer-${index}`}
                    tag="li"
                    style={{
                      height: "auto"
                    }}
                  >
                    <OfferItem loading />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </StyledSpesialOffersListBox>
        </StyledSpesialOfferCard>
      </Container>
    </section>
  );
};
