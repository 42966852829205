import styled from "@emotion/styled";

export const AppWrapperStyled = styled.div`
  background-color: ${(p) => p.theme.colors["background-color-seo"]};
  display: flex;
  flex-direction: column;
  overflow: ${(p) => (p.bloginner ? "initial" : "hidden")};
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
  transition: transform 0.3s;

  &--auth {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 15;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
`;
