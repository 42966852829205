import { useLangUrlDefault } from "../../../../hooks";

import {
  StyledCreateButton,
  StyledTitle,
  StyledTitleBlock
} from "./TitleBlock.styled";

export const TitleBlock = ({ title, linkTo = "", linkName }) => {
  const [, hrefLang] = useLangUrlDefault();
  let link = linkTo;

  if (linkTo && linkTo?.slice(0, 1) !== "/") {
    link = `/${linkTo}`;
  }

  if (hrefLang && linkTo && linkTo?.slice(0, 3) !== hrefLang) {
    link = `${hrefLang}${linkTo}`;
  }

  return (
    <StyledTitleBlock isButton={!!link}>
      <StyledTitle size={"sm"}>{title}</StyledTitle>
      {link ? (
        <StyledCreateButton linkTo={link} iconLeft={"plus"}>
          {linkName}
        </StyledCreateButton>
      ) : null}
    </StyledTitleBlock>
  );
};
