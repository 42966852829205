import * as yup from "yup";

import { REGEX_IPV6, REGEX_IP } from "../constants";

export const IpBlacklistCheckSchema = (trns) =>
  yup.object().shape({
    ipAddress: yup
      .string()
      .required(trns.form.rules.required)
      .test(
        "validValue",
        trns.form.rules.ip,
        (value) => REGEX_IP.test(value) || REGEX_IPV6.test(value)
      )
  });
