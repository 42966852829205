import { locationsList } from "../../../../utils/constants";

import { LocationItem } from "./LocationsItem/LocationItem";

export const Locations = ({
  selectedLocation,
  onClick,
  className = "",
  setCurrentStep,
  dataTestId
}) => {
  const handleClick = (location) => {
    onClick(location);
    if (setCurrentStep) {
      setCurrentStep("countries");
    }
  };
  return (
    <ul
      className={className}
      {...(dataTestId && { "data-testid": dataTestId })}
    >
      {locationsList.map((item) => (
        <LocationItem
          key={item.translation}
          title={item.translation}
          isSelected={item.translation === selectedLocation}
          onClick={() => handleClick(item.translation)}
          dataTestId={dataTestId}
        />
      ))}
    </ul>
  );
};
