import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import animation from "../../../assets/animations/my_anonymity_v2.riv";
import {
  fingerprintFaqImage,
  fingerprintFaqImage2x,
  myAnonymityHeroImageMobile
} from "../../../assets/img";
import { SeoSection } from "../../../components/common/SeoSection/SeoSection";
import { Rive } from "../../../components/ui/Rive/Rive";
import { useDispatchedActions } from "../../../hooks";
import { useUserIp } from "../../../hooks/useUserIp";
import { ApiService } from "../../../services";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllTools } from "../../../store/reducers/ToolsReducer/Tools.selectors";
import { getJsAttributes } from "../../../utils/helpers/getJsAttributes.helper";

import { CheckAnonymity } from "./CheckAnonymity/CheckAnonymity";
import { IpDetails } from "./IpDetails/IpDetails";
import { MyAnonymityInfo } from "./MyAnonymityInfo/MyAnonymityInfo";
import {
  MyAnonymitySection,
  RestyledFAQ,
  RestyledMainSection
} from "./MyAnonymityPage.styled";

const MyAnonymityPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [inBlacklist, setInBlacklist] = useState(false);
  const [result, setResult] = useState(null);
  const [score, setScore] = useState(null);

  const { t } = useTranslation();

  const userIp = useUserIp();
  const { anonymity } = useSelector(getAllTools);
  const { fetchAnonymity } = useDispatchedActions();

  const handleClick = () => {
    setIsLoading(true);
    const params = getJsAttributes();

    ApiService.getAnonimityScore(params)
      .then((res) => {
        if (res.status !== 200) {
          throw res;
        }
        setScore(res.data);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error("An error occurred:", error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    if (!anonymity?.isLoaded) {
      fetchAnonymity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userIp?.ipAddress) {
      ApiService.getIpBlacklist(userIp.ipAddress).then((res) => {
        if (res.status === 200 && res.data) {
          const findedInBlacklist = Object.keys(res.data)?.length
            ? Object.keys(res.data).filter((key) => res.data?.[key])?.length
            : 0;
          setInBlacklist(findedInBlacklist);
        }
      });
    }
  }, [userIp]);

  useEffect(() => {
    if (score && typeof inBlacklist === "number" && anonymity?.isLoaded) {
      setResult({
        score,
        inBlacklist: !!inBlacklist,
        ...anonymity?.data
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score, inBlacklist, anonymity?.isLoaded]);

  return (
    <MyAnonymitySection>
      <RestyledMainSection
        button={false}
        images={{
          mobile1x: myAnonymityHeroImageMobile,
          mobile2x: myAnonymityHeroImageMobile
        }}
        animation={
          <Rive
            src={animation}
            autoPlay
            top={-108}
            right={-82}
            bottom={-108}
            left={-82}
          />
        }
      />
      <CheckAnonymity
        userIp={userIp}
        isLoading={isLoading}
        result={result}
        isLoaded={isLoaded}
        handleClick={handleClick}
      />
      <IpDetails anonymity={result} isLoading={isLoading} />
      <MyAnonymityInfo />
      <RestyledFAQ
        heading={t("fingerprint.faq")}
        image={fingerprintFaqImage}
        image2x={fingerprintFaqImage2x}
        tools
      />
      <SeoSection />
    </MyAnonymitySection>
  );
};

export default MyAnonymityPage;

MyAnonymityPage.getServerSideState = async (store, params) => {
  const { data } = await $api.post("/api/front/faqs", {
    pageTag: params.tag,
    languageCode: params.locale,
    params: params.params
  });
  const fetchSeoBlockParams = {
    languageCode: params.locale,
    keys: ["toolsAnonymityWhatIsIt", "toolsAnonymityWhatDoesIt"]
  };
  const { data: seoBlock } = await $api.post(
    "/api/front/seoBlock",
    fetchSeoBlockParams
  );
  if (seoBlock) {
    store.dispatch(
      AllActions.setSeoBlock({ data: seoBlock, params: fetchSeoBlockParams })
    );
  }
  if (data) {
    store.dispatch(
      AllActions.setFaqContent({
        data,
        pageTag: params.tag,
        languageCode: params.locale
      })
    );
  }
};
