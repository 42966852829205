import styled from "@emotion/styled";

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 382px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    height: 93%;
    max-height: 93%;
    position: absolute;
    margin-top: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(238, 239, 240, 0.05);
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(238, 239, 240, 0.08);
  }
`;

export const StyledItem = styled.li`
  padding: 12px;
  margin-right: 12px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover,
  &:focus,
  &:active {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);
  }
`;

export const StyledName = styled.span`
  font-family: ${(p) => p.theme.fontFamily.lato};
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 12px;
`;
