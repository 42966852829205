import styled from "@emotion/styled";

export const BlockCharacteristicsStyledBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BlockCharacteristicsStyledBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  & > * {
    margin: 0 !important;
  }
`;

export const BlockCharacteristicsRowTitle = styled.div`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 16px;
`;

export const BlockCharacteristicsStyledBodyRowTitle = styled.div`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 16px;
`;

export const BlockCharacteristicsInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const BlockCharacteristicsInputPlaceholder = styled.span`
  position: absolute;
  left: ${(p) => (p.length ? `calc(${p.length * 10}px + 24px)` : "0")};
  top: 38px;
  color: ${(p) => p.theme.colors["neutral-300"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
`;
