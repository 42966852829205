import * as yup from "yup";

export const PromocodeSchema = (trns, languages) => {
  const languagesValidation = {};
  languages.forEach((lang) => {
    languagesValidation[lang.value] = yup
      .string()
      .required(trns.allLanguages.rules.allMustBeFilled)
      .test({
        name: "min",
        message: trns.description.rules.minLength,
        test: (value) => value?.length >= 50
      })
      .test({
        name: "max",
        message: trns.description.rules.maxLength,
        test: (value) => value?.length <= 100
      });
  });

  return yup.object().shape({
    siteId: yup.string().required(trns.site.rules.required),
    promocode: yup.string().required(trns.promocode.rules.required),
    description: yup.object().shape(languagesValidation),
    dateRange: yup
      .array()
      .of(yup.date())
      .when("eternal", {
        is: (eternal) => eternal === "date",
        then: () =>
          yup
            .array()
            .of(yup.date())
            .test("allFilled", trns.date.rules.allRequired, (value) =>
              value.every(Boolean)
            )
            .test(
              "endDateGreaterNow",
              trns.date.rules.endDateGreaterNow,
              (value) => {
                const [, endDate] = value;
                return endDate?.getTime() > new Date().getTime();
              }
            )
      }),
    eternal: yup.string().required("trns.eternal.rules.required")

    // startDate: yup.date().when("eternal", {
    //   is: (eternal) => eternal === false,
    //   then: () => yup.date().required(trns.date.rules.required)
    // }),
    // endDate: yup.date().when("eternal", {
    //   is: (eternal) => eternal === false,
    //   then: () => yup.date().required(trns.date.rules.required)
    // })
  });
};
