import { Fragment } from "react";

import { useTranslation } from "react-i18next";

import { FormCheckbox } from "../../../../../components/forms/FormCheckbox/FormCheckbox";
import { FormInput } from "../../../../../components/forms/FormInput/FormInput";
import { Separator } from "../../../../../components/ui/Separator/Separator";
import { FormBlock } from "../../../components/FormBlock/FormBlock";

import {
  FeaturesItemBox,
  FeaturesItemBoxChechbox,
  FeaturesItemLabel,
  FeaturesItemLabelIcon,
  FeaturesItemLalelDescription,
  StyledBlockFeaturesBody,
  StyledBlockFeaturesItem
} from "./BlockFeatures.styled";

export const BlockFeatures = ({ formData }) => {
  const { t } = useTranslation();

  const featuresData = [
    {
      name: "freeTest",
      label: t("proxy.freeTest.label"),
      data: t("yesno", { returnObjects: true }),
      dataTestId: "addEditServiceForm_freeTest",
      defaultValue: true
    },
    {
      name: "refunds",
      label: t("proxy.refunds.label"),
      data: t("yesno", { returnObjects: true }),
      dataTestId: "addEditServiceForm_refunds",
      defaultValue: true,
      ammountName: "refundPeriod",
      dataTestIdAmmount: "addEditServiceForm_refundPeriod"
    },
    {
      name: "replacementPossibility",
      label: t("proxy.replacementPossibility.label"),
      data: t("yesno", { returnObjects: true }),
      dataTestId: "addEditServiceForm_replacementPossibility",
      defaultValue: true
    },
    {
      name: "affiliateProgram",
      label: t("proxy.affiliateProgram.label"),
      data: t("yesno", { returnObjects: true }),
      dataTestId: "addEditServiceForm_affiliateProgram",
      defaultValue: true
    },
    {
      name: "api",
      label: t("proxy.api.label"),
      data: t("yesno", { returnObjects: true }),
      dataTestId: "addEditServiceForm_api",
      defaultValue: true
    }
  ];
  return (
    <FormBlock
      title={t("dashboard.services.addEdit.blocks.features.title")}
      subtitle={t("dashboard.services.addEdit.blocks.features.subtitle")}
    >
      <StyledBlockFeaturesBody>
        {featuresData.map(
          (
            {
              name,
              label,
              data,
              defaultValue,
              ammountName,
              dataTestId,
              dataTestIdAmmount
            },
            index
          ) => (
            <Fragment key={`${name}-${index}`}>
              <StyledBlockFeaturesItem>
                <FeaturesItemLabel>
                  {label}
                  {!!ammountName && formData?.refunds === "true" && (
                    <FeaturesItemLalelDescription>
                      <FeaturesItemLabelIcon name="infoChat" />
                      {t(
                        "dashboard.services.addEdit.blocks.features.descriptions.refundDays"
                      )}
                    </FeaturesItemLalelDescription>
                  )}
                </FeaturesItemLabel>
                <FeaturesItemBox>
                  <FeaturesItemBoxChechbox>
                    {data.map((item) => (
                      <FormCheckbox
                        key={item.label}
                        name={name}
                        dataTestId={dataTestId}
                        label={item.label}
                        defaultValue={defaultValue.toString()}
                        radioValue={item.value.toString()}
                        type={"radio"}
                      />
                    ))}
                  </FeaturesItemBoxChechbox>
                  {!!ammountName && formData?.refunds === "true" && (
                    <FormInput
                      name={ammountName}
                      type={"number"}
                      showError
                      dataTestId={dataTestIdAmmount}
                      min={"0"}
                    />
                  )}
                </FeaturesItemBox>
              </StyledBlockFeaturesItem>
              {index !== featuresData?.length - 1 ? <Separator /> : null}
            </Fragment>
          )
        )}
      </StyledBlockFeaturesBody>
    </FormBlock>
  );
};
