import { useEffect } from "react";

import { useFormContext, useFormState } from "react-hook-form";

import { FormCheckbox } from "../../../../../../../components/forms/FormCheckbox/FormCheckbox";

import { RestyledFormError, StyledProxyTypesBox } from "./ProxyTypes.styled";

export const ProxyTypes = ({ data }) => {
  const { errors } = useFormState();
  const { watch } = useFormContext();
  const proxyTypes = watch("proxyTypes");

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      if (!errors.proxyTypes) return;

      const [firstKey] = Object.keys(errors);

      if (firstKey !== "proxyTypes") return;

      const element = document.getElementById(`error-${firstKey}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    }
  }, [errors]);

  return (
    <div>
      <StyledProxyTypesBox>
        {data?.map(({ type, name }) => (
          <FormCheckbox
            key={type}
            name={`proxyTypes.${type}`}
            label={name}
            defaultValue={false}
          />
        ))}
      </StyledProxyTypesBox>
      {errors?.proxyTypes && Object.values(proxyTypes).every((i) => !i) && (
        <RestyledFormError
          message={errors?.proxyTypes?.root?.message}
          errorId={"proxyTypes"}
        />
      )}
    </div>
  );
};
