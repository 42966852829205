import PT from "prop-types";

import { StyledContainer } from "./Container.styled";

export const Container = ({ className = "", children, id }) => (
  <StyledContainer className={className} {...(id && { id })}>{children}</StyledContainer>
);

Container.propTypes = {
  className: PT.string,
  children: PT.node
};
