import camelCase from "lodash.camelcase";
import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { StyledIcon, StyledLocationItem } from "./LocationItem.styled";

export const LocationItem = ({
  title,
  isSelected,
  onClick,
  className = "",
  dataTestId
}) => {
  const { t } = useTranslation();
  return (
    <StyledLocationItem
      active={isSelected}
      className={className}
      onClick={onClick}
      {...(dataTestId && {
        "data-testid": `${dataTestId}_${camelCase(t(`navigation.${title}`))}`
      })}
    >
      {t(`navigation.${title}`)}
      <StyledIcon name="arrowDown" />
    </StyledLocationItem>
  );
};

LocationItem.propTypes = {
  title: PT.string.isRequired,
  isSelected: PT.bool,
  onClick: PT.func,
  className: PT.string,
  dataTestId: PT.string
};
