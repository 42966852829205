import { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { RenderReviews } from "../../../../../../components/common/RenderReviews/RenderReviews";
import { Select } from "../../../../../../components/ui/Select/Select";
import { SkeletonReviews } from "../../../../../../components/ui/Skeleton/SkeletonReviews/SkeletonReviews";
import { useDispatchedActions } from "../../../../../../hooks";
import { getAllProxySite } from "../../../../../../store/reducers/ProxySiteReducer/ProxySite.selectors";

import { BaseScore } from "./BaseScore/BaseScore";
import {
  StyledListReviewContainer,
  StyledListReviewHeader,
  StyledListReviewSortBox,
  StyledListReviewSortLabel,
  StyledListReviewSortSelectBox
} from "./ListReview.styled";

const DEFAULT_PAGE_SIZE = 4;

export const ListReview = ({ active, captchaRef, setTokenCaptcha }) => {
  const { t } = useTranslation();
  const refContainer = useRef(null);
  const { reviews, site } = useSelector(getAllProxySite);
  // **Props
  const { alias } = useParams();
  const options = t("proxySitePage.content.reviews.top.sortList", {
    returnObjects: true
  }).map((sortType) => ({
    label: sortType.label,
    value: sortType.param
  }));

  // **Dispatch
  const { getProxySiteReviews } = useDispatchedActions();

  // **Local state
  const [pageParams, setPageParams] = useState(
    reviews.fetchedParams?.siteId === alias
      ? reviews.fetchedParams
      : {
          page: 0,
          size: DEFAULT_PAGE_SIZE,
          sort: "useful",
          siteId: alias,
          showMore: 0
        }
  );

  const changeSortType = (type) => {
    setPageParams({ ...pageParams, sort: type });
  };

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setPageParams({
      ...pageParams,
      showMore: 0,
      page: page - 1
    });

    refContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  const fetchProxyRevies = () => {
    getProxySiteReviews({
      ...pageParams,
      page:
        pageParams.showMore > 0
          ? pageParams.page + pageParams.showMore
          : pageParams.page,
      siteId: alias
    });
  };

  const incrementPageSizes = () => {
    setPageParams({
      ...pageParams,
      showMore: pageParams.showMore + 1
    });
  };

  // Handling content
  useEffect(() => {
    if (
      reviews.fetchedParams?.sort !== pageParams?.sort ||
      reviews.fetchedParams?.page !== pageParams?.page ||
      reviews.fetchedParams?.size !== pageParams?.size ||
      reviews.fetchedParams?.siteId !== pageParams?.siteId ||
      reviews.fetchedParams?.showMore !== pageParams?.showMore ||
      !reviews?.isDataLoaded
    ) {
      fetchProxyRevies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageParams]);

  // Handling error
  useEffect(() => {
    if (reviews.isError) {
      toast.error(t("notifications.apiError"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews.isError]);

  return (
    <StyledListReviewContainer
      ref={refContainer}
      active={active}
      itemScope
      itemType="https://schema.org/AggregateRating"
    >
      <StyledListReviewHeader>
        <StyledListReviewSortBox>
          <StyledListReviewSortLabel>
            {t("proxySitePage.content.reviews.top.title")}:
          </StyledListReviewSortLabel>
          <StyledListReviewSortSelectBox>
            <Select
              value={pageParams.sort}
              onChange={changeSortType}
              options={options}
            />
          </StyledListReviewSortSelectBox>
        </StyledListReviewSortBox>
        <BaseScore
          loading={!site?.isDataLoaded || !reviews?.isDataLoaded}
          score={site.data?.rating}
          total={reviews.data?.totalElements}
        />
      </StyledListReviewHeader>
      {reviews?.isDataLoaded ? (
        <RenderReviews
          siteTitle={site.data?.title}
          reviews={reviews.data?.content}
          totalElements={reviews.data?.totalElements}
          pageSize={DEFAULT_PAGE_SIZE}
          currentPage={
            pageParams?.showMore > 0
              ? pageParams.page + pageParams.showMore + 1
              : pageParams.page + 1
          }
          changeHandler={fetchProxyRevies}
          onChangePage={pageChangeHandler}
          handlerShowMore={incrementPageSizes}
          paginationDisabled={2}
          captchaRef={captchaRef}
          setTokenCaptcha={setTokenCaptcha}
        />
      ) : (
        <SkeletonReviews cells={4} />
      )}
    </StyledListReviewContainer>
  );
};
