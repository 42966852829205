import { memo } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Select } from "../../../components/ui/Select/Select";
import { useDispatchedActions, useMatchedRoute } from "../../../hooks";
import { PROXY_FILTERS } from "../../../utils/constants/filter.constant";
import { getParamsUrlForFilter } from "../../../utils/helpers";
import { Icon } from "../../ui/Icon/Icon";

import {
  ReStyledDropdown,
  ReStyledDropdownItem,
  StyledSelectBox
} from "./ProxyFilter.styled";

export const ProxyFilter = memo(() => {
  // **Props
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const route = useMatchedRoute();

  // **Redux state
  const { t } = useTranslation();

  // **Dispatch
  const { setActiveFilters } = useDispatchedActions();

  const routeHandler = (filter) => {
    setActiveFilters({ filter });

    const fc = searchParams.get("fc");
    const fg = searchParams.get("fg");
    const fpts = searchParams.getAll("fpt");
    const s = searchParams.get("s");
    navigate(getParamsUrlForFilter(fc, fg, fpts, s, filter));
  };
  const options = Object.keys(PROXY_FILTERS).map((key) => ({
    value: PROXY_FILTERS[key],
    label: t(`proxyFilters.${PROXY_FILTERS[key]}`)
  }));

  const filter = searchParams.get("filter");
  const defaultFilter =
    route?.tag === "home" ? PROXY_FILTERS.reviews : PROXY_FILTERS.rating;
  const activeFilter =
    options.find((item) => item.value === filter)?.value || defaultFilter;

  return (
    <>
      <ReStyledDropdown
        label={<Icon name="filter" />}
        align={"end"}
        direction={"bottom"}
      >
        {options.map((item) => (
          <ReStyledDropdownItem
            key={item.value}
            onClick={() => routeHandler(item.value)}
            active={activeFilter === item.value}
          >
            {item.label}
          </ReStyledDropdownItem>
        ))}
      </ReStyledDropdown>

      <StyledSelectBox>
        <Select
          options={options}
          defaultValue={activeFilter}
          value={activeFilter}
          onChange={routeHandler}
        />
      </StyledSelectBox>
    </>
  );
});
