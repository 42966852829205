import styled from "@emotion/styled";
import {
  Swiper as BaseSwiper,
  SwiperSlide as BaseSwiperSlide
} from "swiper/react";

export const Swiper = styled(BaseSwiper)`
  position: relative;
  z-index: 0;
  list-style: none;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 0;

  &.swiper-vertical > .swiper-wrapper {
    flex-direction: column;
  }

  & .swiper-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: content-box;
    transition-property: transform;
  }

  & .swiper-pagination {
    position: absolute;
    z-index: 10;
    text-align: center;
    transform: translate3d(0, 0, 0);
    transition: 300ms opacity;

    line-height: 0%;
    bottom: 16px !important;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: #f3f4f6;
      &-active {
        background-color: #dadae1cc;
      }
    }

    &.swiper-pagination-hidden {
      opacity: 0;
    }
    .swiper-pagination-disabled > &,
    &.swiper-pagination-disabled {
      display: none !important;
    }
  }

  &.swiper-android .swiper-slide,
  & .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }

  & .swiper-pointer-events {
    touch-action: pan-y;
    &.swiper-vertical {
      touch-action: pan-x;
    }
  }

  & .swiper-slide-invisible-blank {
    visibility: hidden;
  }

  & .swiper-pagination-fraction,
  & .swiper-pagination-custom,
  &.swiper-horizontal > .swiper-pagination-bullets,
  & .swiper-pagination-bullets.swiper-pagination-horizontal {
    left: 0;
    bottom: 0px;
    width: 100%;
  }

  & .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #dadada;
    .swiper-pagination-clickable & {
      cursor: pointer;
    }

    &:only-child {
      display: none !important;
    }
  }

  &.swiper-vertical > .swiper-pagination-bullets,
  & .swiper-pagination-vertical.swiper-pagination-bullets {
    left: 20px;
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    .swiper-pagination-bullet {
      display: block;
      & + .swiper-pagination-bullet {
        margin-top: 8px;
      }
    }
    &.swiper-pagination-bullets-dynamic {
      top: 50%;
      width: 8px;
      transform: translateY(-50%);
      .swiper-pagination-bullet {
        display: inline-block;
        transition: 200ms transform, 200ms top;
      }
    }
  }

  &.swiper-horizontal > .swiper-pagination-bullets,
  & .swiper-pagination-horizontal.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      & + .swiper-pagination-bullet {
        margin-left: 8px;
      }
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }

  &.swiper-horizontal.swiper-rtl
    > .swiper-pagination-bullets-dynamic
    .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
  & .swiper-pagination-lock {
    display: none;
  }

  & .swiper-button-lock {
    display: none;
  }

  &.port-scanner-slider {
    padding-top: 24px;
    padding-left: 15px;
    padding-bottom: 48px;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      padding-left: 0;
      padding-top: 48px;
      padding-bottom: 0;
    }

    .swiper-button-prev {
      position: absolute;
      width: 24px;
      height: 24px;
      cursor: pointer;
      bottom: 0;
      background-image: url(./img/icons/arrow_alt.svg);
      transform: rotate(180deg);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;

      @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
        top: 0;
        right: 227px;
        bottom: auto;
      }
    }

    .swiper-button-next {
      position: absolute;
      width: 24px;
      height: 24px;
      cursor: pointer;
      bottom: 0;
      left: 62px;
      background-image: url(./img/icons/arrow_alt.svg);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: center;

      @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
        top: 0;
        right: 175px;
        bottom: auto;
        left: auto;
      }
    }

    .swiper-button-disabled {
      background-image: url(./img/icons/arrow_alt_disabled.svg) !important;
      pointer-events: none;
    }

    .swiper-button-next::after {
      display: none;
    }

    .swiper-button-prev::after {
      display: none;
    }
  }

  &.port-scanner-steps {
    .swiper-scrollbar {
      border-radius: 12px;
      background: #eeeff0;

      @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
        width: 327px !important;
      }
    }

    .swiper-scrollbar-drag {
      border-radius: 12px;
      background: ${(p) => p.theme.colors["main-500"]};
    }
  }
`;

export const SwiperSlide = styled(BaseSwiperSlide)`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  transition-property: transform;
`;
