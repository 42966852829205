import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../../../hooks";
import {
  StyledContentList,
  StyledContentListItem,
  StyledContentTitle,
  StyledOKIcon
} from "../../ExpandableComponent.styled";

export const Benefits = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  let advetages = [];
  if (data?.advantages?.[queryLang] && data?.advantages?.[queryLang].length) {
    advetages = data?.advantages?.[queryLang];
  }
  return advetages?.length > 0 ? (
    <div>
      <StyledContentTitle>
        {t("proxyPage.content.table.advantages")}
      </StyledContentTitle>

      <StyledContentList>
        {advetages
          .map((adv, i) => (
            <StyledContentListItem key={`benefit-${queryLang}-${i}`}>
              <StyledOKIcon />
              <span>{adv}</span>
            </StyledContentListItem>
          ))
          .filter(Boolean)}
      </StyledContentList>
    </div>
  ) : null;
};
