import styled from "@emotion/styled";

export const StyledItem = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 16px;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 5px 18px 0px rgba(135, 140, 189, 0.1);

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 24px;
  }

  &:not(:last-child) {
    margin-bottom: 24px;

    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-bottom: 0;
    }
  }
`;

export const StyledItemTitle = styled.p`
  display: block;
  height: 20px;
  color: ${(p) => p.theme.colors["neutral-500"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.71;
  margin-bottom: 4px;
`;

export const StyledImage = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-right: 16px;
`;

export const StyledValue = styled.p`
  min-height: 24px;
  color: ${(p) => p.theme.colors["neutral-800"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  white-space: wrap;
  word-break: break-word;
`;
