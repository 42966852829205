import { useEffect } from "react";

import { useSelector } from "react-redux";

import { useDispatchedActions } from "../../../hooks";
import { getAllSite } from "../../../store/reducers/SiteReducer/Site.selectors";
import { throttle } from "../../../utils/helpers";

import { BlogNavItem } from "./BlogNavItem/BlogNavItem";
import { StyledNavDesktop } from "./BurgerMenu.styled";
import { Overlay } from "./Overlay/Overlay";
import { ProxiesNavigation } from "./ProxiesNavigation/ProxiesNavigation";

export const ServiceMenu = () => {
  const { isServiceMenuOpened } = useSelector(getAllSite);

  // Dispatch
  const { setServiceMenu } = useDispatchedActions();

  useEffect(() => {
    const handleResize = throttle(() => {
      if (window.innerWidth < 993) {
        setServiceMenu(false);
      }
    }, 500);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setServiceMenu]);

  return (
    <Overlay isOpened={isServiceMenuOpened} setIsOpened={setServiceMenu}>
      <StyledNavDesktop>
        <ProxiesNavigation />
        <BlogNavItem dataTestId="header_serviceMenu_blog" />
      </StyledNavDesktop>
    </Overlay>
  );
};
