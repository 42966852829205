import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FormCheckbox } from "../../../../../../../components/forms/FormCheckbox/FormCheckbox";
import { FormInput } from "../../../../../../../components/forms/FormInput/FormInput";
import { FormSelect } from "../../../../../../../components/forms/FormSelect/FormSelect";
import { Icon } from "../../../../../../../components/ui/Icon/Icon";
import { Tooltip } from "../../../../../../../components/ui/Tooltip/Tooltip";
import { useLangUrlDefault } from "../../../../../../../hooks";
import { getAllContent } from "../../../../../../../store/reducers/ContentReducer/Content.selectors";
import { individualProxyTypes } from "../../../../../../../utils/constants/individualProxyTypes";
import { transformMinRent } from "../../../../../../../utils/helpers";
import { CardInfo } from "../../../../../components/CardInfo/CardInfo";
import { Countries } from "../Countries/Countries";

import {
  StyledCheckboxWrapper,
  StyledProxyTypeLabel,
  StyledRow
} from "./ProxyTypeInfo.styled";

export const ProxyTypeInfo = ({ data }) => {
  const { t } = useTranslation();
  const [queryLang] = useLangUrlDefault();
  const { rentPeriods } = useSelector(getAllContent);

  const { setValue } = useFormContext();
  const prefix = `proxy.${data.type}.`;

  const rents = transformMinRent(
    rentPeriods?.data,
    queryLang,
    t("times", { returnObjects: true })
  );

  const handleCloseCardClick = () => {
    setValue(`proxyTypes.${data.type}`, false);
  };

  return (
    <CardInfo title={data?.name} onClose={handleCloseCardClick}>
      {individualProxyTypes.includes(data.type) && (
        <StyledCheckboxWrapper>
          <FormCheckbox
            name={`${prefix}individual`}
            label={t("proxy.individual.label")}
            defaultValue={false}
            dataTestId={`addEditServiceForm_proxy.${data.type}.individual_checkbox`}
          />
          <Tooltip
            body={t("proxy.individual.tooltip")}
            style={{ display: "flex" }}
          >
            <img src="img/icons/info-solid.svg" alt="Information" />
          </Tooltip>
        </StyledCheckboxWrapper>
      )}
      <StyledRow>
        <FormInput
          name={`${prefix}link`}
          dataTestId={`addEditServiceForm_proxy.${data.type}.link`}
          label={"Link"}
          placeholder={t("forms.link.placeholder")}
          showError
        />
      </StyledRow>
      <StyledRow>
        <FormSelect
          name={`${prefix}minRent`}
          dataTestId={`addEditServiceForm_proxy.${data.type}.minRent`}
          label={t("forms.minRent.label")}
          options={rents}
          placeholder={t("forms.minRent.placeholder")}
          showError
        />
        <FormInput
          name={`${prefix}minPrice`}
          dataTestId={`addEditServiceForm_proxy.${data.type}.minPrice`}
          type="number"
          label={t("forms.minPrice.label")}
          placeholder={"$"}
          min={"0"}
          showError
        />
      </StyledRow>
      {data?.type !== "FreeProxy" && data?.type !== "ResidentialProxy" && (
        <StyledRow>
          <FormInput
            name={`${prefix}minIpAmount`}
            type={"number"}
            min={"0"}
            dataTestId={`addEditServiceForm_proxy.${data.type}.minIpAmount`}
            label={
              <StyledProxyTypeLabel>
                {t("forms.minIP.label")}
                <Tooltip body={t("forms.minIP.tooltip")}>
                  <Icon name="infoChat" />
                </Tooltip>
              </StyledProxyTypeLabel>
            }
            placeholder={t("forms.minIP.placeholder")}
            showError
          />
        </StyledRow>
      )}
      {data?.type === "ResidentialProxy" ? (
        <StyledRow>
          <FormInput
            name={`${prefix}pullIp`}
            dataTestId={`addEditServiceForm_proxy.${data.type}.pullIp`}
            label={t("forms.pullIP.label")}
            type={"number"}
            min={"0"}
            showError
          />
          <FormInput
            name={`${prefix}minGb`}
            dataTestId={`addEditServiceForm_proxy.${data.type}.minGb`}
            type={"number"}
            min={"0"}
            label={t("forms.minGB.label")}
            showError
          />
        </StyledRow>
      ) : (
        <StyledRow>
          <Countries prefix={prefix} type={data.type} />
        </StyledRow>
      )}
    </CardInfo>
  );
};
