export const nextYearValue = () => {
  const today = new Date();

  const nextYear = new Date(today);
  nextYear.setFullYear(today.getFullYear() + 1);

  return nextYear.toISOString().split("T")[0];
};

export const getMinPrice = (data = []) => {
  let minPrice = data[0]?.price;
  data?.forEach((item) => {
    if (item.price < minPrice) {
      minPrice = item.price;
    }
  });
  return minPrice || Math.random().toFixed(1);
};
