import styled from "@emotion/styled";

import { Modal } from "../../../../../../../components/ui/Modal/Modal";

export const StyledCountriesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const StyledCountryLabel = styled.span`
  color: ${(p) =>
    p.isError || !p.isActive
      ? p.theme.colors["neutral-400"]
      : p.theme.colors["main-700"]};
  width: 100%;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
`;
export const StyledCountriesButtonContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledCountriesDesctopContainer = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
`;
export const StyledCountriesButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 11px 15px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(p) => {
    if (p.isError) {
      return p.theme.colors["red-500"];
    }
    return p.isActive
      ? p.theme.colors["main-700"]
      : p.theme.colors["neutral-100"];
  }};
  background-color: ${(p) => {
    if (p.isError) {
      return "#fff5f54d";
    }
    return p.isActive ? "#f7ffe926" : p.theme.colors.white;
  }};
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    border: 1px solid
      ${(p) =>
        p.isError ? p.theme.colors["red-500"] : p.theme.colors["neutral-500"]};
    background-color: ${(p) =>
      p.isError ? "#fff5f54d" : p.theme.colors.white};
    transition: border-color 0.3s ease-in-out;
  }
  &:focus {
    outline: none;
    border: 1px solid
      ${(p) =>
        p.isError ? p.theme.colors["red-500"] : p.theme.colors["main-700"]};
    background-color: ${(p) => (p.isError ? "#fff5f54d" : "#f7ffe926")};
    transition: background-color 0.3s ease-in-out;
  }

  & svg[class^="indicator"] {
    width: 24px;
    height: 24px;
    margin-left: 16px;
    transform: rotate(${(p) => (p.isActive ? "180deg" : "0deg")});
    transition: transform 0.3s;
  }
`;

export const StyledCountriesModal = styled(Modal)`
  width: 100%;
  height: auto;

  left: 0;
  transform: none;
  bottom: 0;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(p) => p.theme.colors["neutral-300"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 150%;
`;
