import { FormError } from "../FormError/FormError";

import { FormFieldWrapperStyled } from "./FormFieldWrapper.styled";

export const FormFieldWrapper = ({
  label,
  className = "",
  showError,
  error,
  errorPath,
  htmlFor,
  children
}) => (
  <FormFieldWrapperStyled
    className={`form-field ${className}`}
    isError={!!error}
  >
    {label ? <label htmlFor={htmlFor}>{label}</label> : null}
    {children}
    {showError && !!error ? (
      <FormError message={error} errorId={errorPath} />
    ) : null}
  </FormFieldWrapperStyled>
);
