export const setAnonymityReducer = (state, action) => {
  state.anonymity.data = action.payload;
  state.anonymity.loading = false;
  state.anonymity.errors = null;
};

export const setToolsSettingsReducer = (state, action) => {
  state.settings.data = action.payload;
  state.settings.isLoaded = true;
  state.settings.errors = null;
};
