import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import {
  animationFreeProxyDifference,
  animationFreeProxyDownload,
  animationFreeProxyList
} from "../../../../assets/animations";
import { Rive } from "../../../../components/ui/Rive/Rive";
import { useSeoBlock } from "../../../../hooks";
import { parseSeoData } from "../../../../utils/helpers";
import { ProxyDifferenceTable } from "../DataTable/ProxyDifferenceTable/ProxyDifferenceTable";

import {
  AnimationContainer,
  ContentWrapper,
  Description,
  DottedList,
  DottedListItem,
  ListTitle,
  // MobileImage,
  // MobilePicture,
  RestyledContainer,
  RestyledTitle,
  SectionInfo,
  StepDescription,
  StepItem,
  StepTitle,
  TableBox,
  TableContainer
} from "./FreeProxyInfo.styled";

export const FreeProxyInfo = () => {
  const { t } = useTranslation();
  const { data } = useSeoBlock([
    "toolsFreeProxyHowToUse",
    "toolsFreeProxyDifference",
    "toolsFreeProxyHowDownload"
  ]);

  const steps =
    parseSeoData(data?.toolsFreeProxyHowDownload?.steps, "JSON") || [];

  const dottedList = useMemo(() => {
    const list = parseSeoData(data?.toolsFreeProxyHowToUse?.list, "JSON");
    if (!list || !list?.length) return null;
    return (
      <DottedList>
        {list.map((item, index) => (
          <DottedListItem key={index}>
            <div>
              <img src="img/icons/dot.svg" alt="Dot icon" />
            </div>
            {item}
          </DottedListItem>
        ))}
      </DottedList>
    );
  }, [data]);

  return (
    <>
      {data?.toolsFreeProxyHowToUse?.showOnFront && (
        <SectionInfo>
          <RestyledContainer>
            <AnimationContainer>
              <Rive
                src={animationFreeProxyList}
                autoPlay
                top={-136}
                right={-110}
                bottom={-136}
                left={-110}
              />
            </AnimationContainer>
            {/* <MobilePicture>
              <source
                srcSet="img/free-proxy/free-proxy-info-image-mobile.svg"
                media="(max-width: 992px)"
              />
              <MobileImage
                src="img/free-proxy/free-proxy-info-image-mobile.svg"
                alt={parseSeoData(data?.toolsFreeProxyHowToUse?.title)}
              />
            </MobilePicture> */}
            <ContentWrapper>
              <RestyledTitle tag="h2">
                {parseSeoData(data?.toolsFreeProxyHowToUse?.title)}
              </RestyledTitle>
              <Description>
                {parseSeoData(data?.toolsFreeProxyHowToUse?.description)}
              </Description>
              <ListTitle>
                {parseSeoData(data?.toolsFreeProxyHowToUse?.listTitle)}
              </ListTitle>
              {dottedList}
            </ContentWrapper>
          </RestyledContainer>
        </SectionInfo>
      )}
      {data?.toolsFreeProxyDifference?.showOnFront && (
        <SectionInfo variant={"light"}>
          <RestyledContainer variant="light">
            <ContentWrapper variant="light">
              <RestyledTitle tag="h2">
                {parseSeoData(data?.toolsFreeProxyDifference?.title)}
              </RestyledTitle>
              <Description>
                {parseSeoData(data?.toolsFreeProxyDifference?.description)}
              </Description>
            </ContentWrapper>
            <AnimationContainer variant="light">
              <Rive
                src={animationFreeProxyDifference}
                autoPlay
                top={-85}
                right={-120}
                bottom={-85}
                left={-120}
              />
            </AnimationContainer>
            {/* <MobilePicture>
              <source
                srcSet="img/free-proxy/free-proxy-info-image-mobile1.svg"
                media="(max-width: 992px)"
              />
              <MobileImage
                src="img/free-proxy/free-proxy-info-image-mobile1.svg"
                alt={t("freeProxy.differenceBetween.title")}
              />
            </MobilePicture> */}
          </RestyledContainer>
          <TableContainer>
            <TableBox>
              <ProxyDifferenceTable
                data={
                  parseSeoData(
                    data?.toolsFreeProxyDifference?.tableData,
                    "JSON"
                  ) || []
                }
              />
            </TableBox>
          </TableContainer>
        </SectionInfo>
      )}
      {data?.toolsFreeProxyHowDownload?.showOnFront && (
        <SectionInfo variant={"white"}>
          <RestyledContainer variant="white">
            <AnimationContainer variant="white">
              <Rive
                src={animationFreeProxyDownload}
                autoPlay
                top={-41}
                right={-69}
                bottom={-13}
                left={-69}
              />
            </AnimationContainer>
            {/* <MobilePicture>
              <source
                srcSet="img/free-proxy/free-proxy-info-image-mobile2.svg"
                media="(max-width: 992px)"
              />
              <MobileImage
                src="img/free-proxy/free-proxy-info-image-mobile2.svg"
                alt={t("freeProxy.howDownloadProxy.title")}
              />
            </MobilePicture> */}
            <ContentWrapper>
              <RestyledTitle tag="h2" variant="white">
                {parseSeoData(data?.toolsFreeProxyHowDownload?.title)}
              </RestyledTitle>
              {steps?.length > 0 &&
                steps.map((step, index) => (
                  <StepItem key={step}>
                    <StepTitle>{`${t("ui.step")} ${index + 1}`}</StepTitle>
                    <StepDescription>{step}</StepDescription>
                  </StepItem>
                ))}
            </ContentWrapper>
          </RestyledContainer>
        </SectionInfo>
      )}
    </>
  );
};
