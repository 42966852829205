import PT from "prop-types";

import { animationPortScannerStep } from "../../../../../../assets/animations";

import {
  StyledAnimation,
  StyledAnimationWrapper,
  StyledContent,
  StyledDescription,
  // StyledImageWrapper,
  StyledStep,
  StyledTitle,
  StyledWrapper
} from "./PortScannerStep.styled";

export const PortScannerStep = ({ title, description, index }) => (
  <StyledWrapper>
    <StyledContent>
      <StyledTitle tag="h2">{title}</StyledTitle>
      <StyledStep>{`Step ${index}`}</StyledStep>
      <StyledDescription>{description}</StyledDescription>
    </StyledContent>
    {index === 1 && (
      <StyledAnimationWrapper>
        <StyledAnimation
          src={animationPortScannerStep}
          autoPlay
          stateMachines="State Machine 1"
        />
      </StyledAnimationWrapper>
    )}
    {/* <StyledImageWrapper mobile={index === 1}>
      <img
        src={image}
        loading="lazy"
        alt={`${title} - ${index}`}
        aria-hidden="true"
      />
    </StyledImageWrapper> */}
  </StyledWrapper>
);

PortScannerStep.propTypes = {
  title: PT.string,
  description: PT.string,
  image: PT.node
};
