import { useTranslation } from "react-i18next";

import { YesNo } from "../../../../../components/common/YesNo/YesNo";
import { StyledSkeleton } from "../../../../../components/ui/Skeleton/components/SkeletonTableComponents.styled";

import { ProxyTypes } from "./ProxyTypes/ProxyTypes";
import { ServiceHead } from "./ServiceHead/ServiceHead";
import { StyledServiceCard } from "./ServiceItem.styled";
import { ShortInfo } from "./ShortInfo/ShortInfo";

export const ServiceItem = ({ data, loading = false }) => {
  const { t } = useTranslation();
  const list = [
    {
      title: t("proxySitePage.about.info.freeTest"),
      value: !loading ? (
        <YesNo dot={false} value={data?.freeTest || false} />
      ) : (
        <StyledSkeleton width={50} height={14} />
      )
    },
    {
      title: t("proxySitePage.about.info.refund"),
      value: !loading ? (
        <YesNo dot={false} value={data?.refunds || false} />
      ) : (
        <StyledSkeleton width={50} height={14} />
      )
    },
    {
      title: t("proxySitePage.info.informations.minPrice"),
      value: !loading ? (
        `$${data?.priceMin}`
      ) : (
        <StyledSkeleton width={50} height={14} />
      )
    }
  ];
  return (
    <StyledServiceCard>
      <ServiceHead data={data} loading={loading} />
      <ProxyTypes data={data?.proxyTypeProps || []} loading={loading} />
      <ShortInfo data={list} />
    </StyledServiceCard>
  );
};
