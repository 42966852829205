import {
  animationAnonymityChecker,
  animationAnonymityInfo
} from "../../../../assets/animations";
import { Rive } from "../../../../components/ui/Rive/Rive";
import { useSeoBlock } from "../../../../hooks";
import { parseSeoData } from "../../../../utils/helpers";

import {
  AnimationWrapper,
  ContentWrapper,
  Description,
  // MobileImage,
  // MobilePicture,
  MyAnonymitySection,
  RestyledContainer,
  RestyledTitle
} from "./MyAnonymityInfo.styled";

export const MyAnonymityInfo = () => {
  const { data } = useSeoBlock([
    "toolsAnonymityWhatIsIt",
    "toolsAnonymityWhatDoesIt"
  ]);
  return (
    <>
      {data?.toolsAnonymityWhatIsIt?.showOnFront && (
        <MyAnonymitySection variant="light">
          <RestyledContainer variant="light">
            <AnimationWrapper variant="light">
              <Rive
                src={animationAnonymityInfo}
                autoPlay
                top={-78}
                left={-80}
                bottom={-78}
                right={-80}
              />
            </AnimationWrapper>
            {/* <MobilePicture>
              <source srcSet="img/my-anonymity/my-anonymity-info-image-mobile.svg" />
              <MobileImage
                src="img/my-anonymity/my-anonymity-info-image-mobile.svg"
                alt={parseSeoData(data?.toolsAnonymityWhatIsIt?.title, "TEXT")}
              />
            </MobilePicture> */}
            <ContentWrapper variant="light">
              <RestyledTitle tag="h2">
                {parseSeoData(data?.toolsAnonymityWhatIsIt?.title)}
              </RestyledTitle>
              <Description>
                {parseSeoData(data?.toolsAnonymityWhatIsIt?.description)}
              </Description>
            </ContentWrapper>
          </RestyledContainer>
        </MyAnonymitySection>
      )}
      {data?.toolsAnonymityWhatDoesIt?.showOnFront && (
        <>
          <MyAnonymitySection>
            <RestyledContainer>
              <ContentWrapper>
                <RestyledTitle tag="h2">
                  {parseSeoData(data?.toolsAnonymityWhatDoesIt?.title)}
                </RestyledTitle>
                <Description>
                  {parseSeoData(data?.toolsAnonymityWhatDoesIt?.description)}
                </Description>
              </ContentWrapper>
              <AnimationWrapper>
                <Rive
                  src={animationAnonymityChecker}
                  autoPlay
                  top={-75}
                  right={-132}
                  bottom={-75}
                  left={-83}
                />
              </AnimationWrapper>
            </RestyledContainer>
            {/* <MobilePicture>
              <source srcSet="img/my-anonymity/my-anonymity-info-image-mobile1_v2.svg" />
              <MobileImage
                variant="full"
                src="img/my-anonymity/my-anonymity-info-image-mobile1_v2.svg"
                alt={parseSeoData(data?.toolsAnonymityWhatDoesIt?.title)}
              />
            </MobilePicture> */}
          </MyAnonymitySection>
        </>
      )}
    </>
  );
};
