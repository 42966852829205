import PT from "prop-types";
import { useTranslation } from "react-i18next";

import { useLangUrlDefault } from "../../../hooks";

import { Icon, StyledLink } from "./Logo.styled";

export const Logo = ({ className, color = "white", onClick, dataTestId }) => {
  const { t } = useTranslation();
  const [, hrefLang] = useLangUrlDefault();

  const handlerLogoClick = () => {
    document.body.classList.remove("lock-backdrop");
    document.body.style.paddingRight = "";
    document.body.style.top = "";
    document.body.style.overflow = "";
    onClick?.();
  };

  return (
    <StyledLink
      color={color}
      to={`${hrefLang}/`}
      className={`${className ? className : ""}`}
      title={t("header.logo.linkTitle")}
      onClick={handlerLogoClick}
      {...(dataTestId && { "data-testid": dataTestId })}
    >
      <Icon name="logo" />
    </StyledLink>
  );
};

Logo.propTypes = {
  className: PT.string,
  color: PT.oneOf(["black", "white"]),
  dataTestId: PT.string
};
