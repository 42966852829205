import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AuthButton } from "../../../components/ui/AuthButton/AuthButton";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { getAllSite } from "../../../store/reducers/SiteReducer/Site.selectors";
import { getAllTools } from "../../../store/reducers/ToolsReducer/Tools.selectors";
import { navigationHeader } from "../../../utils/constants";

import { BlogNavItem } from "./BlogNavItem/BlogNavItem";
import {
  StyledAddSiteButton,
  StyledButtonsWrapper,
  StyledLanguages,
  StyledNavDesktop,
  StyledNavMobile
} from "./BurgerMenu.styled";
import { Navigation } from "./Navigation/Navigation";
import { Overlay } from "./Overlay/Overlay";
import { ServicesNavigation } from "./ServicesNavigation/ServicesNavigation";

export const BurgerMenu = () => {
  const [queryLang, hrefLang] = useLangUrlDefault();
  const { isBurgerMenuOpened } = useSelector(getAllSite);
  const { settings } = useSelector(getAllTools);
  const {
    setBurgerMenu,
    setServiceMenu,
    setServicesMenuStep,
    setServicesOpened,
    setLocationOpened,
    setSelectedLocation,
    setSearchCountry
  } = useDispatchedActions();
  const { t } = useTranslation();

  // const isProd = process.env.REACT_APP_MODE === "prod";

  // const navigationList = isProd
  //   ? navigationHeader.filter((item) => item.title !== "tools")
  //   : navigationHeader;

  const navigationList =
    settings?.isLoaded && settings?.data?.length
      ? [
          {
            title: "tools",
            list: settings?.data?.map((item) => {
              const newItem = {};
              newItem.route = `tools/${item.url}`;
              newItem.title = item.localization?.[queryLang]?.title;
              newItem.description = item.localization?.[queryLang]?.description;
              if (item.isNew) {
                newItem.chip = "new";
              }

              return newItem;
            })
          },
          ...navigationHeader
        ]
      : navigationHeader;

  const closeMenu = () => {
    setBurgerMenu(false);
    setServiceMenu(false);
    setServicesMenuStep("locations");
    setLocationOpened(false);
    setServicesOpened(false);
    setSelectedLocation("");
    setSearchCountry("");
  };

  return (
    <Overlay isOpened={isBurgerMenuOpened} setIsOpened={setBurgerMenu}>
      <StyledNavMobile>
        <ServicesNavigation closeMenu={closeMenu} />
        <Navigation data={navigationList} mobile />
      </StyledNavMobile>
      <StyledNavDesktop>
        <Navigation data={navigationList} />
        <BlogNavItem dataTestId="header_burgerMenu_blog" />
      </StyledNavDesktop>
      <StyledButtonsWrapper>
        <StyledAddSiteButton
          variant="secondary"
          size="nm"
          iconLeft="plus"
          linkTo={`${hrefLang}/dashboard/services/create/`}
          onClick={closeMenu}
          dataTestId="header_mobile_addSiteButton"
        >
          {t("navigation.addsite")}
        </StyledAddSiteButton>
        <AuthButton dataTestId="header_mobile_authButton" />
        <StyledLanguages dataTestId="header_mobile_languages" />
      </StyledButtonsWrapper>
    </Overlay>
  );
};
