import styled from "@emotion/styled";

export const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  & > * {
    margin: 0 !important;
  }
`;

export const StyledProxyTypesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledProxyTypeLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledCheckboxWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 80px;
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    top: 24px;
    right: 100px;
  }
`;
