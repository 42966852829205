import * as yup from "yup";

import { EMAIL_REGEX, EMAIL_STAGE_REGEX } from "../constants";

export const ReviewSchemaWithAuth = (trns, t) =>
  yup.object().shape({
    name: yup.string().required(t(`${trns}.name.rules.required`)),
    email: yup
      .string()
      .trim()
      .required(t(`${trns}.email.rules.required`))
      .matches(
        process.env?.REACT_APP_MODE === "prod"
          ? EMAIL_REGEX
          : EMAIL_STAGE_REGEX,
        t(`${trns}.email.rules.email`)
      ),
    advantages: yup.string(),
    disadvantages: yup.string(),
    reviewBody: yup
      .string()
      .required(t(`${trns}.reviewBody.rules.required`))
      .test({
        name: "review-max",
        message: t(`${trns}.reviewBody.rules.max`),
        test: (value) => value?.length <= 100
      })
  });

export const ReviewSchema = (trns, t) =>
  yup.object().shape({
    advantages: yup.string(),
    disadvantages: yup.string(),
    reviewBody: yup
      .string()
      .required(t(`${trns}.reviewBody.rules.required`))
      .test({
        name: "review-max",
        message: t(`${trns}.reviewBody.rules.max`),
        test: (value) => value?.length <= 100
      })
  });
