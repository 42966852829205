import PT from "prop-types";

import { SwitchInput, SwitchLabel, SwitchWrapper } from "./Switch.styled";

export const Switch = ({
  id,
  checked,
  onChange,
  disabled = false,
  label,
  className,
  style,
  ...rest
}) => (
  <SwitchWrapper className={className} style={style}>
    <SwitchInput
      {...rest}
      id={id}
      type="checkbox"
      role="switch"
      aria-label="switch"
      aria-checked={checked}
      checked={checked}
      onChange={(event) => {
        onChange(event.target.checked);
        event.target.blur();
      }}
      disabled={disabled}
    />
    {label && <SwitchLabel htmlFor={id}>{label}</SwitchLabel>}
  </SwitchWrapper>
);

Switch.propTypes = {
  id: PT.string,
  checked: PT.bool,
  onChange: PT.func,
  disabled: PT.bool,
  defaultChecked: PT.bool,
  label: PT.oneOfType([PT.string, PT.node]),
  className: PT.string,
  style: PT.shape({})
};
