import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";

import { Icon } from "../Icon/Icon";

import {
  ToastifyBounceInDown,
  ToastifyBounceInLeft,
  ToastifyBounceInRight,
  ToastifyBounceInUp,
  ToastifyBounceOutDown,
  ToastifyBounceOutLeft,
  ToastifyBounceOutRight,
  ToastifyBounceOutUp,
  ToastifyFlipIn,
  ToastifyFlipOut,
  ToastifySlideInDown,
  ToastifySlideInLeft,
  ToastifySlideInRight,
  ToastifySlideInUp,
  ToastifySlideOutDown,
  ToastifySlideOutLeft,
  ToastifySlideOutRight,
  ToastifySlideOutUp,
  ToastifySpin,
  ToastifyTrackProgress,
  ToastifyZoomIn,
  ToastifyZoomOut
} from "./Toast.animations";

export const StyledIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

export const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translate3d(0, 0, 9999px);
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff;
  }
  &.Toastify__toast-container--top-left {
    top: 1em;
    left: 1em;
  }
  &.Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
  }
  &.Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 1em;
  }
  &.Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &.Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
  }

  @media only screen and (max-width: 480px) {
    &.Toastify__toast-container {
      width: 100vw;
      padding: 0;
      left: 0;
      margin: 0;
    }
    &.Toastify__toast-container--top-left,
    &.Toastify__toast-container--top-center,
    &.Toastify__toast-container--top-right {
      top: 0;
      transform: translateX(0);
    }
    &.Toastify__toast-container--bottom-left,
    &.Toastify__toast-container--bottom-center,
    &.Toastify__toast-container--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }
    &.Toastify__toast-container--rtl {
      right: 0;
      left: initial;
    }

    & .Toastify__toast {
      margin-bottom: 0;
      border-radius: 0;
    }
  }

  .Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: Lato, sans-serif;
    cursor: pointer;
    direction: ltr;

    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 7px 21px 0px rgba(135, 140, 189, 0.23);
    padding: 16px 24px 16px 20px;

    &.Toastify__toast--success {
      background-image: linear-gradient(
        90deg,
        ${(props) => props.theme.colors["main-700"]} 0 4px,
        #fff 4px 100%
      );

      & .Toastify__toast-icon {
        color: ${(props) => props.theme.colors["main-700"]};
      }
      & .Toastify__progress-bar {
        background-color: ${(props) => props.theme.colors["main-700"]};
      }
    }

    &.Toastify__toast--error {
      background-image: linear-gradient(
        90deg,
        ${(props) => props.theme.colors["red-500"]} 0 4px,
        #fff 4px 100%
      );

      & .Toastify__toast-icon {
        color: ${(props) => props.theme.colors["red-500"]};
      }
      & .Toastify__progress-bar {
        background-color: ${(props) => props.theme.colors["red-500"]};
      }
    }

    &.Toastify__toast--warning {
      background-image: linear-gradient(
        90deg,
        ${(props) => props.theme.colors.orange} 0 4px,
        #fff 4px 100%
      );

      & .Toastify__toast-icon {
        color: ${(props) => props.theme.colors.orange};
      }

      & .Toastify__progress-bar {
        background-color: ${(props) => props.theme.colors.orange};
      }
    }

    &.Toastify__toast--info {
      background-image: linear-gradient(
        90deg,
        ${(props) => props.theme.colors.blue} 0 4px,
        #fff 4px 100%
      );

      & .Toastify__toast-icon {
        color: ${(props) => props.theme.colors.blue};
      }
      & .Toastify__progress-bar {
        background-color: ${(props) => props.theme.colors.blue};
      }
    }
  }
  .Toastify__toast-body {
    display: flex;
    align-items: flex-start;
    padding: 0;
    color: #4c5464;
    font-family: Lato, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 139%;

    & .Toastify__toast-icon {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  & .Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  & .Toastify__close-button--light {
    color: #000;
    opacity: 0.3;
  }
  & .Toastify__close-button > svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
  }
  & .Toastify__close-button:hover,
  & .Toastify__close-button:focus {
    opacity: 1;
  }

  & .Toastify__spinner {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 2px solid;
    border-radius: 100%;
    border-color: #e0e0e0;
    border-right-color: #616161;
    animation: ${ToastifySpin} 0.65s linear infinite;
  }

  & .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 9999;
    opacity: 0.7;
    transform-origin: left;
    &[aria-hidden="true"] {
      background: transparent !important;
      opacity: 0;
      visibility: hidden;
    }
  }
  & .Toastify__progress-bar--animated {
    animation: ${ToastifyTrackProgress} linear 1 forwards;
  }
  & .Toastify__progress-bar--controlled {
    transition: transform 0.2s;
  }
  & .Toastify__progress-bar--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
  }

  & .Toastify--animate {
    animation-fill-mode: both;
    animation-duration: 0.7s;
  }

  & .Toastify--animate-icon {
    animation-fill-mode: both;
    animation-duration: 0.3s;
  }

  & .Toastify__bounce-enter--top-left,
  & .Toastify__bounce-enter--bottom-left {
    animation-name: ${ToastifyBounceInLeft};
  }
  & .Toastify__bounce-enter--top-right,
  & .Toastify__bounce-enter--bottom-right {
    animation-name: ${ToastifyBounceInRight};
  }
  & .Toastify__bounce-enter--top-center {
    animation-name: ${ToastifyBounceInDown};
  }
  & .Toastify__bounce-enter--bottom-center {
    animation-name: ${ToastifyBounceInUp};
  }

  & .Toastify__bounce-exit--top-left,
  & .Toastify__bounce-exit--bottom-left {
    animation-name: ${ToastifyBounceOutLeft};
  }
  & .Toastify__bounce-exit--top-right,
  & .Toastify__bounce-exit--bottom-right {
    animation-name: ${ToastifyBounceOutRight};
  }
  & .Toastify__bounce-exit--top-center {
    animation-name: ${ToastifyBounceOutUp};
  }
  & .Toastify__bounce-exit--bottom-center {
    animation-name: ${ToastifyBounceOutDown};
  }

  & .Toastify__slide-enter--top-left,
  & .Toastify__slide-enter--bottom-left {
    animation-name: ${ToastifySlideInLeft};
  }
  & .Toastify__slide-enter--top-right,
  & .Toastify__slide-enter--bottom-right {
    animation-name: ${ToastifySlideInRight};
  }
  & .Toastify__slide-enter--top-center {
    animation-name: ${ToastifySlideInDown};
  }
  & .Toastify__slide-enter--bottom-center {
    animation-name: ${ToastifySlideInUp};
  }

  & .Toastify__slide-exit--top-left,
  & .Toastify__slide-exit--bottom-left {
    animation-name: ${ToastifySlideOutLeft};
  }
  & .Toastify__slide-exit--top-right,
  & .Toastify__slide-exit--bottom-right {
    animation-name: ${ToastifySlideOutRight};
  }
  & .Toastify__slide-exit--top-center {
    animation-name: ${ToastifySlideOutUp};
  }
  & .Toastify__slide-exit--bottom-center {
    animation-name: ${ToastifySlideOutDown};
  }

  & .Toastify__zoom-enter {
    animation-name: ${ToastifyZoomIn};
  }

  & .Toastify__zoom-exit {
    animation-name: ${ToastifyZoomOut};
  }

  & .Toastify__flip-enter {
    animation-name: ${ToastifyFlipIn};
  }

  & .Toastify__flip-exit {
    animation-name: ${ToastifyFlipOut};
  }
`;
