import { renderToStaticMarkup } from "react-dom/server";

import { FAVICON_LINKS } from "../constants/favicon-links";
import { FONTS_LINK } from "../constants/font-links";

import { getRobotContent } from "./getRobotContent.helper";

export const generateMetaTags = (
  title,
  description,
  language = "en",
  asString = false
) => {
  const metaTags = [
    <html lang={language} key="html" />,
    <meta charset="utf-8" key="charset" />,
    <meta
      name="viewport"
      key="viewport"
      content="width=device-width, initial-scale=1"
    />,
    <base href="/" key="baseUrl" />,

    <title key="title">{title}</title>,

    <meta name="description" content={description} key="description" />,

    <meta itemProp="name" content={title} key="name" />,
    <meta itemProp="description" content={description} key="metaDescription" />,
    <meta name="robots" content={getRobotContent()} key="robots" />,

    <meta property="og:type" content="website" key="ogType" />,
    <meta property="og:title" content={title} key="ogTitle" />,
    <meta
      property="og:description"
      content={description}
      key="ogDescription"
    />,
    <meta
      name="twitter:card"
      content="summary_large_image"
      key="twitterCard"
    />,
    <meta name="twitter:title" content={title} key="twitterTitle" />,
    <meta
      name="twitter:description"
      content={description}
      key="twitterDescription"
    />,
    <meta
      name="msapplication-TileColor"
      content="#ffffff"
      key="msapplication"
    />,
    <meta name="theme-color" content="#ffffff" key="themeColor" />
  ];

  const faviconLinks = FAVICON_LINKS.map(({ rel, sizes, href }, i) => (
    <link rel={rel} sizes={sizes} href={href} key={`favicon-${i}`} />
  ));

  const fontLinks = FONTS_LINK.map(
    ({ rel, href, as, type, crossOrigin }, i) => (
      <link
        rel={rel}
        href={href}
        as={as}
        type={type}
        crossOrigin={crossOrigin}
        key={`font-${i}`}
      />
    )
  );

  const allMetaTags = [...metaTags, ...faviconLinks, ...fontLinks];

  if (asString) {
    return renderToStaticMarkup(<>{allMetaTags}</>);
  }

  return allMetaTags;
};
