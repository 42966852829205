import styled from "@emotion/styled";

import { Icon } from "../../../../../components/ui/Icon/Icon";

export const StyledBlockFeaturesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledBlockFeaturesItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
`;

export const FeaturesItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1 0 calc(50% - 8px);
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.m};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 130%;
`;

export const FeaturesItemLalelDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  color: ${(p) => p.theme.colors["neutral-400"]};
  font-size: ${(p) => p.theme.fontSizes.s};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 140%;
`;

export const FeaturesItemBox = styled.div`
  flex: 1 0 calc(50% - 8px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

export const FeaturesItemBoxChechbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  & > .form-field {
    min-width: fit-content;
    width: calc((100% - 16px) / 2);
  }
`;

export const FeaturesItemLabelIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;
