import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../components/common/Progress/Progress";
import { FormInput } from "../../../../components/forms/FormInput/FormInput";
import { useUserIp } from "../../../../hooks/useUserIp";
import { ApiService } from "../../../../services";
import { TraicingIpSchema } from "../../../../utils/validation/traicingIp.validation";

import {
  InfoMessage,
  InputBox,
  RestyledButton,
  RestyledContainer,
  RestyledFormSelect,
  RestyledTitle,
  ResultContent,
  ResultItem,
  ResultWrapper,
  StyledAdditionalText,
  StyledForm,
  TracingIpWrapper
} from "./TracingIp.styled";

export const TraicingIp = () => {
  const { t } = useTranslation();

  const userIp = useUserIp();

  const methods = useForm({
    resolver: yupResolver(
      TraicingIpSchema(t("tracingIp", { returnObjects: true }))
    )
  });

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const ipAddressOrDomain = data.ipAddress.includes("/")
      ? new URL(data.ipAddress)?.host
      : data.ipAddress;

    try {
      const response = await ApiService.getIpTraceroute(
        ipAddressOrDomain,
        data.type?.value === "yes"
      );
      if (response && response.status === 200) {
        setResult(response.data);
      }
      if (response && response.status !== 200) {
        toast.error(t("notifications.apiError"));
      }
    } catch (error) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RestyledContainer>
      <TracingIpWrapper>
        <RestyledTitle tag="h2">{t("tracingIp.main.title")}</RestyledTitle>
        <FormProvider {...methods}>
          <StyledForm onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <InputBox>
              <FormInput
                name="ipAddress"
                type="text"
                placeholder={t("tracingIp.form.enterIP")}
                label={t("tracingIp.form.inputLabel")}
                showError
                disabled={isLoading}
              />
              <StyledAdditionalText
                type="button"
                onClick={() => {
                  methods.setValue("ipAddress", userIp?.ipAddress);
                  methods.clearErrors();
                }}
              >
                {t("portScanner.form.pasteIP")}
              </StyledAdditionalText>
            </InputBox>
            <RestyledFormSelect
              name="type"
              id="type"
              placeholder={t("ui.yesNo.yes")}
              defaultValue="yes"
              label={t("tracingIp.form.selectLabel")}
              returnObject
              disabled={isLoading}
              options={[
                {
                  label: t("ui.yesNo.yes"),
                  value: "yes"
                },
                { label: t("ui.yesNo.no"), value: "no" }
              ]}
            />
            <RestyledButton
              type="submit"
              fullWidth
              loading={isLoading}
              {...(isLoading && { iconLeft: "loading" })}
            >
              {isLoading
                ? t("tracingIp.form.loading")
                : t("tracingIp.form.traceIP")}
            </RestyledButton>
          </StyledForm>
        </FormProvider>
        <InfoMessage>
          <img src="img/icons/information.svg" alt="Info icon" />
          <span>{t("tracingIp.form.infoMessage")}</span>
        </InfoMessage>
        <RestyledTitle tag="h2">{t("portScanner.checkResults")}</RestyledTitle>
        <ResultWrapper isLoading={isLoading}>
          {isLoading ? (
            <Progress speed={1} isLoading />
          ) : (
            <ResultContent>
              {result ? (
                <>
                  <ResultItem key="description">
                    {result?.description}
                  </ResultItem>
                  {result?.routList &&
                    result?.routList.map((el) => (
                      <ResultItem
                        key={`step-${el.step}`}
                      >{`${el.step} ${el.speed1} ${el.speed2} ${el.speed3} ${el.ipInfo} ${el.ip}`}</ResultItem>
                    ))}
                  {result?.countryAndIpMap &&
                    Object.entries(result?.countryAndIpMap).map(
                      ([key, value], index) => (
                        <ResultItem key={key}>
                          {index === 0 ? value : `${key}: ${value}`}
                        </ResultItem>
                      )
                    )}
                </>
              ) : null}
            </ResultContent>
          )}
        </ResultWrapper>
      </TracingIpWrapper>
    </RestyledContainer>
  );
};
