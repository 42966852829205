import PT from "prop-types";

export const SpriteSVG = ({ name, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...rest}>
    <use href={`/img/svg-sprite_v2.svg#${name}`}></use>
  </svg>
);

SpriteSVG.propTypes = {
  name: PT.string.isRequired
};
