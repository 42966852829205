import { useEffect, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
// import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Progress } from "../../../../components/common/Progress/Progress";
import { FormInput } from "../../../../components/forms/FormInput/FormInput";
import { useUserIp } from "../../../../hooks/useUserIp";
import { ApiService } from "../../../../services";
import {
  headersObjectArrayHelpers,
  removeHttp
} from "../../../../utils/helpers";
import { HttpHeadersCheckSchema } from "../../../../utils/validation/httpHeadersCheck.validation.js";

import {
  RestyledLoading,
  StyledAdditionalText,
  StyledAnalyzingWrapper,
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledFormWrapper,
  StyledImage,
  StyledImageWrapper,
  StyledInfoMessage,
  StyledInnerWrapper,
  StyledInput,
  StyledList,
  StyledListWrapper,
  StyledProgressWrapper,
  StyledResults,
  StyledTitle
} from "./HttpHeaderCheck.styled";
import { HttpHeaderCheckItem } from "./HttpHeaderCheckItem/HttpHeaderCheckItem.jsx";
import { ResultsTable } from "./ResultsTable/ResultsTable.jsx";

export const HttpHeaderCheck = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [url, setUrl] = useState("");
  // const [ipAddress, setIpAddress] = useState("");

  const userIp = useUserIp();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      // const { headers } = await axios.get(process.env.REACT_APP_URL);
      const res = await ApiService.httpHeadersCheck(process.env.REACT_APP_URL);

      if (res.status === 200) {
        const newHeaders = headersObjectArrayHelpers(res.data);
        setResult(newHeaders);

        const siteUrl = removeHttp(process.env.REACT_APP_URL);

        setUrl(siteUrl);
      }
      setIsLoading(false);
    })();
  }, []);

  const { t } = useTranslation();

  const defaultValues = { country: "" };
  const methods = useForm(
    {
      resolver: yupResolver(
        HttpHeadersCheckSchema(t("httpHeadersChecker", { returnObjects: true }))
      )
    },
    defaultValues
  );

  // eslint-disable-next-line no-unused-vars
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const res = await ApiService.httpHeadersCheck(data.url);
      setUrl(data.url);
      if (res.status === 200) {
        const newHeaders = headersObjectArrayHelpers(res.data);
        setResult(newHeaders);
      } else {
        throw new Error("Error");
      }
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setIsLoading(false);
    }
  };

  const checkList = useMemo(
    () => [
      {
        title: t("httpHeadersChecker.site"),
        image: "img/ui/site.svg",
        value: url
      },
      {
        title: t("httpHeadersChecker.ipAddress"),
        image: "img/ui/ip.svg",
        value: userIp?.ipAddress || ""
      }
    ],
    [userIp?.ipAddress, t, url]
  );

  const analyzingWrapper = (variant) => (
    <StyledAnalyzingWrapper variant={variant}>
      <StyledTitle tag="h2">{t("dnsLeak.analyzing")}</StyledTitle>
      <StyledAdditionalText>
        <RestyledLoading name="loading" />
        <span>{t("dnsLeak.pleaseWait")}</span>
      </StyledAdditionalText>
      <StyledProgressWrapper>
        <Progress isLoading />
      </StyledProgressWrapper>
    </StyledAnalyzingWrapper>
  );

  return (
    <StyledContainer>
      <StyledInnerWrapper>
        <StyledFormWrapper>
          <StyledForm>
            <StyledImageWrapper>
              <picture>
                <source
                  srcSet="/img/http-headers-check/http-header-check-image.svg"
                  media="(min-width: 993px)"
                />
                <source
                  srcSet="/img/http-headers-check/http-header-check-image-mobile.svg"
                  media="(max-width: 992px)"
                />
                <StyledImage
                  src="/img/http-headers-check/http-header-check-image.svg"
                  alt={t("httpHeadersChecker.httpHeadersCheck")}
                />
              </picture>
            </StyledImageWrapper>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <StyledTitle tag="h2">
                  {t("httpHeadersChecker.httpHeadersCheck")}
                </StyledTitle>
                <StyledInput>
                  <FormInput
                    name="url"
                    type="text"
                    placeholder={t("httpHeadersChecker.form.enterWebsite")}
                    label={t("httpHeadersChecker.form.enterWebsite")}
                    showError
                  />
                </StyledInput>
                <StyledButton
                  type="submit"
                  loading={isLoading}
                  {...(isLoading && { iconLeft: "loading" })}
                  fullWidth
                >
                  {isLoading
                    ? t("portScanner.form.loading")
                    : t("httpHeadersChecker.form.button")}
                </StyledButton>
              </form>
            </FormProvider>
            <StyledInfoMessage>
              <img src="img/icons/information.svg" alt="Info icon" />
              <span>{t("httpHeadersChecker.form.info")}</span>
            </StyledInfoMessage>
          </StyledForm>
          {isLoading && analyzingWrapper("mobile")}
        </StyledFormWrapper>
        <StyledResults isLoading={isLoading}>
          {isLoading
            ? analyzingWrapper("desktop")
            : result && (
                <StyledListWrapper>
                  <StyledList>
                    {checkList.map((item) => (
                      <HttpHeaderCheckItem key={item.title} {...item} />
                    ))}
                  </StyledList>
                  <ResultsTable data={result} isLoading={isLoading} />
                </StyledListWrapper>
              )}
        </StyledResults>
      </StyledInnerWrapper>
    </StyledContainer>
  );
};
