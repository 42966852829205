export const setPromocodesPrevParamsReducer = (state, action) => {
  state.promocodes.prevParams = action.payload;
};

export const setAllPromocodesReducer = (state, action) => {
  state.promocodes.data = action.payload;
};

export const setAlternatePromocodesReducer = (state, action) => {
  state.alternatePromocodes.data = action.payload;
  state.alternatePromocodes.isDataLoaded = true;
};

export const setPromocodeReducer = (state, action) => {
  state.promocode.data = action.payload;
};

export const setSpecialPromocodesReducer = (state, action) => {
  state.special.data = action.payload.data;
  state.special.type = action.payload.type;
  state.special.isDataLoaded = true;
};
