import PT from "prop-types";

import { Switch } from "../../../ui/Switch/Switch";

export const IsActiveCell = ({
  isActive = false,
  promoId,
  onChange,
  disabled
}) => (
  <Switch
    checked={isActive}
    onChange={() => onChange(promoId)}
    disabled={disabled}
  />
);

IsActiveCell.propTypes = {
  isActive: PT.bool,
  promoId: PT.string,
  onChange: PT.func,
  disabled: PT.bool
};
