/* eslint-disable no-console */
import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { getAllContent } from "../store/reducers/ContentReducer/Content.selectors";

import { useDispatchedActions } from "./useDispatchedActions";
import { useLangUrlDefault } from "./useLangUrlDefault";
import { useMatchedRoute } from "./useMatchedRoute";

export const useMetaData = () => {
  // **Props
  const { hash, pathname } = useLocation();
  const domLocation = useLocation();
  const { alias } = useParams();
  const [queryLang] = useLangUrlDefault();
  const route = useMatchedRoute();
  const [searchParams] = useSearchParams();
  const { getPageContent, setCurrentPageContent } = useDispatchedActions();

  const { pageContent } = useSelector(getAllContent);

  const [, lastUrlParam] = pathname.split("/").reverse();

  let paramTab = searchParams.get("tab");

  if (!paramTab) {
    paramTab =
      lastUrlParam === "promocodes" ? "promocodes" : hash.replace("#", "");
  }

  // Getting page content from the server
  useEffect(() => {
    if (!route || !route?.tag) {
      return;
    }
    const routeCopy = { ...route };

    let params = {};

    if (Object.keys(Object.fromEntries(searchParams))?.length > 0) {
      const allowedKeys = ["fc", "fg", "fpt"];
      const filterKeys = Object.keys(Object.fromEntries(searchParams));

      if (filterKeys.some((filterKey) => allowedKeys.includes(filterKey))) {
        params = { ...Object.fromEntries(searchParams) };
      }
    }

    if (routeCopy.tag === "bloginner") {
      params = { ...params, articleId: alias };
    }

    if (
      routeCopy.tag === "proxy-site" ||
      routeCopy.tag === "proxy-site-promo"
    ) {
      params = { ...params, siteId: alias };
    }

    let location = window.location.href.replace(window.location.origin, "");

    if (!/proxy-{/.test(routeCopy.tag)) {
      location = location.replace(window.location.search, "");
    }

    let innerTag = `${domLocation.pathname}${domLocation?.search || ""}`;

    if (routeCopy?.tag === "login") {
      innerTag = `${domLocation.pathname}`;
    }

    let fetchParams = {
      tag: routeCopy.tag,
      languageCode: queryLang,
      innerTag,
      params,
      location
    };
    if (routeCopy.tag === "proxy") {
      let newParams = params;
      if (Object.keys(params)?.length === 0) {
        newParams = { fpt: "IPv4" };
      }

      fetchParams = {
        tag: routeCopy.tag,
        languageCode: queryLang,
        innerTag,
        params: newParams,
        location: `${domLocation.pathname}${domLocation?.search || ""}`
      };
    } else if (
      routeCopy.tag === "proxy-site" ||
      routeCopy.tag === "proxy-site-promo"
    ) {
      const pathname = location.split("/").reverse();

      fetchParams = {
        tag: routeCopy.tag,
        languageCode: queryLang,
        innerTag,
        params: { ...params },
        location: location.replace(window.location.hash, ""),
        pathname: `/${pathname[2]}/${pathname[1]}/`
      };
    }

    if (pageContent?.data?.[innerTag]) {
      return;
    }

    const promise = getPageContent(fetchParams);
    return () => promise?.abort();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pathname]);

  useEffect(() => {
    let innerTag = `${domLocation.pathname}${domLocation?.search || ""}`;
    if (route?.tag === "login") {
      innerTag = `${domLocation.pathname}`;
    }
    if (
      !!pageContent?.data?.[innerTag] &&
      !pageContent?.isLoading &&
      pageContent?.current?.tag !== innerTag
    ) {
      setCurrentPageContent({
        data: pageContent?.data?.[innerTag],
        innerTag
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageContent.isLoading,
    pageContent.data,
    domLocation.pathname,
    domLocation.search,
    domLocation.hash
  ]);

  // return metaData;
};
