export default {
  header: {
    logo: {
      imgAlt: "Rating Proxy - Независимый рейтинг прокси серверов",
      linkTitle: "Перейти на главную страницу"
    },
    burger: {
      ariaOpen: "Открыть меню",
      ariaClose: "Закрыть меню"
    },
    cabinet: {
      enter: "Вход",
      leave: "Выйти",
      cabinet: "Вход"
    },

    bottom: {
      geo: {
        title: "Прокси по гео"
      },
      for: {
        title: "Прокси для"
      },
      vpn: {
        title: "ВПН",
        countries: {
          title: "ПО СТРАНАМ"
        },
        devices: {
          title: "ДЛЯ УСТРОЙСТВА"
        },
        uses: {
          title: "ДЛЯ ИСПОЛЬЗОВАНИЯ"
        }
      },
      type: {
        title: "ТИП ПРОКСИ",
        ipv4: "Прокси IPv4",
        ipv6: "Прокси IPv6",
        mobile: "Мобильные прокси",
        free: "Бесплатные прокси"
      },
      accounts: {
        title: "Аккаунты",
        countries: {
          title: "ПО СТРАНАМ"
        },
        devices: {
          title: "ДЛЯ УСТРОЙСТВА"
        },
        uses: {
          title: "ДЛЯ ИСПОЛЬЗОВАНИЯ"
        }
      }
    }
  },
  footer: {
    logo: {
      imgAlt: "Rating Proxy - Независимый рейтинг прокси серверов",
      linkTitle: "Перейти на главную страницу",
      description:
        "Выбирая, где купить персональные прокси для парсинга, арбитража или обхода блокировок"
    },
    form: {
      title: "Подписка",
      inputPlaceholder: "Введите почту",
      btn: "Отправить",
      langTitle: "Язык:"
    }
  },
  table: {
    cell: {
      period: {
        eternal: "∞ Бессрочно"
      }
    }
  },
  pagination: {
    table: {
      from: "из",
      rowsCount: "строк/на странице"
    }
  },
  metaPage: {
    title: "TrustyTech - Независимый рейтинг прокси серверов",
    description:
      "Более 100 прокси сервисов которые обеспечат стабильную работу в социальных сетях, SEO и маркетинге, ставках и онлайн играх."
  },
  recentReviews: { title: "Последние отзывы", all: "Все отзывы" },
  mainPage: {
    proxy: {
      titles: {
        IPv4: "Рейтинг IPv4 прокси серверов",
        MobileProxy: "Рейтинг мобильных прокси серверов",
        ResidentialProxy: "Рейтинг резидентных прокси серверов",
        ISP: "Рейтинг ISP прокси серверов",
        IPv6: "Рейтинг IPv6 прокси серверов",
        FreeProxy: "Рейтинг бесплатных прокси серверов",
        SharedProxy: "Рейтинг общих прокси серверов",
        RemoteServer: "Рейтинг удаленных серверов"
      },
      description:
        "Более 1368 прокси серверов в нашем рейтинге. Найдите компании, которым вы можете доверять"
    },
    userChoice: {
      title: "Выбор пользователей",
      columns: {
        name: "Название",
        price: "Цена",
        offers: "Предложения",
        site: "Сайт",
        new: "Новый"
      }
    },
    determineIp: {
      yourIp: "Ваш IP",
      notCountry: "Страна не определена",
      changeLocat: "Изменить местоположение",
      changeBtn: "Изменить"
    },
    title: "Независимый рейтинг прокси серверов",
    subtitle: {
      startLine: "Прокси сервисы для",
      colorLine: "любой",
      endLine: "вашей задачи"
    },
    highlightWord: "рейтинг",
    description:
      "Более 100 прокси сервисов которые обеспечат стабильную работу в социальных сетях, SEO и маркетинге, ставках и онлайн играх."
  },
  blogPage: {
    breadcrumbs: [
      {
        title: "Блог"
      }
    ],
    title: "Блог",
    tabs: [
      {
        name: "all",
        title: "Все"
      },
      {
        name: "articles",
        title: "Статьи"
      },
      {
        name: "news",
        title: "Новости"
      },
      {
        name: "informational",
        title: "Информационные"
      },
      {
        name: "browser",
        title: "Браузер"
      },
      {
        name: "proxyIPv4",
        title: "Прокси IPv4"
      },
      {
        name: "social media",
        title: "Социальные медиа"
      },
      {
        name: "integrations",
        title: "Интеграции"
      },
      {
        name: "proxies",
        title: "Прокси"
      }
    ],
    tabsTitle: "Категории:",
    emptyArticles: "Статей нет",
    emptyNews: "Новостей нет",
    item: {
      categoryTitle: "Категория",
      categories: {
        article: "Статья",
        news: "Новость"
      },
      commentsTitle: "Коментарии",
      readMore: "Читать далее",
      popular: "Популярная статья"
    },
    latest: "Последние",
    search: "Поиск"
  },
  blogInnerPage: {
    breadcrumbs: [
      {
        route: "blog/?tab=articles",
        title: "Блог"
      }
    ],
    articleBody: {
      commentsTitle: "Комментарии",
      viewsTitle: "просмотров",
      shareTitle: "Была полезная статья? Расскажите о ней своим знакомым",
      shareBtns: {
        vk: "Поделиться в Вконтакте",
        twitter: "Поделиться в Твитер",
        telegram: "Поделиться в Телеграм",
        linkedin: "Поделиться в Линкедын",
        facebook: "Поделиться в Фейсбук"
      },
      inThisArticle: "В этой статье:",
      relatedTitle: "Похожие статьи",
      prev: "Предыдущая",
      next: "Следующая"
    },
    comments: {
      noData: "Нет комментариев"
    },
    sidebar: {
      latest: {
        title: "ПОСЛЕДНИЕ ДОБАВЛЕННЫЕ СТАТЬИ"
      },
      popular: {
        title: "Популярные статьи"
      },
      more: "Читать далее"
    }
  },
  addsitePage: {
    title: "Добавить сайт",
    breadcrumbs: [
      {
        title: "Добавить сайт"
      }
    ],
    tabs: [
      {
        name: "proxy",
        title: "Прокси сервис"
      }
    ],
    tabsTitle: "Категории:"
  },
  proxyPage: {
    proxy: {
      titles: {
        IPv4: "Выберите подключение к прокси-сервису IPv4",
        MobileProxy: "Выберите подключение к мобильному прокси-сервису",
        ResidentialProxy: "Выберите подключение к резидентному прокси-сервису",
        ISP: "Выберите подключение к прокси-сервису ISP",
        IPv6: "Выберите подключение к прокси-сервису IPv6",
        FreeProxy: "Выберите подключение к бесплатному прокси-сервису",
        SharedProxy: "Выберите подключение к общему прокси-сервису",
        RemoteServer: "Выберите подключение к удаленному серверу"
      },
      description:
        "Более 1368 прокси серверов в нашем рейтинге. Найдите компании, которым вы можете доверять"
    },
    sidebar: {
      promocodes: {
        title: "Промокоды"
      },
      left: {
        countries: {
          title: "Прокси по ГЕО"
        },
        goals: {
          title: "ЦЕЛИ ИСПОЛЬЗОВАНИЯ",
          items: {
            socialNetwork: "Для соц. сетей",
            onlineGames: "Онлайн игры",
            searchEngineParsing: "Парсинг поисковиков",
            other: "Другое"
          }
        }
      },
      right: {
        comparison: {
          title: "СРАВНЕНИЕ",
          compareAction: "Сравнить",
          clearAction: "Очистить"
        },
        sellers: {
          title: "Топ продавцов",
          promocode: "Промокод для вас",
          goBtn: "Перейти"
        },
        reviews: {
          title: "ПОСЛЕДНИЕ ОТЗЫВЫ"
        }
      }
    },
    specialOffer: {
      title: "Специальные предложения",
      description:
        "Более 100 прокси сервисов которые обеспечат стабильную работу в социальных сетях, SEO и маркетинге, ставках и онлайн играх."
    },
    content: {
      top: {
        proxy: "Запрос на прокси",
        title: {
          main: "Рейтинг прокси сервисов",
          divider: ""
        }
      },
      filter: {
        title: "Прокси тип:",
        rating: "Рейтинг",
        review: "Отзывы",
        cost: "Стоимость"
      },
      sort: {
        title: "Сортировка по"
      },
      table: {
        title: {
          services: "сервисов"
        },
        index: {
          top: "ТОП"
        },
        name: {
          name: "Название",
          domainYear: "Регистрация домена",
          domainYearEnding: "г.",
          countriesQuantity: "К-во стран в продаже",
          promocode: "Промокод"
        },
        rating: {
          name: "Рейтинг",
          notRated: "Без рейтинга",
          halfStar: "Половина звезды",
          oneStar: "Одна звезда",
          oneHalfStars: "Одна с половиной звезда",
          twoStars: "Две звезды",
          twoHalfStars: "Две с половиной звезды",
          threeStars: "Три звезды",
          threeHalfStars: "Три с половиной звезды",
          fourStars: "Четыре звезды",
          fourHalfStars: "Четыре с половиной звезды",
          fiveStars: "Пять звезд",
          stars: "звезды"
        },
        description: {
          title: "Описание",
          noDescription: "Нет описания"
        },
        advantages: "Преимущества",
        type: {
          title: "Тип",
          noContent: "Нет типов"
        },
        freeTest: {
          title: "Бесплатный тест",
          yes: "Да",
          no: "Нет"
        },
        characteristics: {
          title: "Характеристики",
          autorization: {
            title: "Авторизация",
            login: "Логин+пароль",
            ip: "По основному IP",
            noAuth: "Без авторизации"
          }
        },
        geo: {
          title: "ГЕО",
          more: "ещё",
          noContent: "Нет стран",
          modal: {
            title: "Все страны",
            search: "Поиск",
            subtitles: {
              other: "Другие страны"
            }
          }
        },
        minPrice: {
          title: "Мин. цена"
        },
        minRent: {
          title: "Мин. аренда"
        },
        pp: {
          title: "ПП",
          noData: "-"
        },
        api: {
          title: "API",
          newTitle: "Бесплатный тест",
          yes: "Да",
          no: "Нет"
        },
        site: {
          title: "Сайт",
          btn: "Перейти",
          readOverview: "Читать обзор"
        },
        compare: {
          title: "Сравнить",
          ariaLabel: "Добавить в сравнение"
        },
        top: "Топ сервис",
        noDataComponent: "Нет данных"
      },
      modal: {
        title:
          "Выберите необходимые данные и мы разошлем запрос всем подходящим по категориям компаниям нашего каталога",
        quantity: {
          placeholder: "Количество"
        },
        btn: "Отправить"
      }
    }
  },
  proxySitePage: {
    info: {
      tabs: {
        info: "Информация",
        social: "Соц. сети",
        contacts: "Контакты"
      },
      base: {
        domain: "Домен",
        rating: "Рейтинг",
        reviews: "Отзывы",
        reviewCount: "отзывов",
        reviewCountTwo: "отзыва",
        reviewCountOne: "отзыв"
      },
      informations: {
        title: "Услуги",
        minRent: "Мин. аренда",
        minPrice: "Мин. цена",
        pullIP: "Количество IP",
        million: "млн",
        thousand: "тыс",
        minGB: "Мин. ГБ",
        minIP: "Мин. IP",
        individual: "Индивидуальные"
      },
      socialMedia: {
        title: "Социальные сети"
      },
      contacts: {
        title: "Контакты"
      }
    },
    about: {
      title: "О сервисе",
      visit: "Перейти на сайт",
      readReview: "Читать обзор",
      info: {
        title: "Информация",
        countries: "Страны",
        allLocations: "Все местоположения",
        speed: "Скорость",
        ping: "Пинг",
        auth: "Авторизация",
        api: "API",
        reviewTitle: "Последние отзывы",
        allReviews: "Все отзывы",
        affiliate: "Партнерская программа",
        pingTooltip: "Примерные показатели пинга, которые гарантирует сервис",
        freeTest: "Бесплатный тест",
        refund: "Возврат средств",
        speedTooltip:
          "Примерные показатели скорости, которые гарантирует сервис"
      },
      reviews: {
        title: "Оставить отзыв",
        btn: "Добавить отзыв",
        basedOn: "на основе"
      },
      tabs: {
        info: "Информация",
        reviews: "Отзывы",
        rating: "График рейтинга",
        promocodes: "Промокоды"
      },
      rating: {
        title: "График рейтинга",
        color: "Рейтинг"
      },
      promocodes: {
        title: "Промокоды",
        subscribe: "Подписаться на промокоды"
      }
    },
    content: {
      top: {
        btn: "Перейти на сайт"
      },
      tabs: [
        {
          title: "Характеристики",
          name: "characteristics"
        },
        {
          title: "Отзывы",
          name: "reviews"
        },
        {
          title: "График рейтинга",
          name: "rating"
        },
        {
          title: "Промокоды",
          name: "promocodes"
        },
        {
          title: "Контакты",
          name: "contacts"
        }
      ],
      contacts: {
        noData: "Нет контактов"
      },
      chacracteristics: {
        market: {
          title: "На рынке"
        },
        rating: {
          title: "Рейтинг"
        },
        countries: {
          title: "Страны"
        },
        freeTest: {
          title: "Бесплатный тест"
        },
        individual: {
          title: "Индивидуальные"
        },
        change: {
          title: "Возможность замены"
        },
        type: {
          title: "Тип"
        },
        minCost: {
          title: "Минимальная стоимость"
        },
        minRent: {
          title: "Минимальный срок аренды"
        },
        reviews: {
          title: "Отзывы"
        },
        goals: {
          title: "Цели использования"
        },
        auth: {
          title: "Авторизация",
          login: "Логин+пароль",
          ip: "По основному IP",
          noAuth: "Без авторизации"
        },
        return: {
          title: "Возврат средств"
        },
        partnership: {
          title: "Партнёрская программа"
        },
        partnershipPercent: {
          title: "Процент по партнерской программе"
        },
        services: {
          title: "Проверьте лучшие альтернативные сервисы"
        },
        description: {
          title: "Описание",
          noDescription: "Нет описания"
        },
        alternative: {
          title: "Альтернативные сервисы"
        }
      },
      rating: {
        labels: [
          "янв",
          "фев",
          "мар",
          "апр",
          "май",
          "июн",
          "июл",
          "авг",
          "сен",
          "окт",
          "ноя",
          "дек"
        ],
        label: "Рейтинг",
        filters: {
          month: "За месяц",
          general: "Общий"
        }
      },
      reviews: {
        top: {
          title: "Сортировать по",
          sortList: [
            {
              label: "Полезности",
              param: "useful"
            },
            {
              label: "Дате",
              param: "creationDate"
            },
            {
              label: "Оценке",
              param: "rating"
            }
          ]
        },
        noData: "Отзывов нет"
      },
      complaints: {
        top: {
          title: "Сортировать по",
          sortList: [
            {
              label: "дате",
              param: "creationDate"
            }
          ]
        },
        noData: "Жалоб нет"
      },
      promocodes: {
        title: "Промокод",
        btn: "Показать промокод",
        noData: {
          titleStart: "Услуга активных промокодов",
          titleEnd: "еще не добавлена",
          description:
            "Если вы являетесь владельцем сервиса, пожалуйста, авторизуйтесь и создайте промокод",
          titleAlternative: "Промокоды альтернативных сервисов"
        },
        noDescription: "Нет описания"
      }
    },
    notFound: "Сайт не найден"
  },
  comparisonPage: {
    title: "Таблица сравнения",
    table: {
      name: "Имя",
      rating: "Рейтинг",
      link: "Ссылка на сайт",
      freeTest: "Бесплатный тест",
      individual: "Индивидуальные",
      replacement: "Возможность замены",
      countries: "Страны",
      type: "Тип",
      purposes: "Цели использования",
      minCost: "Минимальная стоимость",
      minRent: "Минимальный срок аренды",
      auth: "Авторизация",
      refunds: "Возврат средств",
      partnership: "Партнёрская программа",
      reviews: "Отзывы",
      remove: "Удалить"
    },
    noData: "Сравнения больше не существует!",
    returnText: "Вернуться на главную"
  },
  authPages: {
    navigate: {
      toLogin: {
        text: "Уже есть аккаунт?",
        btn: "Войти"
      },
      toRegister: {
        text: "Нет аккаунта?",
        btn: "Зарегистрироваться"
      }
    },
    agree: {
      with: "Я согласен с",
      offer: "офертой",
      privacy: "политикой конфиденциальности",
      and: "и"
    },
    signIn: {
      title: "Авторизация",
      btn: "Войти",
      remember: "Запомнить меня",
      forgot: "Забыли пароль"
    },
    resetPassword: {
      title: "Сброс пароля",
      subtitle: "Мы отправим вам инструкции по сбросу пароля.",
      success: "Ссылка для сброса пароля отправлена на вашу почту",
      error:
        "Пользователь с таким email не найден. Попробуйте ввести другой email или проверьте правильность введенного email.",
      btn: "Отправить ссылку"
    },
    resetPasswordPage: {
      title: "Установить новый пароль",
      subtitle: "Введите новый пароль",
      btn: "Установить новый пароль",
      error: "Ссылка для сброса пароля недействительна или истек срок действия"
    },
    auth: {
      title: "Авторизация",
      btn: "Войти"
    },
    register: {
      title: "Регистрация",
      btn: "Зарегистрироваться"
    },
    registerSuccess: {
      title: "Спасибо за регистрацию!",
      text: "Мы отправили вам письмо для подтверждения.",
      spam: "Если вы не получили письмо, проверьте папку «Спам».",

      btn: "Перейти в кабинет"
    },
    recovery: {
      title: "Востановление аккаунта",
      btn: "Восстановить"
    },
    feedbackPage: {
      title: "Обратная связь",
      subtitle:
        "Используйте эту форму, если вы хотите задать нам вопрос или сообщить об ошибке. Пожалуйста, сделайте свое сообщение как можно более подробным, тогда мы сможем решить проблему гораздо быстрее."
    },
    passRecovery: "Напомнить пароль"
  },
  cabinetPages: {
    title: "Личный кабинет",
    tabs: {
      proxy: "Прокси сервисы",
      complaints: "Жалобы",
      promocodes: "Промокоды",
      notifications: "Уведомления",
      exit: "Выход"
    },
    proxy: {
      list: {
        new: "Добавить новый сервис",
        edit: "Редактировать",
        delete: "Удалить",
        cancel: "Отменить",
        modalTitle: "Вы уверены что хотите удалить сервис?",
        noData: "Нет сервисов"
      },
      create: {
        title: "Добавить новый прокси сервис"
      },
      edit: {
        title: "Редактирование прокси сервиса"
      }
    },
    promocodes: {
      list: {
        new: "Добавить новый промокод",
        edit: "Редактировать",
        delete: "Удалить",
        cancel: "Отменить",
        modalTitle: "Вы уверены что хотите удалить промокод?",
        noData: "Нет промокодов"
      },
      create: {
        title: "Добавить промокод"
      },
      edit: {
        title: "Редактировать промокод"
      }
    },
    breadcrumbs: {
      "cabinet-proxy": [
        {
          title: "Личный кабинет"
        }
      ],
      "cabinet-proxy-create": [
        {
          title: "Личный кабинет",
          route: "cabinet/proxy/"
        },
        {
          title: "Создание прокси сервиса"
        }
      ],
      "cabinet-proxy-edit": [
        {
          title: "Личный кабинет",
          route: "cabinet/proxy/"
        },
        {
          title: "Редактирование прокси сервиса"
        }
      ],
      "cabinet-promocodes": [
        {
          title: "Личный кабинет",
          route: "cabinet/proxy/"
        },
        {
          title: "Промокоды"
        }
      ],
      "cabinet-promocodes-create": [
        {
          title: "Личный кабинет",
          route: "cabinet/proxy/"
        },
        {
          title: "Промокоды",
          route: "cabinet/promocodes/"
        },
        {
          title: "Создание промокода"
        }
      ],
      "cabinet-promocodes-edit": [
        {
          title: "Личный кабинет",
          route: "cabinet/proxy/"
        },
        {
          title: "Промокоды",
          route: "cabinet/promocodes/"
        },
        {
          title: "Редактирование промокода"
        }
      ]
    }
  },
  feedbackPage: {
    title: "Оставить отзыв",
    text: [
      "Мы всегда готовы ответить на интересующие Вас вопросы, а также выслушать Ваши предложения по улучшению нашего сервиса.",
      "Используйте данную форму, если хотите задать нам вопрос, или сообщить о ошибке. Пожалуйста, делайте Ваше сообщение как можно более развернутым, тогда мы сможем решить проблему намного быстрее."
    ],
    form: {
      btn: "Отправить"
    },
    success: {
      title: "Спасибо за ваш отзыв!",
      subtitle: "Ваш комментарий принят. В ближайшее время мы его опубликуем!",
      btn: "Вернуться на главную"
    }
  },
  navigation: {
    promotionals: "Промокоды",
    addsite: "Добавить сайт",
    feedback: "Обратная связь",
    blog: "Блог",
    logIn: "Вход",
    selectService: "Выберите сервис",
    freeProxy: {
      title: "Бесплатные прокси",
      description:
        "Используйте список прокси абсолютно бесплатно и без авторизации"
    },
    browserFingerprint: {
      title: "Анонимная идентификация браузеров",
      description: "Просмотрите свойства анонимной идентификации браузера"
    },
    portScanner: {
      title: "Сканер портов",
      description:
        "Протестируйте списки прокси, чтобы избежать потенциальных ошибок"
    },
    tracingIP: {
      title: "Трассировка IP",
      description:
        "Найдите проблемы с подключением и получите больше полезных данных о них"
    },
    dndLeakTest: {
      title: "Тест на утечку DNS",
      description: "Проверьте свою конфиденциальность и надежность"
    },
    ipBlacklist: {
      title: "Черный список IP-адресов",
      description:
        "Используйте, чтобы узнать, занесен ли ваш IP-адрес в черный список"
    },
    myAnonymity: {
      title: "Моя анонимность",
      description: "Проверьте степень анонимности в сети"
    },
    webRTC: {
      title: "Тест на утечку WebRTC",
      description: "Защитите свои личные данные, устранив утечки WebRTC"
    },
    httpHeaders: {
      title: "Проверка заголовков HTTP",
      description: "Обеспечивает анализ заголовков каждой страницы сайта"
    },
    evercookieTest: {
      title: "Тест Evercookie",
      description:
        "Протестируйте файлы cookie и другие средства отслеживания, которые использует ваш сайт"
    },
    browserInformation: {
      title: "Информация о браузере",
      description: "Узнайте всю информацию о вашем браузере"
    },
    ipv4: {
      title: "IPv4 прокси",
      description: "Качество выделенного прокси по доступной цене"
    },
    ipv6: {
      title: "IPv6 прокси",
      description:
        "Пакет индивидуальных IP-адресов с неограниченным сроком действия"
    },
    mobileProxy: {
      title: "Мобильные LTE прокси",
      description:
        "Воспользуйтесь высокоскоростными вращающимися мобильными прокси-серверами 4G с динамическим IP-адресом"
    },
    residentialProxy: {
      title: "Резидентные прокси",
      description: "Самые доступные резидентные прокси"
    },
    sharedProxy: {
      title: "Shared прокси",
      description:
        "Используйте стабильные, быстрые и мощные общие IP-адреса по всему миру"
    },
    isp: {
      title: "ISP прокси"
    },
    remoteServer: {
      title: "Удаленный сервер"
    },
    topLocations: "Лучшие локации",
    europe: "Европа",
    asia: "Азия",
    northAmerica: "Северная Америка",
    southAmerica: "Южная Америка",
    australiaOceania: "Австралия и Океания",
    africa: "Африка",
    tools: "Инструменты",
    resources: "Ресурсы",
    interestingRead: "Интересно почитать",
    proxies: "Прокси",
    locations: "Локации",
    services: "Сервисы"
  },
  breadcrumbs: {
    main: "Главная"
  },
  proxy: {
    name: {
      label: "Сайт",
      placeholder: "Название сайта"
    },
    link: {
      label: "Ссылка на сервис"
    },
    referralLink: {
      label: "Реферальная ссылка"
    },
    proxyType: {
      title: "Тип"
    },
    countries: {
      title: "Страны"
    },
    goals: {
      title: "Цели использования"
    },
    minRent: {
      label: "Минимальный срок аренды"
    },
    minPrice: {
      label: "Минимальная цена"
    },
    auth: {
      title: "Авторизация",
      noAuth: "Без авторизации",
      loginAuth: "Логин + пароль",
      ipAuth: "По основному IP"
    },
    benefits: {
      title: "Преимущества",
      moneyBack: "Возврат денег 30 дней",
      support: "Поддержка 24/7",
      anonymity: "Анонимность",
      allPlatforms: "Все платформы"
    },
    freeTest: {
      label: "Бесплатный тест"
    },
    refunds: {
      label: "Возврат средств"
    },
    replacementPossibility: {
      label: "Замена IP"
    },
    api: {
      label: "API"
    },
    affiliateProgram: {
      label: "Партнёрская программа"
    },
    individual: {
      label: "Индивидуальные",
      tooltip:
        "Установите флажок, если ваши IP-адреса предоставляются для индивидуального использования"
    },
    promocode: {
      label: "Промокод"
    },
    description: {
      label: "Описание"
    },
    socialNetworks: {
      placeholder: "Введите "
    },
    affiliatePercent: {
      label: "Процент по партнерской программе"
    },
    contacts: {
      title: "Контактные данные",
      description: "Будут отображены на странице вашего сервиса"
    },
    logotype: {
      title: "Логотип",
      btn: {
        delLogo: "Удалить логотип"
      },
      modal: {
        btn: {
          reset: "Сбросить",
          save: "Сохранить"
        }
      }
    },
    checkAll: {
      label: "Выбрать все"
    },
    btn: {
      add: "Добавить сервис",
      edit: "Редактировать сервис"
    }
  },
  promocodes: {
    showCode: "Показать промокод",
    valid: "Действителен до",
    subscribe: "Подписаться на новые промокоды",
    copy: "Скопировать",
    site: {
      label: "Сайт",
      placeholder: "Выберите сайт",
      noData: "У вас еще нет сайтов"
    },
    promocode: {
      label: "Промокод",
      placeholder: "Введите промокод"
    },
    description: {
      label: "Описание",
      description: "Описание промокода"
    },
    eternal: {
      label: "Бесконечный",
      name: "Бесконечный"
    },
    isActive: {
      label: "Активный"
    },
    date: {
      label: "Дата действия"
    },
    btn: {
      add: "Добавить промокод",
      edit: "Редактировать промокод"
    }
  },
  notifications: {
    serverError: "Ошибка сервера! Пожалуйста, попробуйте позже.",
    apiError: "Что-то пошло не так!",
    invalidToken: "Неверный токен!",
    incorrectData: "Некорректные данные!",
    copy: "Скопированно!",
    copied: "Скопированно",
    comment: "Ваш комментарий принят. В ближайшее время мы его опубликуем!",
    vote: "Спасибо за ваш голос!",
    feedback: "Спасибо за ваш отзыв!",
    proxyQuery: "Запрос на прокси был успешно отправлен!",
    passwordRecovery: "Пароль был успешно изменен!",
    emailSent:
      "Вам было отправлено на почту сообщение с ссылкой для восстановления пароля! Пожалуйста, проверьте почту.",
    authError: {
      401: "Неверный логин или пароль!",
      540: "Такой пользователь уже зарегистрирован!",
      543: "Ошибка ввода капчи, пожалуйста, попробуйте снова!",
      560: "Пользователь не подтвержден! Для подтверждения перейдите по ссылке в письме в вашем Email!",
      561: "Пользователь заблокирован!",
      545: "Несуществующяя почта! Пожалуйста, введите действительный email!"
    },
    comparison: {
      added: "Сайт добавлен в сравнение!",
      removed: "Сайт удален из сравнения!"
    },
    site: {
      creating: "Создание сайта",
      created: "Ваш сайт был успешно создан!",
      removed: "Ваш сайт был успешно удален!",
      edited: "Ваш сайт был успешно обновлен!",
      exist: "Сайт с такой ссылкой уже существует!"
    },
    promocode: {
      created: "Ваш промокод был успешно создан!",
      removed: "Ваш промокод был успешно удален!",
      edited: "Ваш промокод был успешно обновлен!",
      duplicate: "Промокод с таким значением уже существует!"
    },
    image: {
      uploading: "Загрузка изображения",
      uploaded: "Изображение было успешно загружено!"
    },
    file: {
      badFile: "Что-то не так с файлом!",
      badFileType: "Тип вашего файла не соответствует .jpeg или .png",
      largeSize: "Размер файла больше чем 2 МБ."
    },
    modals: {
      review: "Спасибо за ваш отзыв! Ваш отзыв был отправлен на модерацию!",
      reviewEdit: "Ваш отзыв отредактирован и был отправлен на модерацию!",
      reviewDelete: "Отзыв был успешно удален!",
      complaint:
        "Спасибо за вашу жалобу! Ваша жалоба была отправлена на модерацию!",
      comment: "Ваш комментарий принят. В ближайшее время мы его опубликуем!",
      reviewAlreadySend:
        "С вашего ИП/Email уже был оставлен отзыв этому сервису!"
    },
    blogInner: {
      notFoundBlogPost: "Пост блога не найден!"
    }
  },
  dashboard: {
    navBar: {
      profile: "профиль",
      services: "сервисы",
      reviews: "отзывы",
      promocodes: "промокоды",
      hello: "Привет",
      logout: "Выйти",
      addSite: "Добавить сайт"
    },
    profile: {
      title: "Профиль",
      blockEmail: {
        title: "E-mail адрес",
        subtitle: "Управление вашим e-mail адресом",
        change: "Изменить e-mail"
      },
      blockPassword: {
        title: "Пароль",
        subtitle: "Управление вашим паролем",
        help: "Минимум 6 символов"
      },
      confirmModal: {
        title: "Подтвердите изменения",
        subtitle: "Введите специальный код из вашего e-mail",
        timer: "Код будет активен",
        resend: "Отправить код повторно",
        invalidToken: "Неверный токен",
        invalidPassword: "Пароль не совпадает с текущим",
        invalidEmail: "Пожалуйста, введите действительный email",
        emailExist: "Этот email уже занят",
        successEmail: "Email успешно изменен",
        successPassword: "Пароль успешно изменен",
        successSend: "На вашу почту отправлено письмо с кодом подтверждения"
      }
    },
    services: {
      table: {
        title: "Сервисы",
        add: "Добавить сервис",
        sort: "Сортировать по",
        filter: "Фильтровать по",
        all: "Все",
        columns: {
          name: "Название",
          date: "Дата",
          status: "Статус",
          views: "Просмотры",
          reviews: "Отзывы",
          conversions: "Конверсии",
          actions: {
            title: "Действия",
            edit: "Редактировать",
            view: "Просмотр cтраницы",
            delete: "Удалить"
          }
        }
      },
      noData: {
        title: "У вас пока нет ни одного сервиса",
        subtitle: "Добавьте сервис и он появится в этой таблице."
      },
      modal: {
        delete: {
          title: "Удалить сервис",
          subtitle: "Вы уверены что хотите удалить этот сервис?"
        }
      },
      addEdit: {
        titleAdd: "Добавить сервис",
        titleEdit: "Редактировать сервис",
        add: "Добавить сервис",
        edit: "Редактировать сервис",
        blocks: {
          logo: {
            title: "Логотип компании",
            subtitle: "Загрузите файл или укажите ссылку ниже",
            check: "Проверить",
            download: "Скачать файл и подтвердить",
            instruction: {
              title: "Инструкция",
              variantMeta: "Добавьте мета-тег на ваш сайт",
              variantFile: "или добавьте файл в корень сайта",
              variantFileExample: "Путь к файлу должен быть таким:"
            },
            checkError:
              "Мы не можем проверить ваш сайт. Убедитесь, что вы выполнили инструкции по проверке и повторите попытку.",
            checkSuccess: "Сайт успешно проверен!",
            downloadFileError: "Ошибка при загрузке файла"
          },
          characteristics: {
            title: "Характеристики",
            subtitle: "Укажите среднее значение предлагаемых вами услуг",
            speed: "Скорость",
            ping: "Пинг",
            speedPlaceholder: "МБ/с",
            pingPlaceholder: "мс",
            from: "От",
            to: "До"
          },
          description: {
            title: "Описание",
            subtitle: "Добавьте короткое описание вашего сервиса"
          },
          type: {
            title: "Тип",
            subtitle: "Выберите тип прокси и добавьте описание характеристик",
            advantages: {
              title: "Преимущества",
              placeholder: "Анонимность и т.д.",
              add: "Добавить преимущество"
            },
            countries: {
              active: "Активные страны",
              inactive: "Неактивные страны",
              description:
                "Эти страны отсутствуют в нашем каталоге, вы можете выбрать их. Модератор сайта рассмотрит возможность создания необходимой страны"
            }
          },
          auth: {
            title: "Авторизация",
            subtitle: "Выберите тип авторизации"
          },
          benefits: {
            title: "Преимущества",
            subtitle: "Выберите преимущества"
          },
          features: {
            title: "Функционал",
            subtitle: "Выберите функционал",
            descriptions: {
              refundDays:
                "Укажите период, в течение которого пользователь может запросить возврат средств."
            }
          },
          promocode: {
            title: "Промокод",
            subtitle:
              "Промокод позволяет отличить сервис от других и дать скидку пользователю",
            show: "Как это выглядит?"
          },
          contacts: {
            title: "Контакты",
            subtitle: "Укажите контактные данные"
          }
        }
      }
    },
    promocodes: {
      table: {
        title: "Промокоды",
        add: "Добавить промокод",
        sort: "Сортировать по",
        noDataFilter: "Нет данных",
        columns: {
          name: "Название",
          promocode: "Промокод",
          status: "Статус",
          date: "Дата",
          active: "Активен",
          actions: {
            title: "Действия",
            edit: "Редактировать",
            delete: "Удалить"
          }
        }
      },
      activated: {
        success: "Cтатус промокода успешно изменен!",
        notFound: "Промокод не найден!",
        startsLater: "Промокод начнет действовать позже!",
        isExpired: "Cрок действия промокода истек!"
      },
      noData: {
        title: "У вас пока нет ни одного промокода",
        subtitle: "Добавьте промокод и он появится в этой таблице."
      },
      modal: {
        delete: {
          title: "Удалить промокод",
          subtitle: "Вы уверены что хотите удалить этот промокод?"
        }
      },
      addEdit: {
        titleAdd: "Добавление промокода",
        titleEdit: "Редактирование промокода",
        add: "Добавить промокод",
        edit: "Редактировать промокод",
        blocks: {
          site: {
            title: "Сайт",
            subtitle: "Добавьте ваш сайт, например www.sitename.com"
          },
          promocode: {
            title: "Промокод",
            subtitle:
              "Промокод размещается на добровольной основе для того, чтобы отличить сервис от других"
          },
          description: {
            title: "Описание",
            subtitle:
              "Выберите язык ввода и добавьте краткое описание промокода"
          },
          period: {
            title: "Срок действия",
            subtitle: "Установите срок действия промокода",
            date: "Дата"
          },
          active: {
            description:
              "Пожалуйста, во избежание сложностей применения промокода пользователями, активируйте промокод только в случае когда он активен."
          }
        }
      }
    },
    reviews: {
      title: "Мои отзывы",
      add: "Добавить отзыв",
      appeal: {
        btn: "Подать апелляцию",
        modal: {
          title: "Комментарий к апелляции"
        }
      },
      noData: {
        title: "У вас пока нет ни одного отзыва",
        subtitle: "Добавьте отзыв и он появится в этой таблице."
      },
      sortList: [
        {
          label: "Дате",
          value: "CREATION_DATE"
        },
        {
          label: "Рейтингу",
          value: "RATING"
        },
        {
          label: "Полезности",
          value: "USEFULNESS"
        }
      ],
      status: {
        approved: "Одобрен",
        rejected: "Отклонен",
        moderation: "На модерации"
      },
      statusDescription: {
        approved: "Ваш отзыв одобрен",
        rejected: "Ваш отзыв отклонен",
        moderation: "Ваш отзыв находится на модерации"
      },
      modals: {
        delete: {
          success: "Отзыв был успешно удален!",
          error: "Ошибка при удалении отзыва!",
          subtitle: "Вы уверены что хотите удалить этот отзыв?"
        }
      }
    }
  },
  search: {
    label: "Поиск",
    placeholder: "Поиск..."
  },
  forms: {
    refundDays: {
      label: "Дни возврата",
      placeholder: "Введите количество дней",
      rules: {
        required: "Дни возврата обязательны",
        positive: "Дни возврата должны быть больше 0",
        integer: "Дни возврата должны быть целым числом"
      }
    },
    socialLink: {
      rules: {
        https: "Ссылка должна начинаться с https:// и не содержать пробелов"
      }
    },
    reviewBody: {
      label: "Отзыв",
      placeholder: "Введите ваш отзыв",
      rules: {
        required: "Отзыв обязателен",
        max: "Отзыв не должен быть длиннее 100 символов"
      }
    },
    allLanguages: {
      rules: {
        allMustBeFilled: "Заполните для всех языков"
      }
    },
    agree: {
      rules: {
        required: "Согласие обязательно для продолжения"
      }
    },
    site: {
      rules: {
        required: "Сайт обязателен"
      }
    },
    promocode: {
      label: "Промокод",
      placeholder: "Введите промокод",
      rules: {
        required: "Промокод обязателен"
      }
    },
    promocodeDescription: {
      label: "Описание промокода",
      placeholder: "Введите описание промокода",
      rules: {
        required: "Описание промокода обязательно",
        minLength: "Минимальное допустимое количество символов в описание: 50!",
        maxLength:
          "Максимальное допустимое количество символов в описание: 100!"
      }
    },
    login: {
      placeholder: "Логин",
      rules: {
        required: "Логин обязателен"
      }
    },
    name: {
      label: "Ваше имя",
      rules: {
        required: "Имя обязательно",
        max: "Имя не должно быть длиннее чем 40 символов"
      }
    },
    link: {
      label: "Ссылка",
      placeholder: "Введите ссылку",
      rules: {
        required: "Ссылка обязательна",
        url: "Некорректная ссылка"
      }
    },
    linkCategory: {
      label: "Тип обслуживания",
      placeholder: "Выберите тип услуги",
      rules: {
        required: "Укажите тип услуги"
      }
    },
    age: {
      rules: {
        required: "Возраст обязательный",
        max: "Максимальный возраст 99 лет"
      }
    },
    proxyType: {
      placeholder: "Выберите тип прокси",
      rules: {
        required: "Выберите тип",
        minOne: "Выберите хотя бы один тип"
      }
    },
    countries: {
      label: "Страны",
      placeholder: {
        one: "Выберите страну",
        many: "Выберите страны"
      },
      rules: {
        required: "Выберите страны"
      }
    },
    purposeUse: {
      placeholder: {
        one: "Выберите цель использования"
      },
      rules: {
        required: "Выберите цели"
      }
    },
    date: {
      rules: {
        required: "Дата обязательна",
        allRequired: "Дата начала и окончания обязательны",
        endDateGreaterNow: "Дата оконачания должна быть больше текущей"
      }
    },
    minRent: {
      label: "Мин. срок аренды",
      placeholder: "Выберите минимальный период аренды",
      rules: {
        required: "Выберите минимальный срок аренды"
      }
    },
    minPrice: {
      label: "Минимальная цена",
      placeholder: "Мин. цена / шт",
      rules: {
        required: "Введите минимальную цену",
        max: "Цена не должна превышать 12 символов",
        positive: "Цена должна быть больше 0"
      }
    },
    pullIP: {
      label: "Пул IP",
      placeholder: "Пул IP",
      rules: {
        required: "Пул IP обязателен",
        positive: "Пул IP должен быть больше 0"
      }
    },
    minIP: {
      label: "Мин. IP",
      placeholder: "Мин. IP",
      tooltip: "Минимальный обьем услуги которые позволяет приобрести сервис",
      rules: {
        required: "Мин. IP обязателен",
        positive: "Мин. IP должен быть больше 0",
        integer: "Мин. IP должен быть целым числом"
      }
    },
    minGB: {
      label: "Мин. GB",
      placeholder: "Мин. GB",
      rules: {
        required: "Мин. GB обязателен",
        positive: "Мин. GB должен быть больше 0"
      }
    },
    currency: {
      placeholder: "Выберите валюту",
      rules: {
        required: "Выберите валюту"
      }
    },
    speed: {
      rules: {
        number: "Значение должно быть числом",
        greater: "Значение До должно быть больше значения От",
        positive: "Значение скорости должно быть положительным"
      }
    },
    ping: {
      rules: {
        positive: "Значение пинга должно быть положительным"
      }
    },
    description: {
      label: "Описание",
      placeholder: "Введите описание",
      rules: {
        required: "Напишите описание",
        minLength: "Минимальное допустимое количество символом в описании: 50!",
        maxLength:
          "Максимальное допустимое количество символом в описании: 100!",
        maxLength500:
          "Максимальное допустимое количество символом в описании: 500!",
        notEmpty: "Описание не может быть пустым"
      }
    },
    password: {
      placeholder: "Пароль",
      placeholderRepeat: "Повторите пароль",
      placeholderCurrent: "Текущий пароль",
      labelChangeEmail: "Пароль (если меняете email)",
      labelCurrent: "Текущий пароль",
      labelNew: "Новый пароль",

      newPassword: "Введите новый пароль",
      state: {
        show: "Показать пароль",
        hide: "Скрыть пароль"
      },
      rules: {
        required: "Пароль обязателен",
        min: "Минимум символов",
        mismatch: "Пароли должны совпадать",
        noSpace: "Прароль не может содержать пробелы",
        cannotBeSame: "Новый пароль не может быть таким же как текущий"
      }
    },
    email: {
      label: "Ваша почта",
      rules: {
        required: "Почта обязательна",
        email: "Некорректная почта"
      }
    },
    emailV2: {
      label: "E-mail",
      placeholder: "Введите ваш e-mail",
      rules: {
        required: "Почта обязательна",
        email: "Некорректная почта"
      }
    },
    answer: {
      label: "Ваш ответ",
      rules: {
        required: "Ответ обязателен"
      }
    },
    affiliatePercent: {
      rules: {
        max: "Процент не может быть больше чем 3 символа"
      }
    },
    message: {
      label: "Сообщение",
      placeholder: "Введите текст...",
      rules: {
        required: "Заполните сообщение"
      }
    },
    comment: {
      label: "Комментарий",
      placeholder: "Введите комментарий",
      rules: {
        required: "Заполните комментарий"
      }
    },
    field: {
      rules: {
        required: "Поле обязательное"
      }
    },
    common: {
      rules: {
        required: "Выберите значение"
      }
    },
    addComment: {
      btn: "Добавить комментарий"
    },
    buttons: {
      submit: "Отправить",
      cancel: "Отменить",
      save: "Сохранить",
      delete: "Удалить",
      edit: "Редактировать",
      add: "Добавить",
      saveChanges: "Сохранить изменения",
      confirm: "Подтвердить",
      send: "Отправить"
    },
    noOptions: "Нет вариантов"
  },
  reviews: {
    anonymous: "Аноним",
    answers: "Ответы",
    answer: "Ответить",
    showMore: "Показать еще",
    showFull: "Показать полностью",
    you: "Вы",
    expert: "Ответ эксперта",
    showAll: "Показать все ответы",
    showLess: "Свернуть",
    content: {
      advantages: {
        title: "Достоинства"
      },
      disadvantages: {
        title: "Недостатки"
      },
      review: {
        title: "Комментарий"
      },
      usage: {
        title: "Где использовали"
      },
      wishes: {
        title: "Пожелания продавцу"
      },
      reason: {
        title: "Причина"
      }
    },
    reply: {
      apply: "Ответить",
      cancel: "Отменить",
      toggle: {
        close: "Свернуть",
        open: "Развернуть"
      }
    },
    voting: {
      title: "Проголосовать"
    },
    feedback: {
      title: "Отзыв полезен"
    },
    rating: {
      title: "Рейтинг",
      from: "из"
    },
    translator: {
      status: {
        translated: "Переведено",
        original: "Оригинал"
      },
      showOriginal: "Показать оригинал",
      translate: "Перевести"
    }
  },
  common: {
    yes: "Да",
    no: "Нет"
  },
  validation: {
    valid: {
      title: "Валидный",
      report: "Домен валидный!"
    },
    invalid: {
      title: "Невалидный",
      report: "Домен невалидный!",
      panel: {
        title: "Валидация домена для",
        notice: "Чтобы подтвердить домен, используйте один из способов ниже.",
        subtitle: "Как подтвердить домен",
        link: {
          title: "В корне сайта добавьте файл загруженный",
          linkName: "здесь",
          example: "Путь к файлу должен быть такой:"
        },
        metag: {
          title: "Или же добавьте метатег на сайт:"
        },
        nextData: {
          title: "Дата следующей валидации:"
        }
      }
    }
  },
  statuses: {
    added: "Добавлен",
    active: "Активный",
    inactive: "Не активный",
    title: "Статус",
    moderation: "На модерации"
  },
  statistic: {
    views: {
      title: "Просмотров"
    },
    conversions: {
      title: "Переходов"
    },
    reviews: {
      title: "Отзывов"
    }
  },
  uploader: {
    chooseFile: "Нажмите, чтобы загрузить",
    chooseFileSecond: "или перетащите его сюда",
    or: "или",
    chooseOtherFile: "Выберите другой файл",
    staticState: "или перетащите его сюда. (до 2 МБ)",
    draggingState: "Отпустите файл, чтобы загрузить его",
    format: "SVG, PNG или JPG 256x256 px (макс. размер 2MB)"
  },
  modals: {
    review: {
      titleAdd: "Оставить отзыв",
      titleEdit: "Редактировать отзыв",
      titleDelete: "Вы уверены что хотите удалить отзыв?",
      edit: "Редактировать",
      delete: "Удалить",
      cancel: "Отменить",
      form: {
        ratings: {
          title: "Оцените сервис",
          rating: "Рейтинг",
          speed: "Скорость",
          reliability: "Надежность",
          support: "Поддержка",
          price: "Цена"
        },
        personalInfo: {
          title: "Информация о пользователе",
          name: "Имя пользователя",
          email: "E-mail"
        },
        advantages: "Достоинства",
        disadvantages: "Недостатки",
        review: "Комментарий",
        usage: "Где использовали",
        wishes: "Пожелания продавцу",
        rating: "Выберите рейтинг",
        placeholder: "Введите текст",
        success: {
          title: "Спасибо!",
          text: "Ваш отзыв отправлен и появится на сайте после проверки модератором"
        }
      }
    },
    complaint: {
      title: "Оставить жалобу",
      form: {
        reason: "Причина",
        body: "Жалоба"
      }
    },
    comment: {
      title: "Оставить комментарий",
      form: {
        name: {
          title: "Имя пользователя",
          placeholder: "Имя"
        },
        email: {
          title: "E-mail",
          placeholder: "rating.proxy@mail.com"
        },
        body: {
          title: "Комментарий",
          placeholder: "Текст комментария"
        }
      }
    },
    email: {
      title: "Подтверждение почты",
      confirmed: "Ваша почта успешно подтверждена!",
      notConfirmed:
        "К сожаления, не получилось подтвердить вашу почту! Зарегестрируйтесь ещё раз."
    },
    subscribe: {
      title: "Подписка на новые промокоды",
      description: "Мы отправим вам новые промокоды на почту",
      success: "Вы успешно подписались на новые промокоды!",
      info: "Вы уже подписаны на новые промокоды этого сервиса!",
      form: {
        email: {
          title: "E-mail",
          placeholder: "Введите ваш e-mail"
        },
        name: {
          title: "Имя",
          placeholder: "Введите ваше имя"
        },
        btn: "Подписаться",
        close: "Закрыть"
      }
    }
  },
  yesno: [
    {
      value: true,
      label: "Да"
    },
    {
      value: false,
      label: "Нет"
    }
  ],
  times: {
    hour: ["час", "часа", "часов"],
    day: ["день", "дня", "дней"],
    week: ["неделя", "недели", "недель"],
    month: ["месяц", "месяца", "месяцев"],
    year: ["год", "года", "лет"]
  },
  proxyItems: {
    countries: {
      title: "Прокси по странам",
      list: {
        more: "Больше",
        countries: "стран",
        proxies: "прокси сервисов"
      }
    },
    byType: {
      title: "Прокси по типу"
    },
    social: {
      title: "Прокси для соц. сетей",
      additionalText: ["и другие"]
    },
    seo: {
      title: "Прокси для SEO",
      additionalText: ["и другие"]
    },
    mobile: {
      title: "Мобильные прокси",
      list: {
        countries: "стран",
        operators: "операторов",
        proxies: "прокси сервисов"
      }
    },
    shared: {
      title: "Shared прокси",
      list: {
        countries: "стран",
        proxies: "прокси сервисов"
      }
    },
    unknownTile: {
      title: "Неизвестная плитка"
    }
  },
  countries: [
    {
      code: "EGY",
      name: "Египет",
      nameGradient: "Египта"
    },
    {
      code: "AUS",
      name: "Австралия",
      nameGradient: "Австралии"
    },
    {
      code: "AUT",
      name: "Австрия",
      nameGradient: "Австрии"
    },
    {
      code: "GBR",
      name: "Великобритания",
      nameGradient: "Великобритании"
    },
    {
      code: "AM",
      name: "Армения",
      nameGradient: "Армении"
    },
    {
      code: "BLR",
      name: "Беларусь",
      nameGradient: "Беларуси"
    },
    {
      code: "BGR",
      name: "Болгария",
      nameGradient: "Болгарии"
    },
    {
      code: "BRA",
      name: "Бразилия",
      nameGradient: "Бразилии"
    },
    {
      code: "VEN",
      name: "Венесуэла",
      nameGradient: "Венесуэлы"
    },
    {
      code: "DEU",
      name: "Германия",
      nameGradient: "Германии"
    },
    {
      code: "GEO",
      name: "Грузия",
      nameGradient: "Грузии"
    },
    {
      code: "EUROPE",
      name: "Европа",
      nameGradient: "Европы"
    },
    {
      code: "IND",
      name: "Индия",
      nameGradient: "Индии"
    },
    {
      code: "ESP",
      name: "Испания",
      nameGradient: "Испании"
    },
    {
      code: "ITA",
      name: "Италия",
      nameGradient: "Италии"
    },
    {
      code: "KAZ",
      name: "Казахстан",
      nameGradient: "Казахстана"
    },
    {
      code: "CAN",
      name: "Канада",
      nameGradient: "Канады"
    },
    {
      code: "KGZ",
      name: "Киргизия",
      nameGradient: "Киргизии"
    },
    {
      code: "CHN",
      name: "Китай",
      nameGradient: "Китая"
    },
    {
      code: "KOR",
      name: "Южная Корея",
      nameGradient: "Южной Корее"
    },
    {
      code: "LVA",
      name: "Латвия",
      nameGradient: "Латвии"
    },
    {
      code: "LTU",
      name: "Литва",
      nameGradient: "Литвы"
    },
    {
      code: "LIE",
      name: "Лихтенштейн",
      nameGradient: "Лихтенштейна"
    },
    {
      code: "MEX",
      name: "Мексика",
      nameGradient: "Мексики"
    },
    {
      code: "MDA",
      name: "Молдавия",
      nameGradient: "Молдавии"
    },
    {
      code: "MCO",
      name: "Монако",
      nameGradient: "Монако"
    },
    {
      code: "NLD",
      name: "Нидерланды",
      nameGradient: "Нидерландов"
    },
    {
      code: "NOR",
      name: "Норвегия",
      nameGradient: "Норвегии"
    },
    {
      code: "POL",
      name: "Польша",
      nameGradient: "Польши"
    },
    {
      code: "RUS",
      name: "Россия",
      nameGradient: "России"
    },
    {
      code: "SYC",
      name: "Сейшелы",
      nameGradient: "Сейшел"
    },
    {
      code: "SGP",
      name: "Сингапур",
      nameGradient: "Сингапура"
    },
    {
      code: "SVN",
      name: "Сальвадор",
      nameGradient: "Сальвадора"
    },
    {
      code: "USA",
      name: "США",
      nameGradient: "США"
    },
    {
      code: "TUR",
      name: "Турция",
      nameGradient: "Турции"
    },
    {
      code: "UKR",
      name: "Украина",
      nameGradient: "Украины"
    },
    {
      code: "FIN",
      name: "Финляндия",
      nameGradient: "Финляндии"
    },
    {
      code: "FRA",
      name: "Франция",
      nameGradient: "Франции"
    },
    {
      code: "MNE",
      name: "Черногория",
      nameGradient: "Черногории"
    },
    {
      code: "CZE",
      name: "Чехия",
      nameGradient: "Чехии"
    },
    {
      code: "CHL",
      name: "Чили",
      nameGradient: "Чили"
    },
    {
      code: "CHE",
      name: "Швейцария",
      nameGradient: "Швейцарии"
    },
    {
      code: "SWE",
      name: "Швеция",
      nameGradient: "Швеции"
    },
    {
      code: "LKA",
      name: "Шри-Ланка",
      nameGradient: "Шри-ланки"
    },
    {
      code: "JAM",
      name: "Ямайка",
      nameGradient: "Ямайки"
    },
    {
      code: "JPN",
      name: "Япония",
      nameGradient: "Японии"
    }
  ],
  proxyTypesGen: {
    IPv4: "IPv4",
    IPv6: "IPv6",
    ISP: "ISP",
    FreeProxy: "Бесплатных",
    MobileProxy: "Мобильных",
    SharedProxy: "Общих",
    ResidentialProxy: "Резидентских",
    RemoteServer: "Удаленных"
  },
  ui: {
    readReview: "Читать отзывы",
    yesNo: {
      yes: "Да",
      no: "Нет"
    },
    export: "Экспорт:",
    step: "Шаг",
    on: "на"
  },
  fingerprint: {
    main: {
      title: "Анонимная идентификация браузеров",
      subtitle: "Вы уникальны? Узнайте свойства идентификации вашего браузера",
      buttonLabel: "Проверить свои отпечаток"
    },
    httpAttributes: "Атрибуты HTTP-заголовков",
    searchAttributes: "Поиск атрибутов",
    javaScriptAttributes: "Атрибуты JavaScript",
    table: {
      attribute: "Атрибуты",
      similarityRatio: "Коэффициент сходства",
      value: "Значение",
      and: "И",
      others: "Других",
      noValue: "Нет значения"
    },
    faq: "Часто задаваемые вопросы",
    HTTP: {
      userAgent: "User Agent",
      userAgentTooltip: "Показывает информацию о браузере и устройстве",
      accept: "Допустимые типы данных",
      acceptTooltip:
        "HTTP-заголовок, указывающий на типы медиа, которые клиент готов принимать от сервера.",
      contentEncoding: "Кодировка контента",
      contentEncodingTooltip:
        "HTTP-заголовок, указывающий на кодировку, примененную к контенту.",
      contentLanguage: "Язык контента",
      contentLanguageTooltip:
        "HTTP-заголовок, указывающий на язык(и) предоставляемого сервером контента.",
      upgradeInsecureRequests: "Обновление небезопасных запросов",
      upgradeInsecureRequestsTooltip:
        "HTTP-заголовок, указывающий на предпочтение клиента в получении безопасных (HTTPS) версий ресурсов.",
      ifNoneMatch: "If none match",
      ifNoneMatchTooltip: "If none match",
      referer: "Referer",
      refererTooltip: "Referer"
    },
    JS: {
      userAgentJS: "User Agent",
      userAgentJSTooltip: "Отображает информацию о браузере и устройстве",
      os: "Операционная система",
      osTooltip:
        "Программное обеспечение, управляющее компьютером или устройством",
      cookie: "Cookies включены",
      cookieTooltip:
        "Небольшой файл данных, который сайт сохраняет в браузере. Используется для хранения пользовательских настроек и сессий.",
      timeZone: "Часовой пояс",
      timeZoneTooltip:
        "Временной стандарт для региона, который используется устройством или приложением.",
      languageJs: "Язык контента",
      languageJsTooltip:
        "Язык программирования или скриптов, используемый на сайте или в приложении.",
      navigatorProperties: "Свойства навигатора",
      navigatorPropertiesTooltip: "Свойства навигатора",
      canvas: "Canvas",
      canvasTooltip:
        "Процесс сбора мнений или данных, обычно через опросы или прямые взаимодействия.",
      fonts: "Список шрифтов (JS)",
      fontsTooltip: "Список шрифтов, используемых в приложении (JS).",
      adblock: "Использование Adblock",
      adblockTooltip:
        "Инструмент или расширение, предотвращающее показ рекламы в браузере.",
      doNotTrack: "Не отслеживать",
      doNotTrackTooltip:
        "Функция конфиденциальности, предотвращающая отслеживание вашей активности в Интернете.",
      buildID: "BuildID",
      buildIDTooltip:
        "Уникальный идентификатор для конкретной сборки или версии приложения.",
      product: "Продукт",
      productTooltip:
        "Конкретный товар или услуга, предлагаемые компанией или приложением.",
      productSub: "Подпродукт",
      productSubTooltip:
        "Подкатегория или конкретный вариант основного продукта.",
      vendor: "Поставщик",
      vendorTooltip:
        "Компания или лицо, предоставляющее или продающее товар или услугу.",
      vendorSub: "Субпоставщик",
      vendorSubTooltip:
        "Подкатегория или конкретный вариант, связанный с основным поставщиком.",
      hardware: "Конкурентоспособность оборудования",
      hardwareTooltip:
        "Количество логических процессоров или ядер, доступных на устройстве.",
      java: "Java включена",
      javaTooltip: "Указывает, включена ли Java в браузере или приложении.",
      memory: "Память устройства",
      memoryTooltip: "Объем оперативной памяти (RAM), доступной на устройстве.",
      plugins: "Список плагинов",
      pluginsTooltip: "Список установленных плагинов браузера или приложения.",
      screenWidth: "Ширина экрана",
      screenWidthTooltip: "Общая ширина экрана, включая все видимые области.",
      screenHeight: "Высота экрана",
      screenHeightTooltip: "Общая высота экрана, включая все видимые области.",
      screenDepth: "Глубина цвета экрана",
      screenDepthTooltip:
        "Количество бит, используемых для представления цвета каждого пикселя на экране.",
      availableTop: "Доступная верхняя часть экрана",
      availableTopTooltip:
        "Вертикальное расстояние от верхней части экрана до верхнего края области, доступной для контента, без учета системных элементов.",
      availableLeft: "Доступная левая часть экрана",
      availableLeftTooltip:
        "Горизонтальное расстояние от левого края экрана до левого края области, доступной для контента, без учета системных элементов.",
      availableHeight: "Доступная высота экрана",
      availableHeightTooltip:
        "Вертикальное расстояние, доступное для контента на экране, без учета областей, занятых системными элементами.",
      availableWidth: "Доступная ширина экрана",
      availableWidthTooltip:
        "Горизонтальное расстояние, доступное для контента на экране, без учета областей, занятых системными элементами.",
      permissions: "Разрешения",
      permissionsTooltip:
        "Список разрешений, предоставленных или запрашиваемых приложением или сайтом.",
      webGLVendor: "Поставщик WebGL",
      webGLVendorTooltip:
        "Поставщик или производитель реализации WebGL, используемой браузером или приложением.",
      webGLRenderer: "Рендерер WebGL",
      webGLRendererTooltip:
        "Конкретный рендерер или графический движок, используемый WebGL для отображения графики в браузере или приложении.",
      webGLData: "Данные WebGL",
      webGLDataTooltip:
        "Информация о контексте WebGL и данные, которые обрабатываются или используются для отображения.",
      webGLParameters: "Параметры WebGL",
      webGLParametersTooltip:
        "Настройки и параметры для контекста WebGL, включая детали об отображении и производительности.",
      useOfLocalStorage: "Использование локального хранилища",
      useOfLocalStorageTooltip:
        "Указывает, использует ли приложение или сайт локальное хранилище браузера для сохранения данных.",
      useOfSessionStorage: "Использование сеансового хранилища",
      useOfSessionStorageTooltip:
        "Указывает, использует ли приложение или сайт сеансовое хранилище браузера для временного сохранения данных.",
      useOfIndexedDB: "Использование IndexedDB",
      useOfIndexedDBTooltip:
        "Указывает, использует ли приложение или сайт IndexedDB для хранения большого объема структурированных данных.",
      audioFormats: "Аудиоформаты",
      audioFormatsTooltip:
        "Список аудиоформатов, поддерживаемых или используемых приложением или сайтом.",
      audioContext: "Аудиоконтекст",
      audioContextTooltip:
        "Интерфейс, используемый для управления и контроля обработки аудио и маршрутизации в приложении или на сайте.",
      frequencyAnalyser: "Анализатор частот",
      frequencyAnalyserTooltip:
        "Инструмент или функция, анализирующая и отображающая частотные компоненты аудиосигналов.",
      audioData: "Аудио данные",
      audioDataTooltip:
        "Сырые или обработанные данные, представляющие аудиосигналы, используемые для воспроизведения или анализа.",
      videoFormats: "Видео форматы",
      videoFormatsTooltip:
        "Список видеоформатов, которые поддерживаются или используются приложением или сайтом.",
      mediaDevices: "Медийные устройства",
      mediaDevicesTooltip:
        "Список медийных устройств, таких как камеры, микрофоны и динамики, доступных для приложения или сайта.",
      accelerometer: "Акселерометр",
      accelerometerTooltip:
        "Датчик, который измеряет силы ускорения, действующие на устройство, для определения движения и ориентации.",
      gyroscope: "Гироскоп",
      gyroscopeTooltip:
        "Датчик, который измеряет скорость вращения вокруг осей устройства для определения ориентации и угловой скорости.",
      proximitySensor: "Датчик приближения",
      proximitySensorTooltip:
        "Датчик, который обнаруживает наличие соседних объектов без физического контакта, обычно используется для определения близости к устройству.",
      keyboardLayout: "Расположение клавиатуры",
      keyboardLayoutTooltip:
        "Расположение клавиш на клавиатуре, включая тип используемой раскладки (например, QWERTY, AZERTY).",
      battery: "Аккумулятор",
      batteryTooltip: "Информация о состоянии аккумулятора устройства",
      connection: "Соединение",
      connectionTooltip:
        "Информация о типе и статусе сетевого соединения, например, Wi-Fi, мобильная связь или Ethernet.",
      key: "Ключ",
      keyTooltip:
        "Уникальный идентификатор или информация, используемая для доступа к данным или сервисам.",
      locationBar: "Адресная строка",
      locationBarTooltip:
        "Часть интерфейса браузера, где отображается и может редактироваться URL текущей веб-страницы.",
      menuBar: "Меню",
      menuBarTooltip:
        "Часть интерфейса приложения или браузера, которая содержит меню для доступа к различным функциям и настройкам.",
      personalBar: "Персональная панель",
      personalBarTooltip:
        "Настроечная область в интерфейсе браузера, где пользователи могут добавлять ярлыки к своим любимым сайтам или инструментам.",
      statusBar: "Строка состояния",
      statusBarTooltip:
        "Область интерфейса браузера или приложения, которая отображает информацию о текущем состоянии, например, о процессе загрузки или уведомления.",
      toolBar: "Панель инструментов",
      toolBarTooltip:
        "Часть интерфейса приложения или браузера, которая предоставляет доступ к различным инструментам и функциям, часто в виде кнопок или значков.",
      resultState: "Состояние результата",
      resultStateTooltip: "Текущий статус или состояние результата",
      listOfFontsFlash: "Список шрифтов (Flash)",
      listOfFontsFlashTooltip:
        "Список шрифтов, доступных в Adobe Flash или Flash Player, который может включать как системные, так и встроенные шрифты.",
      screenResolutionFlash: "Разрешение экрана (Flash)",
      screenResolutionFlashTooltip:
        "Настройки разрешения экрана, используемые Adobe Flash или Flash Player, которые указывают ширину и высоту области отображения.",
      languageFlash: "Язык (Flash)",
      languageFlashTooltip:
        "Настройки языка, используемые Adobe Flash или Flash Player, которые указывают язык контента или интерфейса пользователя.",
      platformFlash: "Платформа (Flash)",
      platformFlashTooltip:
        "Настройки платформы или операционной системы, используемые Adobe Flash или Flash Player, которые указывают на среду, в которой работает Flash."
    }
  },
  portScanner: {
    main: {
      title: "Сканер портов онлайн",
      subtitle:
        "Port Checker — это простой и бесплатный онлайн-инструмент для проверки открытых портов на вашем компьютере/устройстве, который часто полезен при проверке настроек переадресации портов на маршрутизаторе."
    },
    testPort: "Проверьте свой порт:",
    form: {
      loading: "Загрузка",
      scan: "Cканировать",
      pasteIP: "Вставить мой IP-адрес",
      inputLabel: "IP-адрес или домен:",
      selectLabel: "Тип сканирования портов",
      infoMessage:
        "Если результат «Хост, кажется, не работает», то проверяется брандмауэр или маршрутизатор цели, и IP-адрес блокирует пинги",
      popularPorts: "Популярные порты",
      serverPorts: "Серверные порты",
      gamePorts: "Игровые порты",
      applicationPorts: "Порты приложения",
      p2pPorts: "P2P порты",
      rules: {
        required: "IP-адрес или домен обязателен",
        ip: "Неправильный IP-адрес или домен"
      }
    },
    checkResults: "Результаты проверки:",
    open: "открыт",
    closed: "закрыт",
    notShown: "Не показано:",
    ipDomain: "IP/ Домен:",
    portChecked: {
      title: "Какие порты проверяются",
      port: "Порт",
      protocol: "Протокол",
      description: "Описание",
      tooltip:
        "Выберите, в каком формате вы хотите экспортировать результаты проверки"
    },
    sliderTitle: "Что делает онлайн-сканер портов:",
    cta: {
      title: "Какие порты сканируются",
      description:
        "Наш сервис сканирует самые популярные порты и скрытые порты. Полный их список можно увидеть после проверки в контекстном блоке справа.",
      buttonLabel: "Попробуйте онлайн-сканер портов"
    },
    stepGuide: "Как сканировать порты — пошаговое руководство"
  },
  tracingIp: {
    main: {
      title: "Трассировка IP онлайн",
      subtitle:
        "Онлайн-отслеживание IP-адресов — диагностический инструмент, отображающий информацию обо всех промежуточных маршрутизаторах на пути к конечному узлу. Для этого воспользуйтесь нашим онлайн-сервисом. Вы можете использовать этот диагностический инструмент бесплатно неограниченное количество раз."
    },
    form: {
      traceIP: "Отследить IP",
      loading: "Загрузка",
      inputLabel: "Введите действительный IP-адрес (0.0.0.0) или имя хоста",
      enterIP: "Введите IP",
      selectLabel: "Показать маршрут роута по стране",
      infoMessage: "Отслеживание может занять до нескольких минут",
      rules: {
        required: "IP-адреса или имя хоста обязательны",
        ip: "Неправильный IP-адрес или имя хоста"
      }
    }
  },
  dnsLeak: {
    main: {
      title: "Тест на утечку DNS",
      subtitle:
        "Тест на утечку DNS — это инструмент, используемый для определения того, какие DNS-серверы использует ваш браузер для разрешения доменных имен."
    },
    analyzing: "Анализ...",
    result: "Результат:",
    vulnerable: "УЯЗВИМЫЙ",
    verify:
      "Убедитесь, что ваш интернет-трафик направляется через выбранный вами DNS",
    notDetected:
      "Утечка DNS не обнаружена, но на всякий случай используйте TrustyTech",
    stopDns: "Остановить утечку DNS",
    runDns: "Запустить тест на утечку DNS",
    yourIP: "Ваш IP:",
    pleaseWait: "Пожалуйста, подождите несколько секунд",
    table: {
      ip: "ip",
      provider: "Провайдер",
      country: "Страна"
    },
    whatIsDns: {
      title: "Что такое тест на утечку DNS",
      description:
        "DNS помогает пользователям легко получать доступ к веб-сайтам и другим интернет-ресурсам. Ваше устройство отправляет запросы на DNS-сервер, а сервер отправляет обратно указания к тому, что вы ищете. Именно это делает DNS значительным риском для конфиденциальности."
    },
    whatDoesDns: {
      title: "Что показывает проверка DNS",
      description:
        "Проверка на утечки DNS помогает защитить вашу конфиденциальность и гарантировать, что ваша активность в Интернете не будет раскрыта неавторизованным и потенциально злонамеренным лицам. Вам следует регулярно проверять наличие утечек DNS, чтобы убедиться, что ваши DNS-запросы отправляются туда, куда следует, и что ваша онлайн-активность остается конфиденциальной."
    },
    howFixDns: {
      title: "Как исправить утечку DNS"
    }
  },
  ipBlacklist: {
    main: {
      title: "Проверка черного списка IP-адресов",
      subtitle:
        "Как известно, спам часто может привести к занесению IP в черный список, что заметно нарушает всю работу сети. Чтобы этого избежать, следует использовать сервер проверки IP в спам-базах."
    },
    ipCheckserver: "Сервер проверки IP",
    form: {
      examination: "Проверка",
      loading: "Загрузка",
      enterIp: "Введите IP",
      label: "Пожалуйста, введите правильный IP-адрес:",
      infoMessage: "Тест может занять некоторое время.",
      rules: {
        required: "IP-адрес обязателен",
        ip: "Неправильный IP адрес"
      }
    },
    checks: "Проверки:",
    checkedOut: "Проверено:",
    blacklisted: "В чёрном списке:",
    listed: "В списке",
    notListed: "Не в списке"
  },
  freeProxy: {
    main: {
      title: "Бесплатный прокси-сервер онлайн",
      subtitle:
        "Мы предоставляем списки бесплатных прокси-серверов в информационных целях. В наших регулярно обновляемых списках представлены бесплатные IP-адреса из более чем 150 стран. Однако для обычных серьезных задач бесплатные прокси не подходят. Для таких целей мы рекомендуем воспользоваться нашей службой <a href='https://proxy-sale.com/'>персональных прокси</a>"
    },
    list: {
      title: "Список бесплатных прокси",
      itemsFound: "Найдено прокси:",
      tooltip:
        "Выберите, в каком формате вы хотите экспортировать список прокси",
      infoMessage:
        "Списки бесплатных прокси, предоставляемые этим сервисом, представляют собой общедоступные данные, полученные путем анализа общедоступных интернет-страниц, и предоставляются исключительно в информационных целях. Использование списков бесплатных прокси осуществляется исключительно на ваш страх и риск."
    },
    filter: {
      country: "Страна",
      selectCountry: "Выберите страну",
      city: "Город",
      selectCity: "Выберите город",
      typeOf: "Тип",
      allCountries: "Все страны",
      allCities: "Все города",
      allTypes: "Все типы"
    },
    table: {
      ipAddress: "Адрес IP",
      port: "Порт",
      anonymity: "Анонимность",
      lastChecked: "Последняя проверка",
      speed: "Скорость"
    },
    howUseProxy: {
      title: "Как использовать бесплатный прокси",
      description:
        "Бесплатные прокси стали особенно популярны, когда возникла массовая необходимость обойти блокировки популярных веб-ресурсов – социальных сетей, видеохостингов и других.",
      listTitle:
        "Таким образом, вы можете использовать бесплатные прокси для любых целей:"
    },
    differenceBetween: {
      title: "Разница между бесплатными и персональными прокси-серверами",
      description:
        "На первый взгляд персональные и бесплатные прокси-серверы одинаковы, так как принципы их работы схожи: чтобы «обмануть» систему безопасности провайдера или сайта, реальный IP-адрес пользователя «прикрывается» промежуточным IP-прокси. Однако на самом деле разница довольно резкая:",
      freeProxy: "Бесплатный прокси",
      personalProxy: "Персональный прокси"
    },
    howDownloadProxy: {
      title: "Как скачать список прокси"
    }
  },
  myAnonymity: {
    main: {
      title: "Моя анонимность",
      subtitle:
        "Служба проверки анонимности IP предоставляет исчерпывающую информацию, к которой могут получить доступ различные службы или веб-сайты при посещении или использовании. В совокупности эти данные могут значительно снизить воспринимаемую надежность IP-адреса. Для тщательной проверки анонимности рекомендуется следовать основным правилам. рекомендации сервиса для анонимных и безопасных операций."
    },
    table: {
      provider: "Провайдер",
      hostname: "Имя хоста",
      os: "ОС",
      browser: "Браузер",
      dns: "DNS",
      proxy: "Прокси",
      anonymizer: "Анонимайзер",
      blacklist: "Черный список"
    },
    myIP: "Мой IP:",
    title: "Начать проверять анонимность",
    subtitle:
      "После детальной проверки на экране отобразятся все основные пункты, определяющие уровень анонимности вашего IP",
    buttonLabel: "Проверить мою анонимность",
    yourDisguise: "Ваша маскировка:",
    info: "Умеренные замечания по безопасности и анонимности",
    details: {
      title: "Сведения об IP-адресе",
      location: "Местоположение",
      language: "Язык",
      browser: "Браузер",
      time: "Время",
      socialMedia: "Социальные сети",
      interactiveDetection: "Интерактивное обнаружение",
      navigator: "Навигатор",
      screen: "Экран",
      scripts: "Скрипты",
      plugins: "Плагины",
      hide: "Скрыть",
      showMore: "Показать больше",
      enabled: "включено",
      disabled: "отключено"
    },
    whatIsAnonymity: {
      title: "Что такое проверка анонимности IP или прокси",
      description:
        "С помощью этого сервиса вы можете проверить, насколько вы анонимны в сети, какой объем данных предоставляет ваш компьютер/браузер, те же данные, которые предоставляет ваш ip-адрес. Также сервис можно использовать для проверки уязвимостей, которые повлиять на анонимность IP."
    },
    whatDoesAnonymity: {
      title: "Что делает проверка анонимности",
      description: `Средство проверки точно определяет анонимность прокси, показывает его использование и реальный тип пользователя. Получив такую информацию, клиент может либо подтвердить свою безопасность, либо (при ее отсутствии) предоставить ее себе в кратчайшие сроки.
      С помощью этого сервиса вы можете проверить степень анонимности в сети, а также то, что данные, предоставленные вашим компьютером/браузером, совпадают с данными, предоставленными вашим IP-адресом.`
    }
  },
  httpHeadersChecker: {
    main: {
      title: "Проверка заголовков HTTP",
      subtitle:
        "Проверьте заголовки, рекламируемые вашим веб-сервером или сетевым устройством"
    },
    httpHeadersCheck: "Проверка HTTP-заголовка",
    site: "Сайт",
    ipAddress: "IP-адрес",
    form: {
      enterWebsite: "Введите URL-адрес веб-сайта",
      selectCountry: "Выберите страну",
      button: "Проверить заголовки",
      info: "Тест может занять некоторое время.",
      rules: {
        required: "Веб-сайт обязателен",
        url: "Неправильный URL"
      }
    },
    table: {
      header: "Заголовок",
      value: "Значение"
    }
  },
  webRTCLeakTest: {
    main: {
      title: "Тест на утечку WebRTC",
      subtitle:
        "Если в вашем браузере включен WebRTC и вы находитесь на веб-сайте, который его использует, ваш реальный IP-адрес будет виден и, следовательно, будет утечка. Проверьте, включен ли WebRTC в вашем браузере и не происходит ли утечка вашего настоящий IP-адрес."
    },
    table: {
      ip: "IP",
      type: "Тип",
      status: "Статус",
      leak: "Возможная утечка",
      noLeak: "Утечки нет",
      leakTooltip:
        "Ваша личная информация может быть скомпрометирована, даже если вы используете VPN.",
      noLeakTooltip: "Ваша личная информация защищена"
    },
    webRTCTest: {
      title: "IP-адреса, открытые через WebRTC",
      buttonLabel: "Предотвратить утечку WebRTC",
      leakMessage:
        "Ваш браузер предоставляет IP-адреса сайтам, которые вы посещаете.",
      noLeakMessage:
        "Отлично! Протокол WebRTC отключен, поэтому ваш IP-адрес защищен."
    },
    whatIsWebRTC: {
      title: "Что такое WebRTC",
      description:
        "WebRTC или веб-коммуникация в реальном времени дает веб-браузерам возможность взаимодействовать напрямую без стороннего сервера. Это означает более высокую скорость и время отклика при работе в Интернете. Это важно для таких вещей, как прямая трансляция на таких сервисах, как Twitch. или любой другой сервис, который зависит от скорости и времени пинга."
    },
    whatDoesWebRTC: {
      title: "Что делает тест на утечку WebRTC",
      description:
        "Этот инструмент сообщит вам, являются ли ваши реальные общедоступные IP-адреса незащищенными. Вы сможете увидеть IP-адреса, собранные WebRTC, и передать их вам, чтобы вы могли связать их с вашим общедоступным IP-адресом."
    },
    howFixWebRTC: {
      title: "Как устранить утечку WebRTC"
    }
  },
  evercookieTest: {
    main: {
      title: "Тест Evercookie",
      subtitle:
        "Evercookie создан для защиты данных, которые должны быть постоянными, путем одновременного хранения этих данных в нескольких местах. В случае потери каких-либо данных (например, если вы удалили файлы cookie), их можно восстановить, а затем сбросить и повторно использовать"
    },
    testResult: "Результат теста Evercookie",
    infoCreate:
      "Нажмите, чтобы создать файл Evercookie. Ваша анонимность не будет нарушена",
    buttonCreate: "Создать Evercookies",
    uidNotSet: "В НАСТОЯЩЕЕ ВРЕМЯ НЕ УСТАНОВЛЕНО",
    cookieFound: "НАЙДЕННЫЙ COOKIE UID",
    infoRediscover:
      "Пожалуйста, попробуйте удалить этот файл cookie 'uid' любым возможным способом, затем нажмите кнопку ниже",
    buttonRediscover: "Найти файлы cookie",
    table: {
      name: "Название",
      value: "Значение"
    }
  },
  browserInformation: {
    main: {
      title: "Информация о браузере",
      subtitle:
        "Онлайн-поиск информации о браузере — это инструмент, который поможет вам найти информацию о вашем веб-браузере и определить ваш IP-адрес"
    },
    youreUsing: "Вы используете",
    yourIP: "Ваш IP:",
    table: {
      os: "Операционная система",
      ip: "IP",
      cms: "CMS",
      language: "Язык",
      userAgent: "UserAgent",
      application: "Версия приложения"
    }
  },
  faq: {
    heading: "Часто задаваемые вопросы",
    description:
      "Все, что вам нужно знать о продукте и о том, как он работает. Не можете найти ответ? Пожалуйста, <a href='/ru/feedback/'>свяжитесь с нами</a>"
  },
  proxyFilters: {
    rating: "По рейтингу",
    reviews: "По отзывам",
    costs: "По стоимости"
  },
  buttons: {
    viewMore: "Показать больше"
  },
  noData: {
    select: {
      default: "Нет данных"
    }
  },
  layouts: {
    auth: {
      registration: {
        title: "Начните создавать отзывы о прокси с TrustyTech",
        subtitle: "Зарегистрируйтесь на нашем сервисе за секунды!"
      },
      login: {
        title: "Добро пожаловать на TrustyTech",
        subtitle: "Пожалуйста, войдите в свой аккаунт"
      },
      feedback: {
        title: "Мы всегда готовы ответить на ваши вопросы",
        subtitle: "и выслушать ваши предложения по улучшению нашего сервиса"
      },
      default: {
        title: "TrustyTech",
        subtitle: "Сервис отзывов о прокси"
      }
    }
  },
  continents: {
    ASIA: "Азия",
    AFRICA: "Африка",
    NORTH_AMERICA: "Северная Америка",
    SOUTH_AMERICA: "Южная Америка",
    ANTARCTICA: "Антарктида",
    EUROPE: "Европа",
    AUSTRALIA: "Австралия"
  },
  howToBuy: {
    title: "Как купить ",
    proxy: "Прокси",
    subtitle:
      "Как купить различные типы прокси: IPv4 и IPv6 для центров обработки данных, интернет-провайдеры, жилые дома и вращающиеся мобильные сети 4G/5G:",
    proxySubtitle:
      "Купить качественные IPv4 прокси, расположенные в огромном количестве сетей и подсетей с возможностью выбора подходящей локации",
    listTitle:
      "Услуги предоставляются согласно характеристикам, указанным в каталоге"
  },
  languages: {
    title: "Язык",
    en: "Английский",
    ru: "Русский",
    ua: "Украинский"
  }
};
