import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { StyledDataTable } from "../../../components/common/Table/Table.styled";
import {
  ActionCell,
  HeaderNameCell,
  IsActiveCell,
  NoDataComponent,
  PeriodCell,
  SiteNameCell,
  TextCell
} from "../../../components/common/TableColumns";
import { Pagination } from "../../../components/ui/Pagination/Pagination";
import { SkeletonDashboardPromoTable } from "../../../components/ui/Skeleton/SkeletonDashboardPromoTable/SkeletonDashboardPromoTable";
import { SkeletonPaginationDashboardTable } from "../../../components/ui/Skeleton/SkeletonPaginationDashboardTable/SkeletonPaginationDashboardTable";
import { TableCheckbox } from "../../../components/ui/TableCheckbox/TableCheckbox";
import { useDispatchedActions, useLangUrlDefault } from "../../../hooks";
import { ApiService } from "../../../services";
import { $api } from "../../../services/AxiosInstance";
import { AllActions } from "../../../store/reducers/AllActions";
import { getAllCabinet } from "../../../store/reducers/CabinetReducer/Cabinet.selectors";
import { ModalConfirm } from "../components/ModalConfirm/ModalConfirm";
import { NoData } from "../components/NoData/NoData";
import { TitleBlock } from "../components/TitleBlock/TitleBlock";

import { PaginationBox, PromocodesContainer } from "./Promocodes.styled";

const Promocodes = () => {
  // **Props
  const [, hrefLang] = useLangUrlDefault();

  // **Redux state
  const { t } = useTranslation();
  const { promocodes } = useSelector(getAllCabinet);

  // **Local state
  const [deletedPromocodeId, setDeletedPromocodeId] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listSites, setListSites] = useState([]);
  const [params, setParams] = useState(
    promocodes.prevParams || {
      page: 0,
      size: 10,
      siteIds: []
    }
  );

  // **Dispatch
  const { getCabinetPromocodes, setCabinetPromocodesPrevParams } =
    useDispatchedActions();

  // **Ref
  // const scrollRef = useRef();

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const fetchCabinetPromocodes = useCallback(() => {
    getCabinetPromocodes(params);
    setCabinetPromocodesPrevParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // Change page by clicking pagination
  const pageChangeHandler = (page) => {
    setParams({ ...params, page: page - 1 });
  };

  const pageSizeChangeHandler = (size) =>
    setParams({ ...params, page: 0, size });

  // const changeSortType = (type) => {
  //   setParams({ ...params, sort: type });
  // };

  const openModalHandler = (id) => {
    setIsModalOpened(true);
    setDeletedPromocodeId(id);
  };

  const handleFilter = (value) => setParams({ ...params, siteIds: value });

  const handlerChangeActive = (id) => {
    ApiService.trigerPromoSiteActivation(id)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t("dashboard.promocodes.activated.success"));
          fetchCabinetPromocodes();
          return;
        }

        if (response?.status === 400) {
          if (response?.response?.data?.error === "PROMOCODE_STARTS_LATER") {
            toast.error(t("dashboard.promocodes.activated.startsLater"));
            return;
          }
          if (response?.response?.data?.error === "PROMOCODE_IS_EXPIRED") {
            toast.error(t("dashboard.promocodes.activated.isExpired"));
            return;
          }
        }
        if (
          response?.response?.status === 404 &&
          response?.response?.data?.error === "PROMOCODE_NOT_FOUND"
        ) {
          toast.error(t("dashboard.promocodes.activated.notFound"));
          return;
        }
        toast.error(t("notifications.apiError"));
      })
      .catch(() => {
        toast.error(t("notifications.apiError"));
      });
  };

  const conditionalRowStyles = [
    {
      when: (row) => selectedRows.some((r) => r.id === row.id),
      style: {
        backgroundColor: "rgba(246, 247, 249, 0.80)"
      }
    }
  ];

  const columns = [
    {
      name: (
        <HeaderNameCell
          showFilter
          filter={params.siteIds}
          setFilter={handleFilter}
          filterOption={listSites}
        >
          {t("dashboard.promocodes.table.columns.promocode")}
        </HeaderNameCell>
      ),
      width: "230px",
      cell: (row) => (
        <SiteNameCell
          imgUrl={row?.imageUrl}
          favicon={row?.favicon}
          faviconContentType={row?.faviconContentType}
          name={row?.siteName}
          showRating={false}
        />
      )
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.promocodes.table.columns.promocode")}
        </HeaderNameCell>
      ),
      // minWidth: "200px",
      cell: (row) => <TextCell>{row?.promocode}</TextCell>
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.promocodes.table.columns.date")}
        </HeaderNameCell>
      ),
      // width: "175px",
      cell: (row) => (
        <PeriodCell
          eternal={row?.eternal}
          startDate={row?.startDate}
          endDate={row?.endDate}
        />
      ),
      sortable: true,
      selector: (row) => row?.startDate
    },
    {
      name: (
        <HeaderNameCell>
          {t("dashboard.promocodes.table.columns.active")}
        </HeaderNameCell>
      ),
      // minWidth: "160px",
      cell: (row) => (
        <IsActiveCell
          isActive={row?.isActive}
          promoId={row?.id}
          onChange={handlerChangeActive}
        />
      )
    },
    {
      width: "42px",
      cell: (row) => (
        <ActionCell
          id={row.id}
          actions={[
            {
              linkTo: `${hrefLang}/dashboard/promocodes/edit/${row.id}/`,
              icon: "edit",
              label: t("dashboard.promocodes.table.columns.actions.edit")
            },
            {
              onClick: () => openModalHandler(row.id),

              icon: "delete",
              label: t("dashboard.promocodes.table.columns.actions.delete"),
              variant: "danger"
            }
          ]}
        />
      )
    }
  ];

  const confirmHandler = async () => {
    try {
      setIsDeleting(true);

      const response = await ApiService.deletePromocode(deletedPromocodeId);

      if (response && response.status !== 200) {
        throw response;
      }

      // Closing modal
      setIsModalOpened(false);

      // Show success message
      toast.success(t("notifications.promocode.removed"));

      setDeletedPromocodeId(null);
      fetchCabinetPromocodes();
    } catch (err) {
      toast.error(t("notifications.apiError"));
    } finally {
      setTimeout(() => {
        setIsDeleting(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(promocodes.prevParams)) {
      fetchCabinetPromocodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (promocodes.data.totalPages !== params.page) {
      return;
    }

    if (params.page === 0) {
      return;
    }

    setParams({ ...params, page: params.page - 1 });
  }, [params, promocodes]);

  const customStyles = {
    table: {
      style: {
        marginBottom: "18px"
      }
    },
    rows: {
      style: {
        minHeight: "64px"
      }
    }
  };

  useEffect(() => {
    ApiService.getUserPromoSitesList().then((response) => {
      if (response.status !== 200 || response.data?.length < 1) {
        return;
      }
      setListSites(response.data);
    });
  }, []);
  useEffect(() => {
    if (
      !promocodes.isLoading &&
      typeof promocodes.data?.anyExists === "boolean"
    ) {
      setLoading(false);
    }
  }, [promocodes.isLoading, promocodes.data?.anyExists]);

  return (
    <>
      <TitleBlock
        title={t("dashboard.promocodes.table.title")}
        linkTo={"/dashboard/promocodes/create"}
        linkName={t("dashboard.promocodes.table.add")}
      />

      <PromocodesContainer noData={!promocodes.isLoading && !promocodes.data?.anyExists}>
        {!promocodes.isLoading && !promocodes.data?.anyExists ? (
          <NoData
            title={t("dashboard.promocodes.noData.title")}
            subtitle={t("dashboard.promocodes.noData.subtitle")}
            srcX1={"/img/dashboard/no-promocodes-x1.png"}
            srcX2={"/img/dashboard/no-promocodes-x2.png"}
          />
        ) : (
          <>
            <StyledDataTable
              styleRules={{
                showedAll: true
              }}
              noDataComponent={<NoDataComponent bordered />}
              data={promocodes.data?.content}
              columns={columns}
              selectableRows
              selectableRowsComponent={TableCheckbox}
              selectableRowsHighlight
              onSelectedRowsChange={handleRowSelected}
              conditionalRowStyles={conditionalRowStyles}
              progressPending={loading}
              progressComponent={<SkeletonDashboardPromoTable cells={10} />}
              persistTableHead={!loading}
              customStyles={customStyles}
            />
            {loading && <SkeletonPaginationDashboardTable />}
            {!loading && promocodes.data?.totalElements > 0 && (
              <PaginationBox>
                <Pagination
                  rowCount={promocodes.data.totalElements}
                  rowsPerPage={params.size}
                  onChangePage={pageChangeHandler}
                  onChangeRowsPerPage={pageSizeChangeHandler}
                  currentPage={params.page + 1}
                  useCount
                />
              </PaginationBox>
            )}
          </>
        )}
      </PromocodesContainer>
      <ModalConfirm
        open={isModalOpened}
        close={setIsModalOpened}
        isDeleting={isDeleting}
        srcX1={"/img/dashboard/delete-promo-x1.png"}
        srcX2={"/img/dashboard/delete-promo-x2.png"}
        subtitle={t("dashboard.promocodes.modal.delete.subtitle")}
        onConfirm={confirmHandler}
        pictureVariant={"promocode"}
      />
    </>
  );
};

export default Promocodes;

Promocodes.getServerSideState = async (store, params) => {
  const { data: pageContent } = await $api.post("/api/page/front/content", {
    ...params,
    languageCode: params.locale
  });

  store.dispatch(AllActions.setActivePageParams(params));
  store.dispatch(AllActions.setPageContent(pageContent));
};
