import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Title } from "../../../../components/ui/Title/Title";

export const MyAnonymitySection = styled.div`
  overflow: hidden;
  background-color: ${(p) =>
    p.variant === "light"
      ? p.theme.colors.white
      : p.theme.colors["background-color-seo"]};
  padding-top: 64px;
  padding-bottom: 64px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-top: ${(p) => (p.variant === "light" ? "30px" : "120px")};
    padding-bottom: ${(p) => (p.variant === "light" ? "40px" : "120px")};
  }
`;

export const RestyledContainer = styled(Container)`
  display: flex;
  flex-direction: ${(p) =>
    p.variant === "light" ? "column-reverse" : "column"};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const AnimationWrapper = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;
    width: ${(p) => (p.variant === "light" ? "457px" : "620px")};
    height: ${(p) => (p.variant === "light" ? "372px" : "370px;")};
  }
`;

export const MobilePicture = styled.picture`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const MobileImage = styled.img`
  width: 100%;
  max-width: ${(p) => (p.variant === "full" ? "100%" : "500px")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin: ${(p) => (p.variant === "light" ? "0 0 0 64px" : "0 20px 0 0")};
    ${(p) => (p.variant === "light" ? "max-width: 600px;" : "")}
  }
`;

export const RestyledTitle = styled(Title)`
  margin-bottom: 24px !important;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    max-width: 560px;
  }
`;

export const Description = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 165%;
  margin-bottom: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    max-width: 529px;
  }
`;
