/* eslint-disable no-undef */
import { useEffect, useRef, useState } from "react";

import PT from "prop-types";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

import { calcRotate } from "../../../utils/helpers/calcRotate.helpers";

import {
  StyledProgressBar,
  StyledProgressCircleImg,
  StyledProgressText
} from "./Progress.styled";

export const Progress = ({
  isLoading = false,
  isComplete = false,
  speed = 5,
  stepTime = 500
}) => {
  const refInterval = useRef(null);
  const [percent, setPercent] = useState(0);
  const startTime = useRef(null);

  useEffect(() => {
    if (isComplete) {
      setPercent(100);
      return () => refInterval?.current && clearInterval(refInterval.current);
    }
    if (isLoading) {
      if (startTime.current === null) {
        startTime.current = Date.now();
      }
      refInterval.current = setInterval(() => {
        // Вычисляем новое значение на основе формулы
        const elapsedTime = (Date.now() - startTime.current) / 1000; // Время в секундах
        const newProgress = 99 * (1 - Math.exp(-0.1 * speed * elapsedTime)); // k = 0.1 для плавного роста
        const max99 = Math.min(newProgress, 99); // Убедимся, что не превышает 99
        setPercent(Math.round(max99)); // Округляем до целого числа
      }, stepTime); // Каждые 0.5 секунды
    }
    return () => refInterval?.current && clearInterval(refInterval.current); // Очищаем интервал при размонтировании
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplete, isLoading]);

  return (
    <StyledProgressBar>
      <StyledProgressCircleImg
        src="img/icons/circleIcon.svg"
        style={{
          transform: `rotate(${calcRotate(percent)}deg) translateX(78.5px)`,
          transition: percent === 0 ? "none" : "all 0.5s ease 0s"
        }}
      />
      <CircularProgressbarWithChildren
        value={percent}
        counterClockwise={true}
        styles={{
          path: {
            // Path color
            stroke: "#8FC846",
            strokeWidth: "1px",
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Customize transition animation
            transition:
              percent === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s",
            transformOrigin: "center center"
          },
          trail: {
            // Trail color
            stroke: "transparent"
          }
        }}
      >
        <StyledProgressText>
          {percent}
          {/* <span className={styles.progress_bar__percent}>%</span> */}
        </StyledProgressText>
      </CircularProgressbarWithChildren>
    </StyledProgressBar>
  );
};

Progress.propTypes = {
  isComplete: PT.bool,
  started: PT.bool,
  speed: PT.number,
  stepTime: PT.number
};
