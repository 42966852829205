import { useFavicon } from "../../../hooks";
import { StyledSkeleton } from "../../ui/Skeleton/components/SkeletonTableComponents.styled";

import { PromoButtons } from "./PromoButtons/PromoButtons";
import { PromoTitle } from "./PromoTitle/PromoTitle";
import {
  StyledDescription,
  StyledInfoBox,
  StyledPromocode
} from "./Promocode.styled";

export const Promocode = ({
  description,
  promocode,
  siteId,
  title,
  favicon,
  faviconContentType,
  image,
  time,
  eternal,
  variant,
  loading
}) => {
  const promocodeImage = useFavicon(favicon, faviconContentType, image);

  return (
    <StyledPromocode variant={variant}>
      <StyledInfoBox variant={variant}>
        <PromoTitle
          variant={variant}
          title={title}
          image={promocodeImage}
          time={time}
          eternal={eternal}
          loading={loading}
        />

        <StyledDescription variant={variant}>
          {loading ? <StyledSkeleton height={14} count={2} /> : description}
        </StyledDescription>
      </StyledInfoBox>
      <PromoButtons
        variant={variant}
        promocode={promocode}
        siteId={siteId}
        loading={loading}
      />
    </StyledPromocode>
  );
};
