export const theme = {
  fontSizes: {
    xxs: "12px",
    //need to rename s to xs (correct sizes are xxs, xs, s, m, l, xl, xxl  )
    s: "14px",
    xs: "15px",
    m: "16px",
    l: "18px",
    xl: "24px",
    xll: "32px",
    xxl: "36px",
    xxxl: "48px"
  },
  fontWeight: {
    normal: 400,
    bold: 700
  },
  fontStyle: {
    normal: "normal"
  },
  fontFamily: {
    lato: "Lato, sans-serif",
    title: "ProximaNova, sans-serif"
  },
  lineHeight: {
    normal: 1.5
  },
  colors: {
    white: "#ffffff",
    black: "#000000",
    error: "red",
    yellow: "#f7c76b",
    orange: "#f2994a",
    blue: "#2d8dff",

    "neutral-25": "#f2f3f5",
    "neutral-50": "#ebecee",
    "neutral-75": "#dadae1",
    "neutral-100": "#d4dae5",
    "neutral-200": "#c8ced9",
    "neutral-300": "#aab2c0",
    "neutral-400": "#808a9d",
    "neutral-500": "#4c5464",
    "neutral-600": "#404652",
    "neutral-800": "#2a2a2d",
    "neutral-900": "#1e1e20",

    "neutral-promo-date": "#c5cdd9",

    "dark-50": "#f5f6f7",
    "dark-700": "#232B3B",

    "red-400": "#ffe9e6",
    "red-500": "#ff5c5c",
    "red-600": "#f24444",

    "main-400": "#e8f8cc",
    "main-500": "#c8ee85",
    "main-600": "#a5d949",
    "main-700": "#8fc846",

    "outline-color": "#d8dae0",
    "text-button-main-active": "#79b22f",

    "background-color": "#f6f7f9",
    "background-color-seo": "#fcfcfc",
    "background-color-dark": "#2f3745",
    "background-color-black": "#141921",
    "background-color-image": "#f8f8f8",
    "background-color-special-decor": "rgba(255, 255, 255, 0.03)",

    "overlay-color": "rgba(0, 0, 0, 0.5)",
    "copyright-color": "#bcbcc4a6",

    "rating-background-yellow": "#FDF7DB",
    "rating-background-green": "#F0FBDE",
    "rating-background-red": "#FFE9E6"
  },
  linearGradients: {
    "main-500": "linear-gradient(180deg, #c8ee85 0%, #c8ee85 100%)",
    "main-600": "linear-gradient(180deg, #a5d949 0%, #a5d949 100%)",
    "main-gradient": "linear-gradient(180deg, #c8ee85 0%, #aada54 100%)",

    "red-500": "linear-gradient(180deg, #ff5c5c 0%, #ff5c5c 100%)",
    "red-600": "linear-gradient(180deg, #f24444 0%, #f24444 100%)",
    "red-gradient": "linear-gradient(180deg, #ff7b7b 0%, #e74444 100%)",

    "neutral-25": "linear-gradient(180deg, #f2f3f5 0%, #f2f3f5 100%)",
    transparent:
      "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
    "secondary-active": "linear-gradient(180deg, #e8f8cc40 0%, #e8f8cc40 100%)"
  },
  shadows: {
    buttonShadow: `0px 111px 31px 0px rgba(200, 238, 133, 0),
    0px 71px 29px 0px rgba(200, 238, 133, 0.01),
    0px 40px 24px 0px rgba(200, 238, 133, 0.05),
    0px 18px 18px 0px rgba(200, 238, 133, 0.09),
    0px 4px 10px 0px rgba(200, 238, 133, 0.1)`,
    recentReviewCard: "0px 5px 28px 0px rgba(135, 140, 189, 0.12)",
    recentReviewCardHover: "0px 5px 35px 0px rgba(135, 140, 189, 0.42)",
    card: "0px 5px 28px 0px rgba(135, 140, 189, 0.08)",
    "special-offer-card": "0px 4px 32px 0px rgba(135, 140, 189, 0.13)",
    promoCardLight: "0px 10px 46px 0px rgba(135, 140, 189, 0.10)",
    promoCardTable: "0px 5px 27px 0px rgba(135, 140, 189, 0.16)",
    tabButton: "0px 4px 32px 0px rgba(135, 140, 189, 0.10)",
    tabButton2: "0px 0px 32px 0px rgba(135, 140, 189, 0.13)",
    alterSiteMobile: "0px 15px 46px 0px rgba(135, 140, 189, 0.12)",
    alterSiteDesktop: "0px 15px 46px 0px rgba(135, 140, 189, 0.08)",
    addReviewCounter: "0px 10px 46px 0px rgba(135, 140, 189, 0.1)"
  },
  space: [0, 2, 4, 8, 12, 16, 24, 32, 64, 128, 256],
  radii: {},
  screenSizes: {
    mdMax: "992px",
    lgMin: "993px",
    lgMax: "1199px",
    xlMin: "1200px"
  },
  transitions: {
    default: "0.3s ease"
  },
  borders: {
    border: "1px solid rgba(216, 218, 224, 0.55)",
    "border-04": "1px solid rgba(218, 218, 225, 0.4)"
  }
};
