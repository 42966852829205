import { useEffect, useState } from "react";

import { webrtcCheck } from "@forproxyband/react-tools";
import { useTranslation } from "react-i18next";

import { Progress } from "../../../../components/common/Progress/Progress";
import { useUserIp } from "../../../../hooks/useUserIp";
import { useWebRtcStatus } from "../../../../hooks/useWebRtcStatus";
import { scrollTo } from "../../../../utils/helpers/scroll.helper";

import { ListMobileComponent } from "./ListMobile/ListMobile";
import { ResultsTable } from "./ResultTable/ResultTable";
import {
  ImageWrapper,
  Info,
  InfoMessage,
  Inner,
  LoaderWrapper,
  RestyledButton,
  RestyledContainer,
  RestyledTitle,
  Results,
  TitleWrapper
} from "./WebRTCLeakTest.styled";

export const WebRTCLeakTest = () => {
  const [ips, setIps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const userIp = useUserIp();

  const webRtcStatus = useWebRtcStatus(ips, userIp?.ipAddress, setIps);

  const handlerCheck = () => {
    scrollTo("howToFixContainer");
  };

  useEffect(() => {
    if (userIp?.ipAddress) {
      webrtcCheck.check(setIps);
    }
  }, [userIp?.ipAddress]);

  useEffect(() => {
    let timeout = null;
    if (ips) {
      timeout = setTimeout(() => setIsLoading(false), 3000);
    }
    return () => {
      // eslint-disable-next-line no-undef
      clearTimeout(timeout);
    };
  }, [ips]);

  return (
    <RestyledContainer>
      <Inner>
        <Info>
          <ImageWrapper>
            <img
              src="img/webRTC-leak-test/webRTC-leak-test-image.svg"
              alt={t("dnsLeak.main.title")}
            />
          </ImageWrapper>
          <RestyledButton
            type="button"
            onClick={handlerCheck}
            disabled={isLoading || !userIp?.ipAddress}
            fullWidth
          >
            {t("webRTCLeakTest.webRTCTest.buttonLabel")}
          </RestyledButton>
        </Info>
        <Results>
          {userIp && !isLoading ? (
            <>
              <TitleWrapper>
                <RestyledTitle tag="h2">
                  {t("webRTCLeakTest.webRTCTest.title")}
                </RestyledTitle>
                <InfoMessage webRtcStatus={webRtcStatus}>
                  {webRtcStatus
                    ? t("webRTCLeakTest.webRTCTest.leakMessage")
                    : t("webRTCLeakTest.webRTCTest.noLeakMessage")}
                </InfoMessage>
              </TitleWrapper>
              <ResultsTable
                data={ips}
                status={webRtcStatus}
                userIp={userIp?.ipAddress}
              />
              <ListMobileComponent
                data={ips}
                status={webRtcStatus}
                userIp={userIp?.ipAddress}
              />
              <RestyledButton
                isMobile
                type="button"
                onClick={handlerCheck}
                disabled={isLoading}
                fullWidth
              >
                {t("webRTCLeakTest.webRTCTest.buttonLabel")}
              </RestyledButton>
            </>
          ) : (
            <LoaderWrapper>
              <Progress isLoading />
            </LoaderWrapper>
          )}
        </Results>
      </Inner>
    </RestyledContainer>
  );
};
