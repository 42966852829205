import styled from "@emotion/styled";

import { Container } from "../../../../components/common/Container/Container";
import { Title } from "../../../../components/ui/Title/Title";

export const StyledSection = styled.div`
  overflow: hidden;
  ${(p) =>
    p.variant === "light"
      ? `background-color: white;
    padding-top: 64px;
    padding-bottom: 64px;`
      : "padding-bottom: 0;"}

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding-bottom: ${(p) => (p.variant === "light" ? "100px" : "94px")};
    padding-top: ${(p) => (p.variant === "light" ? "100px" : "70px")};
  }
`;

export const RestyledContainer = styled(Container)`
  display: flex;
  flex-direction: ${(p) =>
    p.variant === "light" ? "column-reverse" : "column"};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledContent = styled.div`
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    ${(p) =>
      p.variant === "light" ? "margin-left: 54px;" : "margin-right: 60px;"}
  }
`;

export const StyledPicture = styled.picture`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 500px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none;
  }
`;

export const RestyledTitle = styled(Title)`
  margin-bottom: 24px !important;
`;

export const Description = styled.p`
  color: ${(p) => p.theme.colors["neutral-600"]};
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: ${(p) => p.theme.fontSizes.l};
  font-style: ${(p) => p.theme.fontStyle.normal};
  font-weight: ${(p) => p.theme.fontWeight.normal};
  line-height: 167%;
  margin-bottom: 40px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 0;
  }
`;

export const AnimmationWrapper = styled.div`
  position: relative;
  display: none;
  width: 0;
  height: 0;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: block;

    ${(p) =>
      p.variant === "light"
        ? `width: 497px;
        min-width: 497px;
        height: 291px;`
        : `width: 591px;
        min-width: 591px;
        height: 303px;`}
  }
`;
