import styled from "@emotion/styled";

export const StyledListReview = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.noData &&
    `
    flex-grow: 1; 
    align-items: center; 
    padding: 56px 32px 32px 32px; 
    border-radius: 12px; 
    background: white;
    box-shadow: 0px 5px 28px 0px rgba(135, 140, 189, 0.08);`};
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: ${(p) => (p.noData ? "124px 32px 32px 32px" : "32px")};
    border-radius: 12px;
    background: ${(p) => p.theme.colors.white};
    box-shadow: ${(p) => p.theme.shadows.card};
  }
`;
