import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// API
import { ApiService } from "../../../services";
import { getJsAttributes } from "../../../utils/helpers/getJsAttributes.helper";

export const fetchAnonymity = createAsyncThunk(
  "tools/fetchAnonymity",
  async (_, { rejectWithValue }) => {
    try {
      const params = getJsAttributes();
      const response = await ApiService.getAnonimityInfo(params);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const fetchToolsSettings = createAsyncThunk(
  "tools/fetchToolsSettings",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ApiService.getToolsSettings();

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);

export const setAnonymity = createAction("tools/setAnonymity");
export const setToolsSettings = createAction("tools/setToolsSettings");
