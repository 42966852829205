import { useState } from "react";

import { useSelector } from "react-redux";

import { Search } from "../../../../components/ui/Search/Search";
import { useDispatchedActions, useLangUrlDefault } from "../../../../hooks";
import { getAllContent } from "../../../../store/reducers/ContentReducer/Content.selectors";
import { getAllSite } from "../../../../store/reducers/SiteReducer/Site.selectors";
import { proxyServicesList } from "../../../../utils/helpers/serviceMenu.helpers";
import { Countries } from "../Countries/Countries";

import {
  StyledLocationsList,
  StyledProxiesNavigation,
  StyledProxiesNavigationItem
} from "./ProxiesNavigation.styled";

export const ProxiesNavigation = () => {
  const [queryLang] = useLangUrlDefault();
  const [selectedLocation, setSelectedLocation] = useState("topLocations");
  const { countries, proxyTypesMenu } = useSelector(getAllContent);
  const { searchCountry } = useSelector(getAllSite);

  const { setSearchCountry } = useDispatchedActions();

  return (
    <StyledProxiesNavigation>
      <StyledProxiesNavigationItem
        title="proxies"
        list={proxyServicesList(proxyTypesMenu?.data?.[queryLang])}
      />
      <StyledProxiesNavigationItem title="locations">
        <Search
          value={searchCountry}
          onChange={setSearchCountry}
          variant="outlined-dark"
          dataTestId="header_searchInput"
        />
        <StyledLocationsList
          selectedLocation={selectedLocation}
          onClick={setSelectedLocation}
          dataTestId="header_locationsList"
        />
      </StyledProxiesNavigationItem>
      <StyledProxiesNavigationItem title={selectedLocation}>
        <Countries
          countries={countries}
          search={searchCountry}
          selectedLocation={selectedLocation}
          dataTestId="header_countriesList"
        />
      </StyledProxiesNavigationItem>
    </StyledProxiesNavigation>
  );
};
